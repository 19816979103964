<ng-template>
  <div
    class="step-container"
    [ngClass]="{
      'step-container--active': active
    }"
    (click)="onStepSelected()"
  >
    <div
      class="circle"
      #stepContainer
      [ngClass]="{
        'circle--complete': complete
      }"
    >
      <div class="title">{{ title }}</div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
