<div
  class="count-icon-container"
  [ngClass]="{
    'count-icon-container__green-circle': theme === 'green-circle',
    'count-icon-container__pink-circle': theme === 'pink-circle',
    'count-icon-container__blue-circle': theme === 'blue-circle',
    'count-icon-container__blue-profile': theme === 'blue-profile',
    'count-icon-container__purple-circle': theme === 'purple-circle',
    'count-icon-container__red-circle': theme === 'red-circle',
    'count-icon-container__orange-circle': theme === 'orange-circle',
    smaller: smaller
  }"
>
  {{ count }}
</div>
