import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

import { LocumsNestComponent } from '../../core/locums-nest-component';

@Component({
  selector: 'locumsnest-internal-notes',
  templateUrl: './internal-notes.component.html',
  styleUrls: ['./internal-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalNotesComponent extends LocumsNestComponent {
  @Input() rows = 3;
  @Input() notesTitle = 'Add internal notes';
  @Input() placeholder = '';
  @Input() showDefaultButton = true;
  @Input({ required: true }) notesFormControl: FormControlState<string>;
  @Input() checkboxFormControl: FormControlState<boolean>;
  @Output() addNote = new EventEmitter<void>();

  onAddNote() {
    this.addNote.emit();
  }
}
