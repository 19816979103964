<div
  *ngIf="!displayTemplateNameInput && !saveResponseStatus.displayAlertMessage"
  class="template-modal"
>
  <label *ngIf="jobListingIsLoadedFromTemplate" class="template-title">
    Would you like to save the changes over the existing template?</label
  >
  <label *ngIf="!jobListingIsLoadedFromTemplate" class="template-title">
    Save as a new template for easy access in the future?</label
  >
  <locumsnest-button [buttonType]="'cancel'" [size]="'xsmall'" (click)="onDeclineClicked()">
    No
  </locumsnest-button>
  <locumsnest-button [buttonType]="'onboarding-next'" [size]="'xsmall'" (click)="onAcceptClicked()">
    Yes
  </locumsnest-button>
</div>
<div
  *ngIf="displayTemplateNameInput && !saveResponseStatus.displayAlertMessage"
  class="template-modal"
>
  <label class="template-title">
    <locumsnest-input-field
      *ngIf="!jobListingIsLoadedFromTemplate"
      [placeholder]="'Template name'"
      [hasBorder]="true"
      [(ngModel)]="templateName"
    ></locumsnest-input-field>

    <locumsnest-input-field
      *ngIf="jobListingIsLoadedFromTemplate"
      [placeholder]="'Type a new template name'"
      [(ngModel)]="templateName"
    ></locumsnest-input-field>
  </label>

  <locumsnest-button [buttonType]="'cancel'" [size]="'xsmall'" (click)="onCancelClicked()">
    Cancel
  </locumsnest-button>
  <locumsnest-button
    [buttonType]="'onboarding-next'"
    [size]="'xsmall'"
    (click)="onSaveTemplateClicked()"
  >
    Save
  </locumsnest-button>
</div>
<div *ngIf="saveResponseStatus.displayAlertMessage" style="margin-bottom: 20px">
  <label class="template-title" [ngClass]="{ 'text-danger': saveResponseStatus.type === 'error' }">
    {{ saveResponseStatus.message }}</label
  >
</div>
