import { CdkCellDef } from '@angular/cdk/table';
import { Directive, TemplateRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[lnCellDef]',
  exportAs: 'lnCellDef',
})
export class CellDefDirective extends CdkCellDef {
  constructor(public override template: TemplateRef<unknown>) {
    super(template);
  }
}
