import { animate, AnimationEvent, group, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'locumsnest-card-wizard-step',
  templateUrl: './card-wizard-step.component.html',
  styleUrls: ['./card-wizard-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('parent', [transition(':enter', [])]),
    trigger('enter', [
      transition(':enter', [
        style({
          width: 10,
          transform: 'translateX(50px)',
          opacity: 0,
          height: 70,
          'overflow-y': 'hidden',
        }),
        group([
          animate('0.5s 0.1s ease', style({ transform: 'translateX(0)', width: '*', height: '*' })),
          animate('0.5s ease', style({ opacity: 1 })),
        ]),
      ]),
      transition(':leave', [
        group([
          animate(
            '0.5s ease',
            style({ transform: 'translateX(50px)', width: 10, height: 0, 'overflow-y': 'hidden' }),
          ),
          animate('0.5s 0.2s ease', style({ opacity: 0 })),
        ]),
      ]),
    ]),
  ],
})
export class CardWizardStepComponent {
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<CardWizardStepComponent>;

  @Input() stepNumber: number;
  @Input() leftTitle: string;
  @Input() showLeftTitle = true;
  @Input() rightTitle: string;
  @Input() showRightTitle = true;
  @Input() showButton: boolean;
  @Input() showCancelButton = false;
  @Input() showStepNumber = true;
  @Input() buttonText: string;
  @Input() backButtonText = 'CANCEL';
  @Input() isCurrentSectionInvalid: boolean;
  @Input() disableNextButton = false;
  @Input() completed: boolean;
  @Input() cardId: string;
  @Input() showDeleteButton: boolean;
  @Input() showFullCard = false;
  @Input() showLeftIcon = false;
  @Input() active = false;
  @Input() cancelButtonTabIndex = null;
  @Input() nextButtonTabIndex = null;

  @Output() nextPress = new EventEmitter<string>();
  @Output() cardClick = new EventEmitter<number>();
  @Output() cancelButtonClick = new EventEmitter<void>();
  @Output() nextIntent = new EventEmitter<void>();
  @Output() removeQuestionButtonClicked = new EventEmitter<string>();

  public onGoToNextCard(buttonType: string) {
    let nextSectionNumber = this.stepNumber;

    if (buttonType === 'back') {
      nextSectionNumber = this.stepNumber - 1;
    } else if (buttonType === '') {
      nextSectionNumber = this.stepNumber + 1;
    }

    this.nextPress.emit('section' + nextSectionNumber);
  }
  public onNextIntent() {
    this.nextIntent.emit();
  }
  public onNextMouseDown() {
    this.onNextIntent();
  }

  public onNextMouseOver() {
    this.onNextIntent();
  }

  public onNextFocus() {
    this.onNextIntent();
  }

  public onCardClick(stepNumber: number) {
    this.cardClick.emit(stepNumber);
  }

  public onCancelButtonClick() {
    this.cancelButtonClick.emit();
  }

  public onRemoveQuestionButtonClicked(id: string) {
    this.removeQuestionButtonClicked.emit(id);
  }

  onAnimationEvent(event: AnimationEvent) {
    if (event.fromState === 'void' && !event.disabled) {
      event.element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
