<div [ngClass]="{ 'horizontal-alignment': horizontalAlignment }">
  <div class="input-field">
    <div *ngIf="dateTitle.length > 0" class="title">{{ dateTitle }}</div>
    <locumsnest-date-picker
      [disabled]="isDisabled"
      [placeholder]="datePlaceholder"
      [showCalendarIconInInput]="true"
      [hasBorder]="hasBorder"
      (change)="onDateChange($event)"
      [(ngModel)]="viewDate"
    >
    </locumsnest-date-picker>
  </div>
  <div class="input-field" [ngClass]="{ 'left-space': horizontalAlignment }">
    <div *ngIf="timeTitle.length > 0" class="title">{{ timeTitle }}</div>
    <locumsnest-time-field
      [disabled]="isDisabled"
      (timeChange)="onTimeChange($event)"
      [(ngModel)]="time"
    ></locumsnest-time-field>
  </div>
</div>
