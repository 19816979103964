<div class="title" [style.gap.px]="gap()">
  @switch (titleType()) {
    @case ('h1') {
      <h1>{{ title() }}</h1>
    }
    @case ('h2') {
      <h2>{{ title() }}</h2>
    }
    @case ('h3') {
      <h3>{{ title() }}</h3>
    }
    @case ('h4') {
      <h4>{{ title() }} <ng-content select="[heading]" /></h4>
    }
  }

  <ng-content />
</div>
<div
  class="line"
  [class.full]="underLineSize() === 'full'"
  [class.no-line]="underLineSize() === 'no-line'"
  [class.grey-theme]="lineTheme() === 'grey'"
></div>
