const emojis = {
  '🎌 Flags': [
    { title: 'Chequered Flag', character: '🏁' },
    { title: 'Triangular Flag', character: '🚩' },
    { title: 'Crossed Flags', character: '🎌' },
    { title: 'Black Flag', character: '🏴' },
    { title: 'White Flag', character: '🏳️' },
    { title: 'Rainbow Flag', character: '🏳️‍🌈' },
    { title: 'Transgender Flag', character: '🏳️‍⚧️' },
    { title: 'Pirate Flag', character: '🏴‍☠️' },
    { title: 'Flag: Ascension Island', character: '🇦🇨' },
    { title: 'Flag: Andorra', character: '🇦🇩' },
    { title: 'Flag: United Arab Emirates', character: '🇦🇪' },
    { title: 'Flag: Afghanistan', character: '🇦🇫' },
    { title: 'Flag: Antigua & Barbuda', character: '🇦🇬' },
    { title: 'Flag: Anguilla', character: '🇦🇮' },
    { title: 'Flag: Albania', character: '🇦🇱' },
    { title: 'Flag: Armenia', character: '🇦🇲' },
    { title: 'Flag: Angola', character: '🇦🇴' },
    { title: 'Flag: Antarctica', character: '🇦🇶' },
    { title: 'Flag: Argentina', character: '🇦🇷' },
    { title: 'Flag: American Samoa', character: '🇦🇸' },
    { title: 'Flag: Austria', character: '🇦🇹' },
    { title: 'Flag: Australia', character: '🇦🇺' },
    { title: 'Flag: Aruba', character: '🇦🇼' },
    { title: 'Flag: Åland Islands', character: '🇦🇽' },
    { title: 'Flag: Azerbaijan', character: '🇦🇿' },
    { title: 'Flag: Bosnia & Herzegovina', character: '🇧🇦' },
    { title: 'Flag: Barbados', character: '🇧🇧' },
    { title: 'Flag: Bangladesh', character: '🇧🇩' },
    { title: 'Flag: Belgium', character: '🇧🇪' },
    { title: 'Flag: Burkina Faso', character: '🇧🇫' },
    { title: 'Flag: Bulgaria', character: '🇧🇬' },
    { title: 'Flag: Bahrain', character: '🇧🇭' },
    { title: 'Flag: Burundi', character: '🇧🇮' },
    { title: 'Flag: Benin', character: '🇧🇯' },
    { title: 'Flag: St. Barthélemy', character: '🇧🇱' },
    { title: 'Flag: Bermuda', character: '🇧🇲' },
    { title: 'Flag: Brunei', character: '🇧🇳' },
    { title: 'Flag: Bolivia', character: '🇧🇴' },
    { title: 'Flag: Caribbean Netherlands', character: '🇧🇶' },
    { title: 'Flag: Brazil', character: '🇧🇷' },
    { title: 'Flag: Bahamas', character: '🇧🇸' },
    { title: 'Flag: Bhutan', character: '🇧🇹' },
    { title: 'Flag: Bouvet Island', character: '🇧🇻' },
    { title: 'Flag: Botswana', character: '🇧🇼' },
    { title: 'Flag: Belarus', character: '🇧🇾' },
    { title: 'Flag: Belize', character: '🇧🇿' },
    { title: 'Flag: Canada', character: '🇨🇦' },
    { title: 'Flag: Cocos (Keeling) Islands', character: '🇨🇨' },
    { title: 'Flag: Congo - Kinshasa', character: '🇨🇩' },
    { title: 'Flag: Central African Republic', character: '🇨🇫' },
    { title: 'Flag: Congo - Brazzaville', character: '🇨🇬' },
    { title: 'Flag: Switzerland', character: '🇨🇭' },
    { title: 'Flag: Côte d’Ivoire', character: '🇨🇮' },
    { title: 'Flag: Cook Islands', character: '🇨🇰' },
    { title: 'Flag: Chile', character: '🇨🇱' },
    { title: 'Flag: Cameroon', character: '🇨🇲' },
    { title: 'Flag: China', character: '🇨🇳' },
    { title: 'Flag: Colombia', character: '🇨🇴' },
    { title: 'Flag: Clipperton Island', character: '🇨🇵' },
    { title: 'Flag: Costa Rica', character: '🇨🇷' },
    { title: 'Flag: Cuba', character: '🇨🇺' },
    { title: 'Flag: Cape Verde', character: '🇨🇻' },
    { title: 'Flag: Curaçao', character: '🇨🇼' },
    { title: 'Flag: Christmas Island', character: '🇨🇽' },
    { title: 'Flag: Cyprus', character: '🇨🇾' },
    { title: 'Flag: Czechia', character: '🇨🇿' },
    { title: 'Flag: Germany', character: '🇩🇪' },
    { title: 'Flag: Diego Garcia', character: '🇩🇬' },
    { title: 'Flag: Djibouti', character: '🇩🇯' },
    { title: 'Flag: Denmark', character: '🇩🇰' },
    { title: 'Flag: Dominica', character: '🇩🇲' },
    { title: 'Flag: Dominican Republic', character: '🇩🇴' },
    { title: 'Flag: Algeria', character: '🇩🇿' },
    { title: 'Flag: Ceuta & Melilla', character: '🇪🇦' },
    { title: 'Flag: Ecuador', character: '🇪🇨' },
    { title: 'Flag: Estonia', character: '🇪🇪' },
    { title: 'Flag: Egypt', character: '🇪🇬' },
    { title: 'Flag: Western Sahara', character: '🇪🇭' },
    { title: 'Flag: Eritrea', character: '🇪🇷' },
    { title: 'Flag: Spain', character: '🇪🇸' },
    { title: 'Flag: Ethiopia', character: '🇪🇹' },
    { title: 'Flag: European Union', character: '🇪🇺' },
    { title: 'Flag: Finland', character: '🇫🇮' },
    { title: 'Flag: Fiji', character: '🇫🇯' },
    { title: 'Flag: Falkland Islands', character: '🇫🇰' },
    { title: 'Flag: Micronesia', character: '🇫🇲' },
    { title: 'Flag: Faroe Islands', character: '🇫🇴' },
    { title: 'Flag: France', character: '🇫🇷' },
    { title: 'Flag: Gabon', character: '🇬🇦' },
    { title: 'Flag: United Kingdom', character: '🇬🇧' },
    { title: 'Flag: Grenada', character: '🇬🇩' },
    { title: 'Flag: Georgia', character: '🇬🇪' },
    { title: 'Flag: French Guiana', character: '🇬🇫' },
    { title: 'Flag: Guernsey', character: '🇬🇬' },
    { title: 'Flag: Ghana', character: '🇬🇭' },
    { title: 'Flag: Gibraltar', character: '🇬🇮' },
    { title: 'Flag: Greenland', character: '🇬🇱' },
    { title: 'Flag: Gambia', character: '🇬🇲' },
    { title: 'Flag: Guinea', character: '🇬🇳' },
    { title: 'Flag: Guadeloupe', character: '🇬🇵' },
    { title: 'Flag: Equatorial Guinea', character: '🇬🇶' },
    { title: 'Flag: Greece', character: '🇬🇷' },
    { title: 'Flag: South Georgia & South Sandwich Islands', character: '🇬🇸' },
    { title: 'Flag: Guatemala', character: '🇬🇹' },
    { title: 'Flag: Guam', character: '🇬🇺' },
    { title: 'Flag: Guinea-Bissau', character: '🇬🇼' },
    { title: 'Flag: Guyana', character: '🇬🇾' },
    { title: 'Flag: Hong Kong SAR China', character: '🇭🇰' },
    { title: 'Flag: Heard & McDonald Islands', character: '🇭🇲' },
    { title: 'Flag: Honduras', character: '🇭🇳' },
    { title: 'Flag: Croatia', character: '🇭🇷' },
    { title: 'Flag: Haiti', character: '🇭🇹' },
    { title: 'Flag: Hungary', character: '🇭🇺' },
    { title: 'Flag: Canary Islands', character: '🇮🇨' },
    { title: 'Flag: Indonesia', character: '🇮🇩' },
    { title: 'Flag: Ireland', character: '🇮🇪' },
    { title: 'Flag: Israel', character: '🇮🇱' },
    { title: 'Flag: Isle of Man', character: '🇮🇲' },
    { title: 'Flag: India', character: '🇮🇳' },
    { title: 'Flag: British Indian Ocean Territory', character: '🇮🇴' },
    { title: 'Flag: Iraq', character: '🇮🇶' },
    { title: 'Flag: Iran', character: '🇮🇷' },
    { title: 'Flag: Iceland', character: '🇮🇸' },
    { title: 'Flag: Italy', character: '🇮🇹' },
    { title: 'Flag: Jersey', character: '🇯🇪' },
    { title: 'Flag: Jamaica', character: '🇯🇲' },
    { title: 'Flag: Jordan', character: '🇯🇴' },
    { title: 'Flag: Japan', character: '🇯🇵' },
    { title: 'Flag: Kenya', character: '🇰🇪' },
    { title: 'Flag: Kyrgyzstan', character: '🇰🇬' },
    { title: 'Flag: Cambodia', character: '🇰🇭' },
    { title: 'Flag: Kiribati', character: '🇰🇮' },
    { title: 'Flag: Comoros', character: '🇰🇲' },
    { title: 'Flag: St. Kitts & Nevis', character: '🇰🇳' },
    { title: 'Flag: North Korea', character: '🇰🇵' },
    { title: 'Flag: South Korea', character: '🇰🇷' },
    { title: 'Flag: Kuwait', character: '🇰🇼' },
    { title: 'Flag: Cayman Islands', character: '🇰🇾' },
    { title: 'Flag: Kazakhstan', character: '🇰🇿' },
    { title: 'Flag: Laos', character: '🇱🇦' },
    { title: 'Flag: Lebanon', character: '🇱🇧' },
    { title: 'Flag: St. Lucia', character: '🇱🇨' },
    { title: 'Flag: Liechtenstein', character: '🇱🇮' },
    { title: 'Flag: Sri Lanka', character: '🇱🇰' },
    { title: 'Flag: Liberia', character: '🇱🇷' },
    { title: 'Flag: Lesotho', character: '🇱🇸' },
    { title: 'Flag: Lithuania', character: '🇱🇹' },
    { title: 'Flag: Luxembourg', character: '🇱🇺' },
    { title: 'Flag: Latvia', character: '🇱🇻' },
    { title: 'Flag: Libya', character: '🇱🇾' },
    { title: 'Flag: Morocco', character: '🇲🇦' },
    { title: 'Flag: Monaco', character: '🇲🇨' },
    { title: 'Flag: Moldova', character: '🇲🇩' },
    { title: 'Flag: Montenegro', character: '🇲🇪' },
    { title: 'Flag: St. Martin', character: '🇲🇫' },
    { title: 'Flag: Madagascar', character: '🇲🇬' },
    { title: 'Flag: Marshall Islands', character: '🇲🇭' },
    { title: 'Flag: North Macedonia', character: '🇲🇰' },
    { title: 'Flag: Mali', character: '🇲🇱' },
    { title: 'Flag: Myanmar (Burma)', character: '🇲🇲' },
    { title: 'Flag: Mongolia', character: '🇲🇳' },
    { title: 'Flag: Macao Sar China', character: '🇲🇴' },
    { title: 'Flag: Northern Mariana Islands', character: '🇲🇵' },
    { title: 'Flag: Martinique', character: '🇲🇶' },
    { title: 'Flag: Mauritania', character: '🇲🇷' },
    { title: 'Flag: Montserrat', character: '🇲🇸' },
    { title: 'Flag: Malta', character: '🇲🇹' },
    { title: 'Flag: Mauritius', character: '🇲🇺' },
    { title: 'Flag: Maldives', character: '🇲🇻' },
    { title: 'Flag: Malawi', character: '🇲🇼' },
    { title: 'Flag: Mexico', character: '🇲🇽' },
    { title: 'Flag: Malaysia', character: '🇲🇾' },
    { title: 'Flag: Mozambique', character: '🇲🇿' },
    { title: 'Flag: Namibia', character: '🇳🇦' },
    { title: 'Flag: New Caledonia', character: '🇳🇨' },
    { title: 'Flag: Niger', character: '🇳🇪' },
    { title: 'Flag: Norfolk Island', character: '🇳🇫' },
    { title: 'Flag: Nigeria', character: '🇳🇬' },
    { title: 'Flag: Nicaragua', character: '🇳🇮' },
    { title: 'Flag: Netherlands', character: '🇳🇱' },
    { title: 'Flag: Norway', character: '🇳🇴' },
    { title: 'Flag: Nepal', character: '🇳🇵' },
    { title: 'Flag: Nauru', character: '🇳🇷' },
    { title: 'Flag: Niue', character: '🇳🇺' },
    { title: 'Flag: New Zealand', character: '🇳🇿' },
    { title: 'Flag: Oman', character: '🇴🇲' },
    { title: 'Flag: Panama', character: '🇵🇦' },
    { title: 'Flag: Peru', character: '🇵🇪' },
    { title: 'Flag: French Polynesia', character: '🇵🇫' },
    { title: 'Flag: Papua New Guinea', character: '🇵🇬' },
    { title: 'Flag: Philippines', character: '🇵🇭' },
    { title: 'Flag: Pakistan', character: '🇵🇰' },
    { title: 'Flag: Poland', character: '🇵🇱' },
    { title: 'Flag: St. Pierre & Miquelon', character: '🇵🇲' },
    { title: 'Flag: Pitcairn Islands', character: '🇵🇳' },
    { title: 'Flag: Puerto Rico', character: '🇵🇷' },
    { title: 'Flag: Palestinian Territories', character: '🇵🇸' },
    { title: 'Flag: Portugal', character: '🇵🇹' },
    { title: 'Flag: Palau', character: '🇵🇼' },
    { title: 'Flag: Paraguay', character: '🇵🇾' },
    { title: 'Flag: Qatar', character: '🇶🇦' },
    { title: 'Flag: Réunion', character: '🇷🇪' },
    { title: 'Flag: Romania', character: '🇷🇴' },
    { title: 'Flag: Serbia', character: '🇷🇸' },
    { title: 'Flag: Russia', character: '🇷🇺' },
    { title: 'Flag: Rwanda', character: '🇷🇼' },
    { title: 'Flag: Saudi Arabia', character: '🇸🇦' },
    { title: 'Flag: Solomon Islands', character: '🇸🇧' },
    { title: 'Flag: Seychelles', character: '🇸🇨' },
    { title: 'Flag: Sudan', character: '🇸🇩' },
    { title: 'Flag: Sweden', character: '🇸🇪' },
    { title: 'Flag: Singapore', character: '🇸🇬' },
    { title: 'Flag: St. Helena', character: '🇸🇭' },
    { title: 'Flag: Slovenia', character: '🇸🇮' },
    { title: 'Flag: Svalbard & Jan Mayen', character: '🇸🇯' },
    { title: 'Flag: Slovakia', character: '🇸🇰' },
    { title: 'Flag: Sierra Leone', character: '🇸🇱' },
    { title: 'Flag: San Marino', character: '🇸🇲' },
    { title: 'Flag: Senegal', character: '🇸🇳' },
    { title: 'Flag: Somalia', character: '🇸🇴' },
    { title: 'Flag: Suriname', character: '🇸🇷' },
    { title: 'Flag: South Sudan', character: '🇸🇸' },
    { title: 'Flag: São Tomé & Príncipe', character: '🇸🇹' },
    { title: 'Flag: El Salvador', character: '🇸🇻' },
    { title: 'Flag: Sint Maarten', character: '🇸🇽' },
    { title: 'Flag: Syria', character: '🇸🇾' },
    { title: 'Flag: Eswatini', character: '🇸🇿' },
    { title: 'Flag: Tristan Da Cunha', character: '🇹🇦' },
    { title: 'Flag: Turks & Caicos Islands', character: '🇹🇨' },
    { title: 'Flag: Chad', character: '🇹🇩' },
    { title: 'Flag: French Southern Territories', character: '🇹🇫' },
    { title: 'Flag: Togo', character: '🇹🇬' },
    { title: 'Flag: Thailand', character: '🇹🇭' },
    { title: 'Flag: Tajikistan', character: '🇹🇯' },
    { title: 'Flag: Tokelau', character: '🇹🇰' },
    { title: 'Flag: Timor-Leste', character: '🇹🇱' },
    { title: 'Flag: Turkmenistan', character: '🇹🇲' },
    { title: 'Flag: Tunisia', character: '🇹🇳' },
    { title: 'Flag: Tonga', character: '🇹🇴' },
    { title: 'Flag: Turkey', character: '🇹🇷' },
    { title: 'Flag: Trinidad & Tobago', character: '🇹🇹' },
    { title: 'Flag: Tuvalu', character: '🇹🇻' },
    { title: 'Flag: Taiwan', character: '🇹🇼' },
    { title: 'Flag: Tanzania', character: '🇹🇿' },
    { title: 'Flag: Ukraine', character: '🇺🇦' },
    { title: 'Flag: Uganda', character: '🇺🇬' },
    { title: 'Flag: U.S. Outlying Islands', character: '🇺🇲' },
    { title: 'Flag: United Nations', character: '🇺🇳' },
    { title: 'Flag: United States', character: '🇺🇸' },
    { title: 'Flag: Uruguay', character: '🇺🇾' },
    { title: 'Flag: Uzbekistan', character: '🇺🇿' },
    { title: 'Flag: Vatican City', character: '🇻🇦' },
    { title: 'Flag: St. Vincent & Grenadines', character: '🇻🇨' },
    { title: 'Flag: Venezuela', character: '🇻🇪' },
    { title: 'Flag: British Virgin Islands', character: '🇻🇬' },
    { title: 'Flag: U.S. Virgin Islands', character: '🇻🇮' },
    { title: 'Flag: Vietnam', character: '🇻🇳' },
    { title: 'Flag: Vanuatu', character: '🇻🇺' },
    { title: 'Flag: Wallis & Futuna', character: '🇼🇫' },
    { title: 'Flag: Samoa', character: '🇼🇸' },
    { title: 'Flag: Kosovo', character: '🇽🇰' },
    { title: 'Flag: Yemen', character: '🇾🇪' },
    { title: 'Flag: Mayotte', character: '🇾🇹' },
    { title: 'Flag: South Africa', character: '🇿🇦' },
    { title: 'Flag: Zambia', character: '🇿🇲' },
    { title: 'Flag: Zimbabwe', character: '🇿🇼' },
    { title: 'Flag: England', character: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
    { title: 'Flag: Scotland', character: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
    { title: 'Flag: Wales', character: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
    { title: 'Flag for Texas (US-TX)', character: '🏴󠁵󠁳󠁴󠁸󠁿' },
  ],
};

export default emojis;
