import { NgClass } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ISelectEvent {
  value: number;
}

@Component({
  selector: 'locumsnest-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
  imports: [NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent {
  @Input() displayText: string;
  @Input() value: number;
  @Input() checked: boolean;
  @Input() isDisabled: boolean;
  @Input() smaller = false;
  @Input() darkTheme = false;
  @Input() hideDot = false;

  @Output() selected: EventEmitter<ISelectEvent> = new EventEmitter();

  onChange() {
    this.selected.emit({ value: this.value });
  }
}
