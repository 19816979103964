import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as fileSaver from 'file-saver';
import { FormControlState, NgrxFormsModule } from 'ngrx-forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, first, isEmpty, map, tap } from 'rxjs/operators';

import { FileUploadComponent } from '@locumsnest/components/src/lib/molecules/file-upload/file-upload.component';
import { isRelativeUrl, isUrl } from '@locumsnest/core/src/lib/helpers/file';
import { getFileNameFromControlValue } from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/value-converters.helper';

import { ProgressBarComponent } from '../../atoms/progress-bar/progress-bar.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DownloadLinkChangedEvent } from './../../molecules/file-upload/interfaces';

@Component({
  selector: 'locumsnest-file-uploader',
  templateUrl: './file-uploader.component.html',
  standalone: true,
  imports: [
    FileUploadComponent,
    FormsModule,
    ProgressBarComponent,
    NgrxFormsModule,
    PipesModule,
    CommonModule,
  ],
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FileUploadComponent, { static: true }) fileUpload: FileUploadComponent;

  @Input() isDisabled: boolean;
  @Input() file: FormControlState<string>;
  @Input() showFileDetails: boolean;
  @Input() showUploadButton: boolean;
  @Input() acceptFormats = '*';
  @Input() acceptFormatsUi = '*';
  @Input() compact = false;
  @Input() parseCsv = false;
  @Input() transparentTheme: boolean;
  @Input() maxSize: number;
  @Input() tempFileUpload = false;
  @Input() blueTheme = false;

  @Output() removeFile = new EventEmitter<string>();

  public uploading: boolean;
  public percentage: number;
  public fileSize: number;
  public downloadLink$ = new BehaviorSubject<string>('');
  private downloadLinkSubscription = new Subscription();

  get fileName() {
    return this.file.value && getFileNameFromControlValue(this.file.value);
  }

  onRemoveFileBtnClicked($event: Event) {
    $event.stopPropagation();
    this.fileUpload.writeValue('');
    this.removeFile.emit(this.fileName);
  }

  onCancelBtnClicked() {
    this.fileUpload.writeValue('');
    this.removeFile.emit(this.fileName);
  }
  openFileUrl(url) {
    window.open(url, '_blank');
  }
  downloadFile() {
    if (isUrl(this.file.value)) {
      this.openFileUrl(this.file.value);
      return;
    }
    this.downloadLink$
      .pipe(
        first(),
        filter((fileUrl) => !!fileUrl),
        tap((fileUrl) => this.openFileUrl(fileUrl)),
        isEmpty(),
      )
      .subscribe((noLinkUsed) => {
        if (noLinkUsed) {
          this.downloadBase64File();
        }
      });
  }

  onUploadProgressUpdated(fileObj) {
    this.fileSize = fileObj.file.size;
    this.percentage = fileObj.progress;
    this.uploading = this.percentage < 100 && this.percentage > 0;
  }

  public ngAfterViewInit() {
    this.downloadLinkSubscription = this.fileUpload.downloadLinkChanged
      .pipe(map(({ downloadLink }: DownloadLinkChangedEvent) => downloadLink))
      .subscribe(this.downloadLink$);
  }

  public ngOnDestroy() {
    this.downloadLinkSubscription.unsubscribe();
  }

  private downloadBase64File() {
    const file = JSON.parse(this.file.value);
    if (file) {
      if (isRelativeUrl(file.base64EncodedFile)) {
        const topHref = window.name;
        const topHrefSessions = topHref.split('/');
        const rootUrl = topHrefSessions.splice(0, topHrefSessions.length - 3).join('/');
        const fileUrl = rootUrl + file.base64EncodedFile.replace(rootUrl, '');
        window.top.location.href = fileUrl;
      } else {
        const blob = JSON.parse(this.file.value).base64EncodedFile;
        const filename = JSON.parse(this.file.value).name;
        fileSaver.saveAs(blob, filename);
      }
    }
  }
}
