import { Component, EventEmitter, Input, Output } from '@angular/core';

type STATUS = 'ok' | 'alert' | 'warning';
type SIZE = 'xsmall' | 'small' | 'normal' | 'large';

@Component({
  selector: 'locumsnest-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
  @Output() statusChange = new EventEmitter();
  @Input() size: SIZE = 'normal';
  protected _status: STATUS = 'ok';
  constructor() {}

  get status() {
    return this._status;
  }
  @Input()
  set status(status: STATUS) {
    if (status !== this.status) {
      this._status = status;
      this.onStatusChange();
    }
  }

  onStatusChange() {
    this.statusChange.emit(this.status);
  }
}
