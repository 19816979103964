import { NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  numberAttribute,
  Output,
} from '@angular/core';
import { FormControlState, NgrxFormsModule } from 'ngrx-forms';

import { AtomsModule } from '../../atoms';

@Component({
  selector: 'locumsnest-input-with-icon',
  templateUrl: './input-with-icon.component.html',
  styleUrls: ['./input-with-icon.component.scss'],
  standalone: true,
  imports: [AtomsModule, NgrxFormsModule, NgStyle, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWithIconComponent {
  @Input({ required: true }) iconName: string;
  @Input({ required: true }) inputControl: FormControlState<string>;
  @Input({ transform: numberAttribute }) iconSize = 20;
  @Input({ transform: numberAttribute }) maxLength: number;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() nonClickable = false;
  @Output() buttonClicked = new EventEmitter<void>();
  @Output() inputEntered = new EventEmitter<Event>();
}
