<div
  class="wrapper"
  [ngClass]="{
    'sender-right': message.isOfficer && isTimesheetView,
    'sender-left': !message.isOfficer && isTimesheetView,
    'sender-right-zendesk': message.isOfficer && !isTimesheetView,
    'sender-left-zendesk': !message.isOfficer && !isTimesheetView,
    'alert-message': message.isAlert
  }"
  [locumsnestScrollTo]="isLast"
>
  <div class="message">
    <div
      class="text"
      locumsnestAddAnchorBlank
      [innerHTML]="message.messageText"
      [ngClass]="{ 'text--compact': isTimesheetView }"
    ></div>

    <div class="sender" [ngClass]="{ 'sender--timesheet': isTimesheetView }">
      <locumsnest-avatar [photoUrl]="null" />
    </div>
  </div>
  <div
    class="attachments"
    [ngClass]="{
      'attachments-left': !message.isOfficer,
      'attachments-right': message.isOfficer
    }"
  >
    <a
      *ngFor="let item of message.attachments; trackBy: trackById"
      class="attachments__href"
      target="_blank"
      [href]="item.contentUrl"
    >
      <img
        *ngIf="item.thumbnails[0]?.contentUrl; else noImage"
        class="attachments__href__image"
        [alt]="item.thumbnails[0]?.fileName"
        [src]="item.thumbnails[0]?.contentUrl"
      />
      <ng-template #noImage>
        <div class="attachments__href__file" [title]="item.fileName">
          <div class="attachments__href__file__icon"></div>
          <locumsnest-ellipsis [text]="item.fileName" />
        </div>
      </ng-template>
    </a>
  </div>
  <div class="message-info">
    <span class="name">{{ message.sender?.firstName }} {{ message.sender?.lastName }} - </span>
    <span class="time">{{ message.createdAt | dateFormat }}</span>
  </div>
</div>
