import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[locumsnestTabHeader]',
  standalone: true,
})
export class TabHeaderDirective {
  @Input('locumsnestTabHeader') set tabHeader(title: string | TemplateRef<any>) {
    if (title instanceof TemplateRef) {
      this.viewContainer.createEmbeddedView(title);
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: title,
      });
    }
  }

  private templateRef = inject(TemplateRef<any>);
  private viewContainer = inject(ViewContainerRef);
}
