import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { PipesModule } from '../../pipes/pipes.module';

@Component({
  selector: 'locumsnest-progress-bar',
  standalone: true,
  imports: [CommonModule, PipesModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() percentage: number;
  constructor() {}
}
