import {
  Component,
  Input,
  QueryList,
  ContentChildren,
  AfterContentInit,
  ChangeDetectorRef,
} from '@angular/core';
import { StepComponent } from '../../atoms/step/step.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'locumsnest-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterContentInit {
  protected _destroyed = new Subject<void>();

  @ContentChildren(StepComponent, { descendants: true }) steps: QueryList<StepComponent>;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.steps.changes.pipe(takeUntil(this._destroyed)).subscribe(() => {
      this._stateChanged();
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private _stateChanged() {
    this._changeDetectorRef.markForCheck();
  }

  _trackByStep(index, step) {
    return step ? step.id : null;
  }
}
