import { SelectionModel } from '@angular/cdk/collections';
import { NgClass } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { TagComponent } from '../tag/tag.component';

export type TagValue<T> = T | T[] | null;

@Component({
  selector: 'ln-tag-group',
  standalone: true,
  imports: [NgClass, TagComponent],
  templateUrl: './tag-group.component.html',
  styleUrl: './tag-group.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TagGroupComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagGroupComponent<T>
  implements AfterContentInit, OnChanges, OnDestroy, ControlValueAccessor
{
  private destroy$ = new Subject<void>();
  private tagsMap = new Map<string | number, TagComponent<T>>();
  private cd = inject(ChangeDetectorRef);

  @Input() disabled = false;
  @Input()
  set value(value: TagValue<T>) {
    this.setupValue(value);
    this.onChange(this.value);
    this.setSelectedOptions();
  }

  get value() {
    if (this.selectionModel.isEmpty()) {
      if (this.selectionModel.isMultipleSelection()) {
        return [];
      }
      return null;
    }
    if (this.selectionModel.isMultipleSelection()) {
      return this.selectionModel.selected;
    }
    return this.selectionModel.selected[0];
  }

  @Input() compareWithKey: ((value: T) => string | number) | null = (value) =>
    value as string | number;
  @Input() compareWith: (v1: T, v2: T) => boolean = (v1, v2) => v1 === v2;

  @HostListener('blur') markAsTouched() {
    this.onTouched();
    this.cd.markForCheck();
  }

  @Output() selectionChanged = new EventEmitter<TagValue<T>>();

  @ContentChildren(TagComponent, { descendants: true }) tags: QueryList<TagComponent<T>>;

  ngAfterContentInit(): void {
    this.selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe((values) => {
      values.removed.forEach((rv) => this.tagsMap.get(this.compareWithKey(rv))?.deselect());
      values.added.forEach((av) => this.tagsMap.get(this.compareWithKey(av))?.setSelected());
    });
    this.tags.changes
      .pipe(
        startWith<QueryList<TagComponent<T>>>(this.tags),
        tap(() => this.refreshTagMap()),
        tap(() => queueMicrotask(() => this.setSelectedOptions())),
        switchMap((tags) => merge(...tags.map((t) => t.selected))),
        takeUntil(this.destroy$),
      )
      .subscribe((selectedTag) => {
        this.handleSelection(selectedTag);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['compareWith']) {
      this.selectionModel.compareWith = changes['compareWith'].currentValue;
      this.setSelectedOptions();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(value: TagValue<T>): void {
    this.setupValue(value);
    this.setSelectedOptions();
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    if (!this.disabled) {
      this.onTouched = fn;
    }
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  protected onChange: (newValue: TagValue<T>) => void = () => {};
  protected onTouched: () => void = () => {};

  private selectionModel = new SelectionModel<T>(true);

  private setupValue(value: TagValue<T>) {
    this.selectionModel.clear();
    if (value) {
      if (Array.isArray(value)) {
        this.selectionModel.select(...value);
      } else {
        this.selectionModel.select(value);
      }
    }
  }

  private setSelectedOptions() {
    const valuesWithUpdatedReferences = this.selectionModel.selected.map((val) => {
      const correspondingTag = this.findTagByValue(val);
      return correspondingTag ? correspondingTag.value : val;
    });
    this.selectionModel.clear();
    this.selectionModel.select(...valuesWithUpdatedReferences);
  }

  private findTagByValue(value: T | null) {
    if (this.tagsMap.has(this.compareWithKey(value))) {
      return this.tagsMap.get(this.compareWithKey(value));
    }
    return this.tags && this.tags.find((t) => this.compareWith(t.value, value));
  }

  private handleSelection(tag: TagComponent<T>) {
    if (tag.value) {
      this.selectionModel.toggle(tag.value);
      this.selectionChanged.emit(this.value);
      this.onChange(this.value);
      this.cd.markForCheck();
    }
  }

  private refreshTagMap() {
    this.tagsMap.clear();
    this.tags.forEach((t) => this.tagsMap.set(this.compareWithKey(t.value), t));
  }
}
