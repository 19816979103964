/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src';

import { createTabsAdapter } from './adapter';
import { IBaseTabsService } from './interfaces';

export const featureKey = 'filterTabs';
const namespace = 'FilterTabs';

const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
export const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
export const tabsAdapter = createTabsAdapter(featureKey, messageableFactory, signalableFactory);

export const reducer = tabsAdapter.createReducer();

export interface TabsService extends IBaseTabsService {}

export const {
  AddActiveTabSignal,
  AddHomeTabSignal,
  InitializeTabsSignal,
  RemoveTabSignal,
  RevertToDefaultsHomeFiltersSignal,
  SaveActiveTabFiltersSignal,
  UpdateActiveTabSignal,
  UpdateEditTabSignal,
  UpdateTabNameSignal,
} = tabsAdapter.getSignals();

@Injectable({ providedIn: 'root' })
@tabsAdapter.tabsService()
export class TabsService {
  constructor(public store: Store) {}
}
