import { Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
  @Input() title: string;
  @Input() image: string;
  @Input() description: string;
  @Input() activated: boolean;

  public showDetails = false;

  constructor() {}

  public onInfoClicked() {
    this.showDetails = !this.showDetails;
  }
}
