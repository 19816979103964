import { Component, ContentChildren, QueryList } from '@angular/core';

import { CardWizardStepComponent } from '../card-wizard-step/card-wizard-step.component';

@Component({
  selector: 'locumsnest-card-wizard',
  templateUrl: './card-wizard.component.html',
  styleUrls: ['./card-wizard.component.scss'],
})
export class CardWizardComponent {
  @ContentChildren(CardWizardStepComponent, { descendants: true })
  steps: QueryList<CardWizardStepComponent>;

  trackByStep(index: number, step: CardWizardStepComponent) {
    return step?.stepNumber || index;
  }
}
