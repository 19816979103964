<button
  class="btn {{ extraClass }}"
  type="button"
  [id]="idx"
  [class.full-width]="fullWidth"
  [ngClass]="{
    'btn-small': size === 'small',
    'btn-medium': size === 'medium',
    'btn-xsmall': size === 'xsmall',
    'btn-radius-0': radius === 'radius-0',
    'btn-radius-5': radius === 'radius-5',
    'btn-radius-25': radius === 'radius-25',
    'btn-selected': isSelected === true,
    'btn-back': buttonType === 'back',
    'btn-accept': buttonType === 'accept',
    'btn-accept-dark': buttonType === 'accept-dark',
    'btn-info': buttonType === 'info',
    'btn-decline': buttonType === 'decline',
    'btn-cancel': buttonType === 'cancel',
    'btn-warning-accept': buttonType === 'warning-accept',
    'btn-warning-decline': buttonType === 'warning-decline',
    'btn-alert': buttonType === 'alert',
    'btn-onboarding-back': buttonType === 'onboarding-back',
    'btn-onboarding-next': buttonType === 'onboarding-next',
    'btn-onboarding-save': buttonType === 'onboarding-save',
    'btn-transparent': buttonType === 'transparent',
    'btn-go': buttonType === 'go',
    'btn-confirm': buttonType === 'confirm',
    'btn-save': buttonType === 'save',
    'btn-actioned': buttonType === 'actioned',
    'btn-bot': buttonType === 'bot',
    'btn-dismiss': buttonType === 'dismiss',
    'btn-bold': hasBoldFont === true,
    'btn-tag': buttonType === 'tag',
    bordered: isBordered === true
  }"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
