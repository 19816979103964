import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMessageListItem } from 'apps/hospital-admin/src/app/messaging-panel/+state/interfaces/messaging-ui-state';
import moment from 'moment-timezone';

import { IMessengerMessage } from '../../interfaces/message';
import { IUser } from '../../interfaces/user';

@Component({
  selector: 'locumsnest-message-list-item',
  templateUrl: './message-list-item.component.html',
  styleUrls: ['./message-list-item.component.scss'],
})
export class MessageListItemComponent {
  @Input() messageListItem: IMessageListItem;

  @Output() messageSelected = new EventEmitter<number>();

  constructor() {}

  fromNow(date) {
    const formattedDate = moment(date).fromNow();
    return formattedDate;
  }

  public getLastMessage(messages: IMessengerMessage[]) {
    return messages.length > 0 ? messages[messages.length - 1].messageText : '';
  }

  trackByFirstLastName(index, receiver: IUser) {
    return receiver ? receiver.firstName + ' ' + receiver.lastName : null;
  }

  public onMessageSelected(id: number) {
    this.messageSelected.emit(id);
  }
}
