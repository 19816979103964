@if (displayAlert()) {
  <div
    class="toast-container-ln row p-2 gap-2 align-items-center"
    @inOut
    role="alert"
    (click)="onToastClicked()"
  >
    @switch (alertType()) {
      @case ('success') {
        <div class="col-2 success-icon"></div>
      }
      @case ('info') {
        <div class="col-2 success-icon"></div>
      }
      @case ('warn') {
        <div class="col-2 warn-icon"></div>
      }
      @case ('error') {
        <div class="col-2 error-icon"></div>
      }
    }

    <div class="col text" [innerHTML]="message()"></div>
  </div>
}
