import { Component, Input } from '@angular/core';

type AlertType = 'error' | 'info' | 'success';

@Component({
  selector: 'locumsnest-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrls: ['./alert-container.component.scss'],
})
export class AlertContainerComponent {
  @Input() top: number;
  @Input() displayAlert = false;
  @Input() message = '';
  @Input() alertType: AlertType = 'error';
  @Input() limitToMaxWidth = false;
  @Input() fullWidth = false;

  constructor() {}
}
