const emojis = {
  '💡 Objects': [
    { title: 'Love Letter', character: '💌' },
    { title: 'Hole', character: '🕳️' },
    { title: 'Bomb', character: '💣' },
    { title: 'Person Taking Bath', character: '🛀' },
    { title: 'Person in Bed', character: '🛌' },
    { title: 'Kitchen Knife', character: '🔪' },
    { title: 'Amphora', character: '🏺' },
    { title: 'World Map', character: '🗺️' },
    { title: 'Compass', character: '🧭' },
    { title: 'Brick', character: '🧱' },
    { title: 'Barber Pole', character: '💈' },
    { title: 'Manual Wheelchair', character: '🦽' },
    { title: 'Motorized Wheelchair', character: '🦼' },
    { title: 'Oil Drum', character: '🛢️' },
    { title: 'Bellhop Bell', character: '🛎️' },
    { title: 'Luggage', character: '🧳' },
    { title: 'Hourglass Done', character: '⌛' },
    { title: 'Hourglass Not Done', character: '⏳' },
    { title: 'Watch', character: '⌚' },
    { title: 'Alarm Clock', character: '⏰' },
    { title: 'Stopwatch', character: '⏱️' },
    { title: 'Timer Clock', character: '⏲️' },
    { title: 'Mantelpiece Clock', character: '🕰️' },
    { title: 'Thermometer', character: '🌡️' },
    { title: 'Umbrella on Ground', character: '⛱️' },
    { title: 'Firecracker', character: '🧨' },
    { title: 'Balloon', character: '🎈' },
    { title: 'Party Popper', character: '🎉' },
    { title: 'Confetti Ball', character: '🎊' },
    { title: 'Japanese Dolls', character: '🎎' },
    { title: 'Carp Streamer', character: '🎏' },
    { title: 'Wind Chime', character: '🎐' },
    { title: 'Red Envelope', character: '🧧' },
    { title: 'Ribbon', character: '🎀' },
    { title: 'Wrapped Gift', character: '🎁' },
    { title: 'Diving Mask', character: '🤿' },
    { title: 'Yo-Yo', character: '🪀' },
    { title: 'Kite', character: '🪁' },
    { title: 'Crystal Ball', character: '🔮' },
    { title: 'Magic Wand', character: '🪄' },
    { title: 'Nazar Amulet', character: '🧿' },
    { title: 'Joystick', character: '🕹️' },
    { title: 'Teddy Bear', character: '🧸' },
    { title: 'Piñata', character: '🪅' },
    { title: 'Nesting Dolls', character: '🪆' },
    { title: 'Framed Picture', character: '🖼️' },
    { title: 'Thread', character: '🧵' },
    { title: 'Sewing Needle', character: '🪡' },
    { title: 'Yarn', character: '🧶' },
    { title: 'Knot', character: '🪢' },
    { title: 'Shopping Bags', character: '🛍️' },
    { title: 'Prayer Beads', character: '📿' },
    { title: 'Gem Stone', character: '💎' },
    { title: 'Postal Horn', character: '📯' },
    { title: 'Studio Microphone', character: '🎙️' },
    { title: 'Level Slider', character: '🎚️' },
    { title: 'Control Knobs', character: '🎛️' },
    { title: 'Radio', character: '📻' },
    { title: 'Banjo', character: '🪕' },
    { title: 'Mobile Phone', character: '📱' },
    { title: 'Mobile Phone with Arrow', character: '📲' },
    { title: 'Telephone', character: '☎️' },
    { title: 'Telephone Receiver', character: '📞' },
    { title: 'Pager', character: '📟' },
    { title: 'Fax Machine', character: '📠' },
    { title: 'Battery', character: '🔋' },
    { title: 'Electric Plug', character: '🔌' },
    { title: 'Laptop', character: '💻' },
    { title: 'Desktop Computer', character: '🖥️' },
    { title: 'Printer', character: '🖨️' },
    { title: 'Keyboard', character: '⌨️' },
    { title: 'Computer Mouse', character: '🖱️' },
    { title: 'Trackball', character: '🖲️' },
    { title: 'Computer Disk', character: '💽' },
    { title: 'Floppy Disk', character: '💾' },
    { title: 'Optical Disk', character: '💿' },
    { title: 'DVD', character: '📀' },
    { title: 'Abacus', character: '🧮' },
    { title: 'Movie Camera', character: '🎥' },
    { title: 'Film Frames', character: '🎞️' },
    { title: 'Film Projector', character: '📽️' },
    { title: 'Television', character: '📺' },
    { title: 'Camera', character: '📷' },
    { title: 'Camera with Flash', character: '📸' },
    { title: 'Video Camera', character: '📹' },
    { title: 'Videocassette', character: '📼' },
    { title: 'Magnifying Glass Tilted Left', character: '🔍' },
    { title: 'Magnifying Glass Tilted Right', character: '🔎' },
    { title: 'Candle', character: '🕯️' },
    { title: 'Light Bulb', character: '💡' },
    { title: 'Flashlight', character: '🔦' },
    { title: 'Red Paper Lantern', character: '🏮' },
    { title: 'Diya Lamp', character: '🪔' },
    { title: 'Notebook with Decorative Cover', character: '📔' },
    { title: 'Closed Book', character: '📕' },
    { title: 'Open Book', character: '📖' },
    { title: 'Green Book', character: '📗' },
    { title: 'Blue Book', character: '📘' },
    { title: 'Orange Book', character: '📙' },
    { title: 'Books', character: '📚' },
    { title: 'Notebook', character: '📓' },
    { title: 'Ledger', character: '📒' },
    { title: 'Page with Curl', character: '📃' },
    { title: 'Scroll', character: '📜' },
    { title: 'Page Facing Up', character: '📄' },
    { title: 'Newspaper', character: '📰' },
    { title: 'Rolled-Up Newspaper', character: '🗞️' },
    { title: 'Bookmark Tabs', character: '📑' },
    { title: 'Bookmark', character: '🔖' },
    { title: 'Label', character: '🏷️' },
    { title: 'Money Bag', character: '💰' },
    { title: 'Coin', character: '🪙' },
    { title: 'Yen Banknote', character: '💴' },
    { title: 'Dollar Banknote', character: '💵' },
    { title: 'Euro Banknote', character: '💶' },
    { title: 'Pound Banknote', character: '💷' },
    { title: 'Money with Wings', character: '💸' },
    { title: 'Credit Card', character: '💳' },
    { title: 'Receipt', character: '🧾' },
    { title: 'Envelope', character: '✉️' },
    { title: 'E-Mail', character: '📧' },
    { title: 'Incoming Envelope', character: '📨' },
    { title: 'Envelope with Arrow', character: '📩' },
    { title: 'Outbox Tray', character: '📤' },
    { title: 'Inbox Tray', character: '📥' },
    { title: 'Package', character: '📦' },
    { title: 'Closed Mailbox with Raised Flag', character: '📫' },
    { title: 'Closed Mailbox with Lowered Flag', character: '📪' },
    { title: 'Open Mailbox with Raised Flag', character: '📬' },
    { title: 'Open Mailbox with Lowered Flag', character: '📭' },
    { title: 'Postbox', character: '📮' },
    { title: 'Ballot Box with Ballot', character: '🗳️' },
    { title: 'Pencil', character: '✏️' },
    { title: 'Black Nib', character: '✒️' },
    { title: 'Fountain Pen', character: '🖋️' },
    { title: 'Pen', character: '🖊️' },
    { title: 'Paintbrush', character: '🖌️' },
    { title: 'Crayon', character: '🖍️' },
    { title: 'Memo', character: '📝' },
    { title: 'File Folder', character: '📁' },
    { title: 'Open File Folder', character: '📂' },
    { title: 'Card Index Dividers', character: '🗂️' },
    { title: 'Calendar', character: '📅' },
    { title: 'Tear-Off Calendar', character: '📆' },
    { title: 'Spiral Notepad', character: '🗒️' },
    { title: 'Spiral Calendar', character: '🗓️' },
    { title: 'Card Index', character: '📇' },
    { title: 'Chart Increasing', character: '📈' },
    { title: 'Chart Decreasing', character: '📉' },
    { title: 'Bar Chart', character: '📊' },
    { title: 'Clipboard', character: '📋' },
    { title: 'Pushpin', character: '📌' },
    { title: 'Round Pushpin', character: '📍' },
    { title: 'Paperclip', character: '📎' },
    { title: 'Linked Paperclips', character: '🖇️' },
    { title: 'Straight Ruler', character: '📏' },
    { title: 'Triangular Ruler', character: '📐' },
    { title: 'Scissors', character: '✂️' },
    { title: 'Card File Box', character: '🗃️' },
    { title: 'File Cabinet', character: '🗄️' },
    { title: 'Wastebasket', character: '🗑️' },
    { title: 'Locked', character: '🔒' },
    { title: 'Unlocked', character: '🔓' },
    { title: 'Locked with Pen', character: '🔏' },
    { title: 'Locked with Key', character: '🔐' },
    { title: 'Key', character: '🔑' },
    { title: 'Old Key', character: '🗝️' },
    { title: 'Hammer', character: '🔨' },
    { title: 'Axe', character: '🪓' },
    { title: 'Pick', character: '⛏️' },
    { title: 'Hammer and Pick', character: '⚒️' },
    { title: 'Hammer and Wrench', character: '🛠️' },
    { title: 'Dagger', character: '🗡️' },
    { title: 'Crossed Swords', character: '⚔️' },
    { title: 'Pistol', character: '🔫' },
    { title: 'Boomerang', character: '🪃' },
    { title: 'Shield', character: '🛡️' },
    { title: 'Carpentry Saw', character: '🪚' },
    { title: 'Wrench', character: '🔧' },
    { title: 'Screwdriver', character: '🪛' },
    { title: 'Nut and Bolt', character: '🔩' },
    { title: 'Gear', character: '⚙️' },
    { title: 'Clamp', character: '🗜️' },
    { title: 'Balance Scale', character: '⚖️' },
    { title: 'White Cane', character: '🦯' },
    { title: 'Link', character: '🔗' },
    { title: 'Chains', character: '⛓️' },
    { title: 'Hook', character: '🪝' },
    { title: 'Toolbox', character: '🧰' },
    { title: 'Magnet', character: '🧲' },
    { title: 'Ladder', character: '🪜' },
    { title: 'Alembic', character: '⚗️' },
    { title: 'Test Tube', character: '🧪' },
    { title: 'Petri Dish', character: '🧫' },
    { title: 'DNA', character: '🧬' },
    { title: 'Microscope', character: '🔬' },
    { title: 'Telescope', character: '🔭' },
    { title: 'Satellite Antenna', character: '📡' },
    { title: 'Syringe', character: '💉' },
    { title: 'Drop of Blood', character: '🩸' },
    { title: 'Pill', character: '💊' },
    { title: 'Adhesive Bandage', character: '🩹' },
    { title: 'Stethoscope', character: '🩺' },
    { title: 'Door', character: '🚪' },
    { title: 'Mirror', character: '🪞' },
    { title: 'Window', character: '🪟' },
    { title: 'Bed', character: '🛏️' },
    { title: 'Couch and Lamp', character: '🛋️' },
    { title: 'Chair', character: '🪑' },
    { title: 'Toilet', character: '🚽' },
    { title: 'Plunger', character: '🪠' },
    { title: 'Shower', character: '🚿' },
    { title: 'Bathtub', character: '🛁' },
    { title: 'Mouse Trap', character: '🪤' },
    { title: 'Razor', character: '🪒' },
    { title: 'Lotion Bottle', character: '🧴' },
    { title: 'Safety Pin', character: '🧷' },
    { title: 'Broom', character: '🧹' },
    { title: 'Basket', character: '🧺' },
    { title: 'Roll of Paper', character: '🧻' },
    { title: 'Bucket', character: '🪣' },
    { title: 'Soap', character: '🧼' },
    { title: 'Toothbrush', character: '🪥' },
    { title: 'Sponge', character: '🧽' },
    { title: 'Fire Extinguisher', character: '🧯' },
    { title: 'Shopping Cart', character: '🛒' },
    { title: 'Cigarette', character: '🚬' },
    { title: 'Coffin', character: '⚰️' },
    { title: 'Headstone', character: '🪦' },
    { title: 'Funeral Urn', character: '⚱️' },
    { title: 'Moai', character: '🗿' },
    { title: 'Placard', character: '🪧' },
    { title: 'Potable Water', character: '🚰' },
  ],
};

export default emojis;
