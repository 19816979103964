import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'locumsnest-lock-button',
  templateUrl: './lock-button.component.html',
  styleUrls: ['./lock-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockButtonComponent {
  @Input() lockState: boolean;
  @Input() disabled: boolean;
  @Output() toggleState = new EventEmitter<boolean>();

  toggle() {
    this.toggleState.emit(!this.lockState);
  }
}
