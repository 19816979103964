import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { InputWrapper } from '@locumsnest/components/src/lib/core/input-wrapper';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'locumsnest-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileDownloadComponent),
      multi: true,
    },
  ],
})
export class FileDownloadComponent extends InputWrapper implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() link: string;
  @Output() downloadBtnClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngOnInit() {}

  public onDownloadBtnClicked(link: string) {
    this.downloadBtnClicked.emit();
  }
}
