<div
  class="recent-activity-container-scroller"
  infiniteScroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="5"
  [infiniteScrollThrottle]="30"
  (scrolled)="onScroll()"
>
  <ng-content></ng-content>
  <ng-container *ngIf="isLoading; else recentActivityTemplate">
    <ng-container *ngTemplateOutlet="loadingOrEmpty; context: { $implicit: true }"> </ng-container>
  </ng-container>
</div>

<ng-template #recentActivityTemplate>
  <ng-container *ngIf="recentActivity?.length; else loadingOrEmpty">
    <div
      *ngFor="let row of recentActivity; let first = first; trackBy: trackById"
      class="recent-activity-table"
      [locumsnestScrollTo]="first"
    >
      <div class="recent-activity-table__row">
        <div class="recent-activity-table__row__date">{{ row.createdAt | dateFormat }}</div>
        <locumsnest-ellipsis [text]="row.fullName" />
        <locumsnest-ellipsis [text]="row.email" />
        <locumsnest-ellipsis
          class="word-break"
          tpVariation="popper"
          [tp]="row.description ? description : null"
          [text]="row.description"
        />
      </div>

      <ng-template #description>
        <div class="word-break tp-scroller">
          <div [innerHTML]="row?.description | safe: 'html'"></div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingOrEmpty let-loading>
  <div class="recent-activity-table">
    <div class="title recent-activity-table__loading">
      {{ loading ? 'Loading...' : 'No Recent Activity logs' }}
    </div>
  </div>
</ng-template>
