const emojis = {
  '❤️ Symbols': [
    { title: 'Heart with Arrow', character: '💘' },
    { title: 'Heart with Ribbon', character: '💝' },
    { title: 'Sparkling Heart', character: '💖' },
    { title: 'Growing Heart', character: '💗' },
    { title: 'Beating Heart', character: '💓' },
    { title: 'Revolving Hearts', character: '💞' },
    { title: 'Two Hearts', character: '💕' },
    { title: 'Heart Decoration', character: '💟' },
    { title: 'Heart Exclamation', character: '❣️' },
    { title: 'Broken Heart', character: '💔' },
    { title: 'Red Heart', character: '❤️' },
    { title: 'Orange Heart', character: '🧡' },
    { title: 'Yellow Heart', character: '💛' },
    { title: 'Green Heart', character: '💚' },
    { title: 'Blue Heart', character: '💙' },
    { title: 'Purple Heart', character: '💜' },
    { title: 'Brown Heart', character: '🤎' },
    { title: 'Black Heart', character: '🖤' },
    { title: 'White Heart', character: '🤍' },
    { title: 'Hundred Points', character: '💯' },
    { title: 'Anger Symbol', character: '💢' },
    { title: 'Speech Balloon', character: '💬' },
    { title: 'Eye in Speech Bubble', character: '👁️‍🗨️' },
    { title: 'Left Speech Bubble', character: '🗨️' },
    { title: 'Right Anger Bubble', character: '🗯️' },
    { title: 'Thought Balloon', character: '💭' },
    { title: 'Zzz', character: '💤' },
    { title: 'White Flower', character: '💮' },
    { title: 'Hot Springs', character: '♨️' },
    { title: 'Barber Pole', character: '💈' },
    { title: 'Stop Sign', character: '🛑' },
    { title: 'Twelve O’Clock', character: '🕛' },
    { title: 'Twelve-Thirty', character: '🕧' },
    { title: 'One O’Clock', character: '🕐' },
    { title: 'One-Thirty', character: '🕜' },
    { title: 'Two O’Clock', character: '🕑' },
    { title: 'Two-Thirty', character: '🕝' },
    { title: 'Three O’Clock', character: '🕒' },
    { title: 'Three-Thirty', character: '🕞' },
    { title: 'Four O’Clock', character: '🕓' },
    { title: 'Four-Thirty', character: '🕟' },
    { title: 'Five O’Clock', character: '🕔' },
    { title: 'Five-Thirty', character: '🕠' },
    { title: 'Six O’Clock', character: '🕕' },
    { title: 'Six-Thirty', character: '🕡' },
    { title: 'Seven O’Clock', character: '🕖' },
    { title: 'Seven-Thirty', character: '🕢' },
    { title: 'Eight O’Clock', character: '🕗' },
    { title: 'Eight-Thirty', character: '🕣' },
    { title: 'Nine O’Clock', character: '🕘' },
    { title: 'Nine-Thirty', character: '🕤' },
    { title: 'Ten O’Clock', character: '🕙' },
    { title: 'Ten-Thirty', character: '🕥' },
    { title: 'Eleven O’Clock', character: '🕚' },
    { title: 'Eleven-Thirty', character: '🕦' },
    { title: 'Cyclone', character: '🌀' },
    { title: 'Spade Suit', character: '♠️' },
    { title: 'Heart Suit', character: '♥️' },
    { title: 'Diamond Suit', character: '♦️' },
    { title: 'Club Suit', character: '♣️' },
    { title: 'Joker', character: '🃏' },
    { title: 'Mahjong Red Dragon', character: '🀄' },
    { title: 'Flower Playing Cards', character: '🎴' },
    { title: 'Muted Speaker', character: '🔇' },
    { title: 'Speaker Low Volume', character: '🔈' },
    { title: 'Speaker Medium Volume', character: '🔉' },
    { title: 'Speaker High Volume', character: '🔊' },
    { title: 'Loudspeaker', character: '📢' },
    { title: 'Megaphone', character: '📣' },
    { title: 'Postal Horn', character: '📯' },
    { title: 'Bell', character: '🔔' },
    { title: 'Bell with Slash', character: '🔕' },
    { title: 'Musical Note', character: '🎵' },
    { title: 'Musical Notes', character: '🎶' },
    { title: 'Chart Increasing with Yen', character: '💹' },
    { title: 'Elevator', character: '🛗' },
    { title: 'ATM Sign', character: '🏧' },
    { title: 'Litter in Bin Sign', character: '🚮' },
    { title: 'Potable Water', character: '🚰' },
    { title: 'Wheelchair Symbol', character: '♿' },
    { title: 'Men’s Room', character: '🚹' },
    { title: 'Women’s Room', character: '🚺' },
    { title: 'Restroom', character: '🚻' },
    { title: 'Baby Symbol', character: '🚼' },
    { title: 'Water Closet', character: '🚾' },
    { title: 'Warning', character: '⚠️' },
    { title: 'Children Crossing', character: '🚸' },
    { title: 'No Entry', character: '⛔' },
    { title: 'Prohibited', character: '🚫' },
    { title: 'No Bicycles', character: '🚳' },
    { title: 'No Smoking', character: '🚭' },
    { title: 'No Littering', character: '🚯' },
    { title: 'Non-Potable Water', character: '🚱' },
    { title: 'No Pedestrians', character: '🚷' },
    { title: 'No Mobile Phones', character: '📵' },
    { title: 'No One Under Eighteen', character: '🔞' },
    { title: 'Radioactive', character: '☢️' },
    { title: 'Biohazard', character: '☣️' },
    { title: 'Up Arrow', character: '⬆️' },
    { title: 'Up-Right Arrow', character: '↗️' },
    { title: 'Right Arrow', character: '➡️' },
    { title: 'Down-Right Arrow', character: '↘️' },
    { title: 'Down Arrow', character: '⬇️' },
    { title: 'Down-Left Arrow', character: '↙️' },
    { title: 'Left Arrow', character: '⬅️' },
    { title: 'Up-Left Arrow', character: '↖️' },
    { title: 'Up-Down Arrow', character: '↕️' },
    { title: 'Left-Right Arrow', character: '↔️' },
    { title: 'Right Arrow Curving Left', character: '↩️' },
    { title: 'Left Arrow Curving Right', character: '↪️' },
    { title: 'Right Arrow Curving Up', character: '⤴️' },
    { title: 'Right Arrow Curving Down', character: '⤵️' },
    { title: 'Clockwise Vertical Arrows', character: '🔃' },
    { title: 'Counterclockwise Arrows Button', character: '🔄' },
    { title: 'Back Arrow', character: '🔙' },
    { title: 'End Arrow', character: '🔚' },
    { title: 'On! Arrow', character: '🔛' },
    { title: 'Soon Arrow', character: '🔜' },
    { title: 'Top Arrow', character: '🔝' },
    { title: 'Place of Worship', character: '🛐' },
    { title: 'Atom Symbol', character: '⚛️' },
    { title: 'Om', character: '🕉️' },
    { title: 'Star of David', character: '✡️' },
    { title: 'Wheel of Dharma', character: '☸️' },
    { title: 'Yin Yang', character: '☯️' },
    { title: 'Latin Cross', character: '✝️' },
    { title: 'Orthodox Cross', character: '☦️' },
    { title: 'Star and Crescent', character: '☪️' },
    { title: 'Peace Symbol', character: '☮️' },
    { title: 'Menorah', character: '🕎' },
    { title: 'Dotted Six-Pointed Star', character: '🔯' },
    { title: 'Aries', character: '♈' },
    { title: 'Taurus', character: '♉' },
    { title: 'Gemini', character: '♊' },
    { title: 'Cancer', character: '♋' },
    { title: 'Leo', character: '♌' },
    { title: 'Virgo', character: '♍' },
    { title: 'Libra', character: '♎' },
    { title: 'Scorpio', character: '♏' },
    { title: 'Sagittarius', character: '♐' },
    { title: 'Capricorn', character: '♑' },
    { title: 'Aquarius', character: '♒' },
    { title: 'Pisces', character: '♓' },
    { title: 'Ophiuchus', character: '⛎' },
    { title: 'Shuffle Tracks Button', character: '🔀' },
    { title: 'Repeat Button', character: '🔁' },
    { title: 'Repeat Single Button', character: '🔂' },
    { title: 'Play Button', character: '▶️' },
    { title: 'Fast-Forward Button', character: '⏩' },
    { title: 'Next Track Button', character: '⏭️' },
    { title: 'Play or Pause Button', character: '⏯️' },
    { title: 'Reverse Button', character: '◀️' },
    { title: 'Fast Reverse Button', character: '⏪' },
    { title: 'Last Track Button', character: '⏮️' },
    { title: 'Upwards Button', character: '🔼' },
    { title: 'Fast Up Button', character: '⏫' },
    { title: 'Downwards Button', character: '🔽' },
    { title: 'Fast Down Button', character: '⏬' },
    { title: 'Pause Button', character: '⏸️' },
    { title: 'Stop Button', character: '⏹️' },
    { title: 'Record Button', character: '⏺️' },
    { title: 'Eject Button', character: '⏏️' },
    { title: 'Cinema', character: '🎦' },
    { title: 'Dim Button', character: '🔅' },
    { title: 'Bright Button', character: '🔆' },
    { title: 'Antenna Bars', character: '📶' },
    { title: 'Vibration Mode', character: '📳' },
    { title: 'Mobile Phone Off', character: '📴' },
    { title: 'Female Sign', character: '♀️' },
    { title: 'Male Sign', character: '♂️' },
    { title: 'Multiply', character: '✖️' },
    { title: 'Plus', character: '➕' },
    { title: 'Minus', character: '➖' },
    { title: 'Divide', character: '➗' },
    { title: 'Infinity', character: '♾️' },
    { title: 'Double Exclamation Mark', character: '‼️' },
    { title: 'Exclamation Question Mark', character: '⁉️' },
    { title: 'Question Mark', character: '❓' },
    { title: 'White Question Mark', character: '❔' },
    { title: 'White Exclamation Mark', character: '❕' },
    { title: 'Exclamation Mark', character: '❗' },
    { title: 'Wavy Dash', character: '〰️' },
    { title: 'Currency Exchange', character: '💱' },
    { title: 'Heavy Dollar Sign', character: '💲' },
    { title: 'Medical Symbol', character: '⚕️' },
    { title: 'Recycling Symbol', character: '♻️' },
    { title: 'Fleur-de-lis', character: '⚜️' },
    { title: 'Trident Emblem', character: '🔱' },
    { title: 'Name Badge', character: '📛' },
    { title: 'Japanese Symbol for Beginner', character: '🔰' },
    { title: 'Hollow Red Circle', character: '⭕' },
    { title: 'Check Mark Button', character: '✅' },
    { title: 'Check Box with Check', character: '☑️' },
    { title: 'Check Mark', character: '✔️' },
    { title: 'Cross Mark', character: '❌' },
    { title: 'Cross Mark Button', character: '❎' },
    { title: 'Curly Loop', character: '➰' },
    { title: 'Double Curly Loop', character: '➿' },
    { title: 'Part Alternation Mark', character: '〽️' },
    { title: 'Eight-Spoked Asterisk', character: '✳️' },
    { title: 'Eight-Pointed Star', character: '✴️' },
    { title: 'Sparkle', character: '❇️' },
    { title: 'Copyright', character: '©️' },
    { title: 'Registered', character: '®️' },
    { title: 'Trade Mark', character: '™️' },
    { title: 'Keycap Number Sign', character: '#️⃣' },
    { title: 'Keycap Asterisk', character: '*️⃣' },
    { title: 'Keycap Digit Zero', character: '0️⃣' },
    { title: 'Keycap Digit One', character: '1️⃣' },
    { title: 'Keycap Digit Two', character: '2️⃣' },
    { title: 'Keycap Digit Three', character: '3️⃣' },
    { title: 'Keycap Digit Four', character: '4️⃣' },
    { title: 'Keycap Digit Five', character: '5️⃣' },
    { title: 'Keycap Digit Six', character: '6️⃣' },
    { title: 'Keycap Digit Seven', character: '7️⃣' },
    { title: 'Keycap Digit Eight', character: '8️⃣' },
    { title: 'Keycap Digit Nine', character: '9️⃣' },
    { title: 'Keycap: 10', character: '🔟' },
    { title: 'Input Latin Uppercase', character: '🔠' },
    { title: 'Input Latin Lowercase', character: '🔡' },
    { title: 'Input Numbers', character: '🔢' },
    { title: 'Input Symbols', character: '🔣' },
    { title: 'Input Latin Letters', character: '🔤' },
    { title: 'A Button (Blood Type)', character: '🅰️' },
    { title: 'AB Button (Blood Type)', character: '🆎' },
    { title: 'B Button (Blood Type)', character: '🅱️' },
    { title: 'CL Button', character: '🆑' },
    { title: 'Cool Button', character: '🆒' },
    { title: 'Free Button', character: '🆓' },
    { title: 'Information', character: 'ℹ️' },
    { title: 'ID Button', character: '🆔' },
    { title: 'Circled M', character: 'Ⓜ️' },
    { title: 'New Button', character: '🆕' },
    { title: 'NG Button', character: '🆖' },
    { title: 'O Button (Blood Type)', character: '🅾️' },
    { title: 'OK Button', character: '🆗' },
    { title: 'P Button', character: '🅿️' },
    { title: 'SOS Button', character: '🆘' },
    { title: 'Up! Button', character: '🆙' },
    { title: 'Vs Button', character: '🆚' },
    { title: 'Japanese “Here” Button', character: '🈁' },
    { title: 'Japanese “Service Charge” Button', character: '🈂️' },
    { title: 'Japanese “Monthly Amount” Button', character: '🈷️' },
    { title: 'Japanese “Not Free of Charge” Button', character: '🈶' },
    { title: 'Japanese “Reserved” Button', character: '🈯' },
    { title: 'Japanese “Bargain” Button', character: '🉐' },
    { title: 'Japanese “Discount” Button', character: '🈹' },
    { title: 'Japanese “Free of Charge” Button', character: '🈚' },
    { title: 'Japanese “Prohibited” Button', character: '🈲' },
    { title: 'Japanese “Acceptable” Button', character: '🉑' },
    { title: 'Japanese “Application” Button', character: '🈸' },
    { title: 'Japanese “Passing Grade” Button', character: '🈴' },
    { title: 'Japanese “Vacancy” Button', character: '🈳' },
    { title: 'Japanese “Congratulations” Button', character: '㊗️' },
    { title: 'Japanese “Secret” Button', character: '㊙️' },
    { title: 'Japanese “Open for Business” Button', character: '🈺' },
    { title: 'Japanese “No Vacancy” Button', character: '🈵' },
    { title: 'Red Circle', character: '🔴' },
    { title: 'Orange Circle', character: '🟠' },
    { title: 'Yellow Circle', character: '🟡' },
    { title: 'Green Circle', character: '🟢' },
    { title: 'Blue Circle', character: '🔵' },
    { title: 'Purple Circle', character: '🟣' },
    { title: 'Brown Circle', character: '🟤' },
    { title: 'Black Circle', character: '⚫' },
    { title: 'White Circle', character: '⚪' },
    { title: 'Red Square', character: '🟥' },
    { title: 'Orange Square', character: '🟧' },
    { title: 'Yellow Square', character: '🟨' },
    { title: 'Green Square', character: '🟩' },
    { title: 'Blue Square', character: '🟦' },
    { title: 'Purple Square', character: '🟪' },
    { title: 'Brown Square', character: '🟫' },
    { title: 'Black Large Square', character: '⬛' },
    { title: 'White Large Square', character: '⬜' },
    { title: 'Black Medium Square', character: '◼️' },
    { title: 'White Medium Square', character: '◻️' },
    { title: 'Black Medium-Small Square', character: '◾' },
    { title: 'White Medium-Small Square', character: '◽' },
    { title: 'Black Small Square', character: '▪️' },
    { title: 'White Small Square', character: '▫️' },
    { title: 'Large Orange Diamond', character: '🔶' },
    { title: 'Large Blue Diamond', character: '🔷' },
    { title: 'Small Orange Diamond', character: '🔸' },
    { title: 'Small Blue Diamond', character: '🔹' },
    { title: 'Red Triangle Pointed Up', character: '🔺' },
    { title: 'Red Triangle Pointed Down', character: '🔻' },
    { title: 'Diamond with a Dot', character: '💠' },
    { title: 'Radio Button', character: '🔘' },
    { title: 'White Square Button', character: '🔳' },
    { title: 'Black Square Button', character: '🔲' },
  ],
};
export default emojis;
