import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFileImage',
  standalone: true,
})
export class IsFileImagePipe implements PipeTransform {
  transform(file: File): boolean {
    return file && file.type.split('/')[0] === 'image';
  }
}
