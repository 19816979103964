import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AtomsModule } from '../../atoms';
import { IArrowDirectionsEnum } from './../../../../../core/src/lib/interfaces/enumeration';

@Component({
  selector: 'locumsnest-pagination-bar',
  standalone: true,
  imports: [AtomsModule, FormsModule],
  templateUrl: './pagination-bar.component.html',
  styleUrls: ['./pagination-bar.component.scss'],
})
export class PaginationBarComponent implements OnInit {
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Input() loadedRowsCount: number;
  @Input() currentPageRowsCount: number;
  @Input() totalRowsCount: number;
  @Input() pageSize: number;
  @Input() showPaginationText = true;
  @Input() isEditable = true;

  @Output()
  arrowButtonClicked: EventEmitter<IArrowDirectionsEnum> = new EventEmitter<IArrowDirectionsEnum>();
  @Output() selectedPageChanged: EventEmitter<number> = new EventEmitter<number>();
  directionEnum;

  constructor() {}

  ngOnInit() {
    this.directionEnum = IArrowDirectionsEnum;
  }

  public onArrowButtonClicked(direction: IArrowDirectionsEnum) {
    if (direction === IArrowDirectionsEnum.LEFT && this.currentPage === 1) {
      return;
    }
    if (direction === IArrowDirectionsEnum.RIGHT && this.currentPage === this.totalPages) {
      return;
    }

    this.arrowButtonClicked.emit(direction);
  }

  public onSelectedPageChanged(event: Event) {
    this.selectedPageChanged.emit(+(event.target as HTMLInputElement).value);
  }

  public onBlur(event: Event) {
    (event.target as HTMLInputElement).value = this.currentPage.toString();
  }

  public getPaginationText(): string {
    let paginationText = '';
    let showingFrom = 0;
    let showingTo = 0;
    let outOf = 0;

    if (this.currentPage) {
      if (this.currentPage === 1) {
        showingFrom = 1;
      } else if (this.currentPageRowsCount > 0) {
        showingFrom = this.loadedRowsCount + 1;
      }
    }

    showingTo =
      this.currentPage === 1 && this.pageSize > 0
        ? this.pageSize
        : (this.loadedRowsCount ? this.loadedRowsCount : 0) +
          (this.currentPageRowsCount ? this.currentPageRowsCount : 0);

    if (this.totalRowsCount) {
      outOf = this.totalRowsCount;

      if (showingTo > this.totalRowsCount) {
        showingTo = this.totalRowsCount;
      }
    }

    if (
      showingTo < this.currentPageRowsCount * this.currentPage &&
      this.currentPage > 1 &&
      this.currentPage !== this.totalPages
    ) {
      showingTo = this.currentPageRowsCount * this.currentPage;
      showingFrom = this.currentPageRowsCount * (this.currentPage - 1) + 1;
    }

    if (this.currentPage > 1 && this.currentPage === this.totalPages && this.pageSize > 0) {
      showingTo = this.totalRowsCount;
      showingFrom = this.pageSize * (this.currentPage - 1) + 1;
    }

    paginationText = 'Showing ' + showingFrom + ' - ' + showingTo + ' out of ' + outOf;

    return paginationText;
  }
}
