<div *ngIf="show" class="container">
  <div class="container--header">
    <div class="title">{{ title }}</div>
    <div *ngIf="showCloseButton" class="close-button" (click)="closeModal()"></div>
  </div>
  <div class="container--body">
    <ng-content select="[body]"></ng-content>
  </div>
  <span *ngIf="showLine">
    <hr />
  </span>
  <div class="container--footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
