<ckeditor
  [editor]="Editor"
  [config]="{
    plugins: plugins,
    toolbar: [
      'heading',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'specialCharacters'
    ],
    heading: {
      options: [
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading',
          class: 'ck-heading_heading'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Subheading'
        },
        {
          model: 'paragraph',
          view: 'p',
          title: 'Normal',
          converterPriority: 'high'
        },
        {
          model: 'heading4',
          view: {
            name: 'p',
            classes: 'ck-heading_footnote'
          },
          title: 'Footnote'
        }
      ]
    }
  }"
  [disabled]="isDisabled"
  (change)="onChange($event)"
  [(ngModel)]="inputText"
>
</ckeditor>
<div class="error-msg">
  <small *ngIf="errorMsg" class="text-danger">{{ errorMsg }}</small>
</div>
