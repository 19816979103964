<!-- class="orange-theme" -->
<ng-select
  bindLabel="name"
  bindValue="id"
  [id]="idx"
  [items]="options"
  [placeholder]="options.length > 0 ? placeholder : 'Loading data...'"
  [disabled]="isDisabled"
  [clearable]="clearable"
  [searchable]="true"
  [ngClass]="{ 'select-option-show-arrow': displaySelectArrow }"
  [multiple]="multiple"
  [closeOnSelect]="!multiple"
  [hideSelected]="hideSelected"
  (blur)="onBlur($event)"
  (change)="onOptionSelect()"
  (click)="onClick($event)"
  (clear)="onClearAll($event)"
  (remove)="onRemoveOne($event)"
  (add)="onAddOne($event)"
  [(ngModel)]="value"
>
  <ng-template ng-option-tmp let-item="item">
    <div
      [tp]="item.description"
      [tpDelay]="800"
      [id]="idx + '__' + item.name | kebabcase | lowercase"
    >
      {{ item.name | textTruncate : 35 }}
    </div>
  </ng-template>

  <ng-container *ngIf="multiple">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div
        *ngFor="let item of items | slice : 0 : maxDisplayItems; trackBy: trackBy"
        class="ng-value"
      >
        <ng-container
          *ngIf="clearButtonPosition === 'left'"
          [ngTemplateOutlet]="clearButton"
          [ngTemplateOutletContext]="{
            $implicit: item,
            clear: clear
          }"
        ></ng-container>
        <span
          class="ng-value-label"
          [tp]="item.description"
          [tpDelay]="800"
          [id]="idx + '__' + item.name | kebabcase | lowercase"
        >
          {{ item.name | textTruncate : 35 }}
        </span>
        <ng-container
          *ngIf="clearButtonPosition === 'right'"
          [ngTemplateOutlet]="clearButton"
          [ngTemplateOutletContext]="{
            $implicit: item,
            clear: clear
          }"
        ></ng-container>
      </div>
      <div *ngIf="items.length > maxDisplayItems" class="ng-value">
        <span class="ng-value-label" [id]="idx + '__more'"
          >{{ items.length - maxDisplayItems }} more...</span
        >
      </div>
    </ng-template>
  </ng-container>
</ng-select>

<ng-template #clearButton let-item let-clear="clear">
  <span
    class="ng-value-icon {{ clearButtonPosition }}"
    aria-hidden="true"
    [id]="idx + '__' + item.name + '__clear' | kebabcase | lowercase"
    (click)="clear(item)"
    >×
  </span>
</ng-template>
