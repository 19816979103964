import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';

@Component({
  selector: 'locumsnest-ellipsis',
  templateUrl: './ellipsis.component.html',
  styleUrls: ['./ellipsis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NestedEllipsisModule, NgStyle],
})
export class EllipsisComponent {
  @Input() text: string;
  @Input() height = 18;
}
