import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type IconType = 'approve' | 'decline' | 'pending';

@Component({
  selector: 'locumsnest-action-icon-button',
  templateUrl: './action-icon-button.component.html',
  styleUrls: ['./action-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionIconButtonComponent {
  @Input() idx: string;
  @Input() icon: IconType;
  @Input() displayText: string;
  @Output() actionIconClicked = new EventEmitter<void>();

  public showDisplayText$ = new BehaviorSubject(false);

  onActionIconClicked() {
    if (this.icon === 'pending') {
      this.showDisplayText$.next(true);
      setTimeout(() => this.showDisplayText$.next(false), 3000);
      this.actionIconClicked.emit();
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.icon.previousValue === 'pending' && change.icon.currentValue === 'approve') {
      this.showDisplayText$.next(true);
      setTimeout(() => this.showDisplayText$.next(false), 3000);
    }
  }
}
