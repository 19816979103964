<div
  class="toggle"
  [ngClass]="{
    activated: value,
    isAlert: isAlert,
    disabled: isDisabled,
    pressed: isPressed,
    darkTheme: darkTheme
  }"
  (click)="onClicked()"
  (mousedown)="onMouseDown()"
  (mouseup)="onMouseUp()"
  (mouseleave)="onMouseUp()"
>
  <div class="bg">
    <input
      type="checkbox"
      [checked]="!isDisabled"
      [name]="fieldName || 'myDefault'"
      [(ngModel)]="value"
    />
  </div>
  <div class="handle"></div>
</div>
