import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  ISignalConstructor,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src/lib/ngrx';
import { serviceSelectors } from '@locumsnest/core/src/lib/ngrx/decorators/service';

import { ITabsState, TabId } from './interfaces';

const TAB_ACTION_PREFIX = 'TABS-ACTION ';
export type TabActionPrefixType = typeof TAB_ACTION_PREFIX;
export const TAB_SIGNAL_PREFIX_REGEX = /\[Signal\] TABS-ACTION/;
export interface ITabSignals<F extends string> {
  InitializeTabsSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Initialize Tabs`,
    { page: string; filterNamespace: string; alertsNamespace: string }
  >;
  AddActiveTabSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Add Active Tab`,
    { page: string }
  >;
  UpdateTabNameSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Update Tab Name`,
    { id: TabId; name: string; page: string }
  >;
  SaveActiveTabFiltersSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Save Active Tab Filters`,
    { page: string }
  >;
  UpdateActiveTabSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Update Active Tab`,
    { id: TabId; page: string; filterNamespace: string; alertsNamespace: string }
  >;
  RemoveTabSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Remove Tab`,
    { id: TabId; page: string; filterNamespace: string; alertsNamespace: string }
  >;
  RevertToDefaultsHomeFiltersSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Revert To Defaults Home Filters`,
    { page: string; filterNamespace: string; alertsNamespace: string }
  >;
  AddHomeTabSignal: ISignalConstructor<
    F,
    `${TabActionPrefixType}Add Home Tab`,
    { page: string; filterNamespace: string; alertsNamespace: string }
  >;
}

export function createTabsAdapter(
  featureKey: string,
  messageableFactory: MessageableFactory<string>,
  signalableFactory: SignalableFactory<string>,
) {
  const selectJobListingListTabsState = createFeatureSelector<ITabsState>(featureKey);

  class UpdateActiveTabMessage extends messageableFactory.create<
    `${TabActionPrefixType}Update Active Tab`,
    { id: TabId; namespace?: string }
  >(`${TAB_ACTION_PREFIX}Update Active Tab`) {}

  class UpdateEditTabMessage extends messageableFactory.create<
    `${TabActionPrefixType}Update Edit Tab`,
    { id: TabId; namespace?: string }
  >(`${TAB_ACTION_PREFIX}Update Edit Tab`) {}

  class InitializeTabsSignal extends signalableFactory.create<
    `${TabActionPrefixType}Initialize Tabs`,
    { page: string; filterNamespace: string; alertsNamespace: string }
  >(`${TAB_ACTION_PREFIX}Initialize Tabs`) {}

  class UpdateEditTabSignal extends signalableFactory.create<
    `${TabActionPrefixType}Update Edit Tab`,
    { id: TabId; namespace?: string }
  >(`${TAB_ACTION_PREFIX}Update Edit Tab`) {}

  class AddActiveTabSignal extends signalableFactory.create<
    `${TabActionPrefixType}Add Active Tab`,
    { page: string }
  >(`${TAB_ACTION_PREFIX}Add Active Tab`) {}

  class UpdateTabNameSignal extends signalableFactory.create<
    `${TabActionPrefixType}Update Tab Name`,
    { id: TabId; name: string; page: string }
  >(`${TAB_ACTION_PREFIX}Update Tab Name`) {}

  class SaveActiveTabFiltersSignal extends signalableFactory.create<
    `${TabActionPrefixType}Save Active Tab Filters`,
    { page: string }
  >(`${TAB_ACTION_PREFIX}Save Active Tab Filters`) {}

  class UpdateActiveTabSignal extends signalableFactory.create<
    `${TabActionPrefixType}Update Active Tab`,
    { id: TabId; page: string; filterNamespace: string; alertsNamespace: string }
  >(`${TAB_ACTION_PREFIX}Update Active Tab`) {}

  class RemoveTabSignal extends signalableFactory.create<
    `${TabActionPrefixType}Remove Tab`,
    { id: TabId; page: string; filterNamespace: string; alertsNamespace: string }
  >(`${TAB_ACTION_PREFIX}Remove Tab`) {}

  class RevertToDefaultsHomeFiltersSignal extends signalableFactory.create<
    `${TabActionPrefixType}Revert To Defaults Home Filters`,
    { page: string; filterNamespace: string; alertsNamespace: string }
  >(`${TAB_ACTION_PREFIX}Revert To Defaults Home Filters`) {}

  class AddHomeTabSignal extends signalableFactory.create<
    `${TabActionPrefixType}Add Home Tab`,
    { page: string; filterNamespace: string; alertsNamespace: string }
  >(`${TAB_ACTION_PREFIX}Add Home Tab`) {}
  const INITIAL_STATE: ITabsState = { activeTabId: null, editTabId: null };

  function createReducer(initialState = INITIAL_STATE) {
    const reducer = (
      state = initialState,
      action: UpdateActiveTabMessage | UpdateEditTabMessage,
    ) => {
      switch (action.type) {
        case UpdateActiveTabMessage.TYPE: {
          const { id } = (action as UpdateActiveTabMessage).payload;
          state = {
            ...state,
            activeTabId: id,
          };
          break;
        }
        case UpdateEditTabMessage.TYPE: {
          const { id } = (action as UpdateEditTabMessage).payload;
          state = {
            ...state,
            editTabId: id,
          };
          break;
        }

        default:
          break;
      }

      return state;
    };

    return reducer;
  }

  function getSelectors() {
    const selectActiveTabId = createSelector(
      selectJobListingListTabsState,
      (state) => state.activeTabId,
    );
    const selectEditTabId = createSelector(
      selectJobListingListTabsState,
      (state) => state.editTabId,
    );

    return {
      selectActiveTabId,
      selectEditTabId,
    };
  }

  function getSignals() {
    return {
      InitializeTabsSignal,
      AddHomeTabSignal,
      AddActiveTabSignal,
      UpdateTabNameSignal,
      SaveActiveTabFiltersSignal,
      UpdateActiveTabSignal,
      UpdateEditTabSignal,
      RemoveTabSignal,
      RevertToDefaultsHomeFiltersSignal,
    };
  }

  const tabsService = () =>
    function (cls) {
      const selectors = getSelectors();
      cls = serviceSelectors(selectors)(cls);
      cls.prototype.updateActiveTab = function (id: TabId) {
        this.store.dispatch(new UpdateActiveTabMessage({ id }));
      };
      cls.prototype.updateEditTab = function (id: TabId) {
        this.store.dispatch(new UpdateEditTabMessage({ id }));
      };

      return cls;
    };
  return {
    createReducer,
    getSelectors,
    tabsService,
    getSignals,
  };
}
