import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[locumsnestScrollTo]',
})
export class ScrollToDirective implements AfterViewInit {
  @Input('locumsnestScrollTo') onFirst: boolean | '';
  @Input() toTheTop: boolean | '';

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.toTheTop) {
      this.elRef.nativeElement.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    } else if (this.onFirst || this.onFirst === '') {
      this.elRef.nativeElement.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }
}
