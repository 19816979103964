import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  @Input() @HostBinding('class.small') small = false;
  @Input() photoUrl = '';
  @Input() name: string;
  @Input() showStatus = false;
  @Input() statusFont = '';
  @Input() showVaccinationStatus = false;
  @Input() showAssignmentNumberWarning = false;
  @Input() vaccinationStatus: boolean | null;
  @Input() isHyperlink = false;
  @Input() profileId: string;
  @Input() disabled = false;
}
