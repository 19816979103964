import { Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-user-status-bar',
  templateUrl: './user-status-bar.component.html',
  styleUrls: ['./user-status-bar.component.scss'],
})
export class UserStatusBarComponent {
  @Input() details: string;
  @Input() photoUrl = '';
  @Input() name: string;
  @Input() vaccinationStatus: boolean | null;
  @Input() showVaccinationStatus: boolean;
  @Input() isHyperlink = false;
  @Input() profileId: string;
}
