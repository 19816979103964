<div class="tabs-nav">
  <div
    *ngFor="let tab of tabs; index as i; trackBy: trackById; let first = first"
    class="tab"
    [ngClass]="{ active: tab.isActive }"
    (click)="onSelectedTab($event, tab)"
    (dblclick)="onEditTab(tab.id, first)"
  >
    <ng-container *locumsnestTabHeader="tab.name; let name">{{ name }}</ng-container>
  </div>
  <div *ngIf="canAddTabs" class="tab add-tab" (click)="onAddTab()">+</div>
</div>

<div class="tabs-body">
  <ng-container *ngTemplateOutlet="currentTab"></ng-container>
</div>
