import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChildren, forwardRef, Input, QueryList } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import {
  ISelectEvent,
  RadioButtonComponent,
} from '../../atoms/radio-button/radio-button.component';
import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';
import { ButtonGroupRadioButtonComponent } from './button-group-radio-button.component';

@Component({
  selector: 'locumsnest-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss'],
  standalone: true,
  imports: [NgClass, RadioButtonComponent, NgTemplateOutlet],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true,
    },
  ],
})
export class RadioButtonGroupComponent extends InputWrapperWithChangeDetection {
  @Input() options: ISelectOption[];
  @Input() isVertical: boolean;
  @Input() groupName: string;
  @Input() smaller: boolean;
  @Input() isDisabled: boolean;
  @Input() hideDot = false;
  @Input() darkTheme: boolean;

  @ContentChildren(ButtonGroupRadioButtonComponent, { descendants: true })
  radioButtons: QueryList<ButtonGroupRadioButtonComponent>;

  onSelectionChange(selection: ISelectEvent) {
    this.writeValue(selection.value);
  }

  isSelected(option: ISelectOption) {
    return option.id === this.value;
  }
}
