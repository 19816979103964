import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputWrapper } from '@locumsnest/components/src/lib/core/input-wrapper';

@Component({
  selector: 'locumsnest-expandable-input-section',
  templateUrl: './expandable-input-section.component.html',
  styleUrls: ['./expandable-input-section.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpandableInputSectionComponent),
      multi: true,
    },
  ],
})
export class ExpandableInputSectionComponent
  extends InputWrapper<boolean>
  implements ControlValueAccessor
{
  @Input() fieldName = 'expandable-input';
  @Input() label: string;
  @Input() isReadOnly = false;
  @Input() isAlert = false;
  @Input() propertyId: string;

  @Output() expandToggleAction = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  // this input is used for the cases that we do not want to link toggle's value to a form control
  @Input()
  set toggleValue(val: boolean) {
    this.writeValue(val);
  }

  private _isExpanded = false;

  constructor() {
    super();
    if (this.isReadOnly) {
      this.isExpanded = false;
    }
  }

  get isExpanded(): boolean {
    return this._isExpanded;
  }

  @Input()
  set isExpanded(val: boolean) {
    if (!this.isReadOnly && this._isExpanded !== val) {
      this._isExpanded = val;
    }
  }

  onArrowClick() {
    this.onExpandToggleAction(!this.isExpanded);
  }

  onValueChange($event) {
    if ($event !== false && $event !== true) {
      return;
    }

    if ($event !== this.value) {
      this.valueChange.emit($event);
    }
  }

  onExpandToggleAction(val: boolean) {
    this.expandToggleAction.emit({ value: val, propertyId: this.propertyId });
  }
}
