import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMessage } from '../../interfaces/message';

@Component({
  selector: 'locumsnest-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
})
export class MessageListComponent {
  @Input() title: string;
  @Input() messages: IMessage[];
  @Output() loadMore = new EventEmitter();

  constructor() {}

  onScroll() {
    this.loadMore.emit();
  }
}
