import { Actions } from '@ngrx/effects';
import { get, trim } from 'lodash-es';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';

import { RouterService } from '../router/router.service';

export abstract class BaseEffects {
  protected abstract actions$: Actions;

  getDestroyAction(currentActionType: string = '', path: string = '', isEditMode: boolean = false) {
    const destroyFilter = filter((action: { type: string; payload: any }) => {
      const url = trim(get(action, 'payload.routerState.url', ''), '/');
      path = trim(path, '/');
      const isSameUrl =
        (url.startsWith(path) && isEditMode && path !== url) || (url === path && !isEditMode);

      return (
        ((path === '' || !isSameUrl) && action.type === '@ngrx/router-store/navigation') ||
        action.type === currentActionType
      );
    });

    return this.actions$.pipe(destroyFilter);
  }

  getRegexPathDestroyAction(currentActionType: string = '', path: RegExp) {
    const destroyFilter = filter((action: { type: string; payload: any }) => {
      const url = trim(get(action, 'payload.routerState.url', ''), '/');
      const isAcceptedUrl = path.test(url);
      return (
        (!isAcceptedUrl && action.type === '@ngrx/router-store/navigation') ||
        action.type === currentActionType
      );
    });
    return this.actions$.pipe(destroyFilter);
  }
}

export abstract class AutoDestroyedEffects {
  protected abstract actions$: Actions;
  protected abstract routerService: RouterService;

  onDestroy(currentActionType: string, allowSubPaths = false) {
    return merge(
      this.routerService
        .getCurrentPath()
        .pipe(
          distinctUntilChanged(
            (x, y) => x === y || (allowSubPaths && (y.startsWith(x) || x.startsWith(y)))
          )
        )
        .pipe(skip(1)),
      this.actions$.pipe(
        filter((action) => action.type === currentActionType),
        skip(1)
      )
    );
  }
}
