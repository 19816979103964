import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'locumsnest-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss'],
})
export class NotificationAlertComponent {
  @Input() totalAlerts: number;
  @Input() unreadAlerts: number;
  @Input() lastAlertDate: Date;
  @Input() type: string;

  @Output() click = new EventEmitter();

  notificationClicked() {
    this.click.emit();
  }
}
