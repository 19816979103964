import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type Theme = `${'green' | 'blue' | 'pink' | 'purple' | 'red' | 'orange'}-circle` | 'blue-profile';

@Component({
  selector: 'locumsnest-count-icon',
  templateUrl: './count-icon.component.html',
  styleUrls: ['./count-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountIconComponent {
  @Input() count = 0;
  @Input() theme: Theme = 'green-circle';
  @Input() smaller = false;
}
