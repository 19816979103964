import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';

import { ISelectOption } from '../../interfaces';
import { DropdownSelectComponentBase } from './base/dropdown-select-base.component';

@Component({
  selector: 'locumsnest-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
  styleUrls: ['./dropdown-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true,
    },
  ],
})
export class DropdownSelectComponent<T extends string | boolean | number>
  extends DropdownSelectComponentBase<T>
  implements OnDestroy, ControlValueAccessor
{
  @Input() idx: string;
  @Input() displaySelectArrow = false;
  @Input() @HostBinding('class.drop-down-input--disabled') isDisabled = false;
  @Input() extraClass: string;
  @Input() placeholder: string;
  @Input() clearOnDestroy = false;
  @Input() hideSelected = false;
  @Input() clearButtonPosition: 'left' | 'right' = 'left';
  @Input() @HostBinding('class.dashboard-theme') dashboardTheme = false;
  @Input() @HostBinding('class.transparent-theme') transparentTheme = false;
  @Input() @HostBinding('class.header-theme') headerTheme = false;
  @Input() @HostBinding('class.error-theme') errorTheme = false;
  @Input() @HostBinding('class.border-theme') hasBorder = false;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() maxDisplayItems = 999;
  @Input() @HostBinding('class.input--required') isRequired: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<T>();
  @Output() clearAll = new EventEmitter<void>();
  @Output() removeOne = new EventEmitter<number | string>();
  @Output() addOne = new EventEmitter<number | string>();

  onOptionSelect() {
    this.select.emit(this.value);
  }

  onClearAll(_: NgOption) {
    this.clearAll.emit();
  }

  onAddOne(option: ISelectOption) {
    this.addOne.emit(option.id);
  }

  onRemoveOne(option: NgOption) {
    this.removeOne.emit(option.value['id'] || option.value);
  }

  onClick(event: Event) {
    event.stopPropagation();
  }

  trackBy(_: number, item) {
    return item.id;
  }

  ngOnDestroy() {
    if (this.clearOnDestroy) {
      this.value = null;
    }
  }
}
