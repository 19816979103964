<div
  class="ln-card"
  [ngClass]="{ 'with-image': configurationDetails.banner }"
  (click)="onConfigurationCardClicked()"
>
  <div class="ln-card__header">
    @if (configurationDetails.proVersion) {
      <div class="ln-card__premium"></div>
    }
    <div>
      <ln-status [statusType]="configurationDetails.statusType" [outline]="outline">
        {{ configurationDetails.status }}
      </ln-status>
    </div>
  </div>
  <img class="ln-card__icon" [src]="configurationDetails.icon" />
  <div class="ln-card__title">{{ configurationDetails.title }}</div>
  <div class="ln-card__description">
    <div class="ellipsis">
      {{ configurationDetails.description }}
    </div>
  </div>
  @if (configurationDetails.banner) {
    <div class="banner">
      <img class="banner__image" [src]="configurationDetails.banner" />
    </div>
  }
  <div class="ln-card__last-update">Last updated: {{ configurationDetails.lastUpdated }}</div>
</div>
