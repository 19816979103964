import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';
import { box, Boxed, unbox, Unboxed } from 'ngrx-forms';

import {
  defferSerialization,
  HttpApiPersistenceService,
  ISerializedApiService,
} from '@locumsnest/core/src';
import {
  getEntityFields,
  IField,
  Omittable,
  Serializer,
} from '@locumsnest/core/src/lib/serializers';

import { IFilterViews } from './interfaces';

const ENDPOINT = defferSerialization({ endpoint: 'filterViews' });

const boxedNumberField: IField<Boxed<number[]>, Unboxed<number[]>> = {
  deserialize: (v: string | number[]) => {
    if (typeof v === 'string') return box(v.split(',').map((val) => +val));
    return box(v || null);
  },
  serialize: (v: Boxed<number[]>) => unbox(v) || null,
};

const filtersSerializer = new Serializer(
  getEntityFields(
    {
      tier: new Omittable(boxedNumberField),
      labels: new Omittable(boxedNumberField),
      tags: new Omittable(boxedNumberField),
    },
    {},
  ),
);

@Injectable({
  providedIn: 'root',
})
export class FilterViewsPersistenceService
  extends HttpApiPersistenceService<LocumsNestEndpointConfig, IFilterViews>
  implements ISerializedApiService<'filterViews', IFilterViews>
{
  override readonly endpoint = ENDPOINT;
  override readonly serializer = new Serializer(
    getEntityFields<IFilterViews>({
      filters: filtersSerializer,
    }),
  );
}
