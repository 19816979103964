import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'locumsnest-button-group-radio-button',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  standalone: true,
})
export class ButtonGroupRadioButtonComponent {
  @Input() id: number;
  @Input() name: string;
  @Input() disabled: boolean;

  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;
}
