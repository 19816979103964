import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'locumsnest-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent {
  @Output() rangeSelected = new EventEmitter();
  startDate: Date;
  endDate: Date;

  isStartDateSelected: boolean;
  isEndDateSelected: boolean;

  constructor() {}

  markAsSelectedStart($event) {
    this.startDate = $event;
    this.isStartDateSelected = true;
    this.datesSelected();
  }

  markAsSelectedEnd($event) {
    this.endDate = $event;
    this.isEndDateSelected = true;
    this.datesSelected();
  }

  datesSelected() {
    if (this.isStartDateSelected && this.isEndDateSelected) {
      this.rangeSelected.emit({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    }
  }
}
