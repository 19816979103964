import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroupState } from 'ngrx-forms';

import { IContactFormState } from '@locumsnest/core/src/lib/adapters/contact-form-adapter';

import { LocumsNestComponent } from '../../core/locums-nest-component';
import { ISelectOption } from '../../interfaces';

@Component({
  selector: 'locumsnest-contact-form-widget',
  templateUrl: './contact-form-widget.component.html',
  styleUrls: ['./contact-form-widget.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ContactFormWidgetComponent extends LocumsNestComponent {
  @Output() subjectSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendMessage: EventEmitter<any> = new EventEmitter<any>();

  @Input() title: string;
  @Input() subjectPlaceholder: string;
  @Input() subjectOptions: ISelectOption[];
  @Input() messageNote: string;
  @Input() helpText: string;
  @Input() buttonLabel: string;
  @Input() contactFormState: FormGroupState<IContactFormState>;

  constructor(protected router: Router) {
    super();
  }

  public onSubjectSelect(event) {
    this.subjectSelect.emit(event);
  }

  public onSendMessage() {
    this.sendMessage.emit();
  }
}
