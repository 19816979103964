import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

type Styles = 'Normal' | 'Black' | 'Blue' | 'Add' | 'Green';

@Component({
  selector: 'locumsnest-action-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {
  @Input() idx: string;
  @Input() buttonType: string;
  @Input() style: Styles = 'Normal';
  @Input() active = false;
  @Input() disabled = false;

  @HostBinding('class')
  get hostClass(): string {
    return this.disabled ? 'disabled' : 'enabled';
  }
}
