import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly delimiter = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = Time.getMoment(value, DATE_FORMAT);

      return {
        day: date.date(),
        month: date.month() + 1,
        year: date.year(),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (date) {
      const { day, month, year } = date;
      // ngb months 1-12 , javascript 0-11
      return Time.getMoment({ day, month: month - 1, year }).format(DATE_FORMAT);
    }
    return null;
  }
}
