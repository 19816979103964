<a class="link" [id]="idx" [routerLink]="['/job-listings/new/date/' + getTheDate()]">
  <div class="shift_add">
    <div class="shift_add_icon_container">
      <div class="shift_add_icon"></div>
    </div>
    <div class="shift_add_text">
      {{ displayText }}
    </div>
  </div>
</a>
