<locumsnest-section-title [title]="title" [titleType]="'h2'"> </locumsnest-section-title>
<div
  class="scroller"
  infinite-scroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div *ngFor="let message of messages" class="message-box-content-row">
    <locumsnest-message
      [sender]="message?.sender"
      [messageText]="message?.messageText"
      [createdAt]="message?.createdAt"
    >
    </locumsnest-message>
  </div>
</div>
