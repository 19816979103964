import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';

type AlertType = 'error' | 'info' | 'success' | 'warn';

@Component({
  selector: 'locumsnest-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  animations: [
    trigger('inOut', [
      transition(':enter', [style({ transform: 'translateX(100%)' }), animate('1000ms')]),
      transition(':leave', [animate('1000ms', style({ transform: 'translateX(100%)' }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  message = input('');
  alertType = input<AlertType>('error');
  displayAlert = input(false);

  @Output() toastClicked = new EventEmitter<void>();

  public onToastClicked() {
    this.toastClicked.emit();
  }
}
