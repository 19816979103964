import { Component, EventEmitter, Input, Output } from '@angular/core';

type IconOptions = 'email' | 'share' | 'edit';

@Component({
  selector: 'locumsnest-circular-button',
  templateUrl: './circular-button.component.html',
  styleUrls: ['./circular-button.component.scss'],
})
export class CircularButtonComponent {
  @Output() click = new EventEmitter();
  @Input() icon: IconOptions;
  @Input() label: string;
  @Input() url = '';

  constructor() {}

  onClicked() {
    this.click.emit();
  }
}
