import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LocumsNestComponent } from '../../core/locums-nest-component';

interface RecentActivity {
  id: number;
  createdAt: string;
  fullName: string;
  email: string;
  description: string;
}

@Component({
  selector: 'locumsnest-recent-activity',
  templateUrl: './recent-activity.component.html',
  styleUrls: ['./recent-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentActivityComponent extends LocumsNestComponent {
  @Input() recentActivity: RecentActivity[];
  @Input() isLoaded = false;
  @Input() isLoading = false;

  @Output() loadMore = new EventEmitter();

  public onScroll() {
    this.loadMore.emit();
  }
}
