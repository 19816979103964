import { Directive, ElementRef, inject, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[locumsnestAddAnchorBlank]',
  standalone: true,
})
export class AddAnchorBlankDirective implements OnInit {
  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  ngOnInit() {
    const links: HTMLAnchorElement[] = this.elementRef.nativeElement.querySelectorAll('a');
    links.forEach((link) => {
      this.renderer.setAttribute(link, 'target', '_blank');
    });
  }
}
