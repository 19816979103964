import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { CarouselPanelComponent } from '../../molecules/carousel-panel/locumsnest-carousel-panel';

@Component({
  selector: 'locumsnest-carousel',
  styleUrls: ['./locumsnest-carousel.scss'],
  templateUrl: './locumsnest-carousel.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @Input() currentPane: number;
  console = window.console;
  @ContentChildren(CarouselPanelComponent, { descendants: true })
  carouselPanels: QueryList<CarouselPanelComponent>;

  @Output() showCarouselPanel: EventEmitter<number> = new EventEmitter<number>();

  onShowCarouselPanel(carouselPanelIndex: number) {
    this.showCarouselPanel.emit(carouselPanelIndex);
  }
}
