<div class="progress">
  <div
    class="progress-bar"
    role="progressbar"
    aria-valuenow="70"
    aria-valuemin="0"
    aria-valuemax="100"
    [ngStyle]="{ width: percentage + '%' }"
  >
    <span class="sr-only">4</span>
  </div>
</div>
<div class="percentage-text">{{ percentage | number: '.0-2' }}% done</div>
