<form [ngrxFormState]="contactFormState">
  <locumsnest-section-title [title]="title" [titleType]="'h2'"> </locumsnest-section-title>
  <div class="input-row">
    <locumsnest-dropdown-grouping-select
      class="subjectOptions"
      [ngrxFormControlState]="contactFormState?.controls.subject"
      [options]="subjectOptions"
      [placeholder]="subjectPlaceholder"
      (select)="onSubjectSelect($event)"
    ></locumsnest-dropdown-grouping-select>
  </div>
  <div class="input-row">
    <locumsnest-text-area
      legacy
      [ngrxFormControlState]="contactFormState?.controls.message"
      [placeholder]="messageNote"
      [errorMsg]="getErrorMsg(contactFormState?.controls.message)"
      [isInvalid]="
        contactFormState?.controls.message.isDirty && contactFormState?.controls.message.isInvalid
      "
    ></locumsnest-text-area>
  </div>
  <div class="input-row">
    <locumsnest-help-text class="help-text" [helpText]="helpText"></locumsnest-help-text>
  </div>
  <locumsnest-button
    class="submit-button"
    [buttonType]="'info'"
    [size]="'small'"
    [disabled]="
      contactFormState.isInvalid && contactFormState.userDefinedProperties.enableSubmitButton
    "
    (click)="onSendMessage()"
  >
    {{ buttonLabel }}
  </locumsnest-button>
</form>
