import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
  selector: '[locumsnestFocusable]',
  standalone: true,
})
export class FocusableDirective implements AfterViewInit {
  private host = inject(ElementRef);

  ngAfterViewInit() {
    this.host.nativeElement.focus();
  }
}
