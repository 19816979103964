<div class="icon-button" (click)="onActionIconClicked()">
  <div
    class="icon"
    [ngClass]="{
      approve: icon === 'approve',
      decline: icon === 'decline',
      pending: icon === 'pending'
    }"
  ></div>
  <div class="text-area">
    <div *ngIf="(showDisplayText$ | async) && icon === 'approve'" class="approve-text">
      {{ displayText }}
    </div>
  </div>
</div>
