import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, numberAttribute } from '@angular/core';

@Component({
  selector: 'locumsnest-icon',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() iconName = 'lock';
  @Input({ transform: numberAttribute }) width: string | number = 20;
  @Input({ transform: numberAttribute }) height: string | number = 20;
  public readonly iconBasePath = '/assets/icons/';
}
