<div class="header" (click)="onToggle()">
  <span class="icon"></span>
  <span class="title">
    @if (hasNewMessages) {
      <div class="status-icon-bell"></div>
    }
    {{ title }}
  </span>
</div>
@if (isExpanded) {
  <div
    class="main"
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    @for (message of messages; track message.id) {
      <div class="messages">
        <locumsnest-messenger-message [message]="message" [isTimesheetView]="true" />
      </div>
    }
  </div>
}

<div class="footer">
  <textarea
    #text
    type="text"
    placeholder="{{ placeholder }}"
    rows="1"
    [ngrxFormControlState]="newComment"
    (keydown)="onKeyUp()"
  ></textarea>
  <span class="arrow" (click)="onClick()"></span>
</div>
