import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IJobListingTemplateLoaded } from 'apps/hospital-admin/src/app/job-listing/+state/interfaces/job-listing-ui-state';

import { IAlertState } from '@locumsnest/core/src';

@Component({
  selector: 'locumsnest-template-save-modal',
  templateUrl: './template-save-modal.component.html',
  styleUrls: ['./template-save-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateSaveModalComponent {
  @Input() jobListingIsLoadedFromTemplate = false;
  @Input() saveResponseStatus: IAlertState;
  @Input() existingTemplate: IJobListingTemplateLoaded;

  @Output() declineSaveAsTemplateClicked = new EventEmitter<void>();
  @Output() saveTemplateClicked = new EventEmitter<string>();
  @Output() updateExistingTemplate = new EventEmitter<{
    templateName: string;
    templateId: number;
  }>();

  public displayTemplateNameInput = false;
  public templateName: string;

  public onDeclineClicked() {
    if (this.jobListingIsLoadedFromTemplate) {
      this.displayTemplateNameInput = true;
    } else {
      this.declineSaveAsTemplateClicked.emit();
    }
  }

  public onSaveTemplateClicked() {
    this.saveTemplate();
  }

  public onCancelClicked() {
    this.displayTemplateNameInput = false;
  }

  public onAcceptClicked() {
    if (this.jobListingIsLoadedFromTemplate) {
      this.updateExistingTemplate.emit({
        templateName: this.existingTemplate.name,
        templateId: this.existingTemplate.id,
      });
    } else {
      this.displayTemplateNameInput = true;
    }
  }

  private saveTemplate() {
    this.saveTemplateClicked.emit(this.templateName);
    setTimeout(() => {
      this.templateName = '';
      this.displayTemplateNameInput = false;
    }, 100);
  }
}
