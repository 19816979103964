<div
  *ngIf="displayAlert"
  class="alert-container"
  [ngClass]="{
    'limit-width': limitToMaxWidth,
    'full-Width': fullWidth
  }"
  [ngStyle]="{ top: top + 'px' }"
>
  <div
    class="alert-box"
    role="alert"
    [ngClass]="{
      success: alertType === 'success',
      info: alertType === 'info',
      error: alertType === 'error',
      'limit-width': limitToMaxWidth
    }"
  >
    <ng-container *ngIf="message && message !== ''; else content">
      {{ message }}
    </ng-container>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  </div>
</div>
