import { Component, Input } from '@angular/core';
import { InfoCard } from '../../interfaces/info-card';

@Component({
  selector: 'locumsnest-info-card-list',
  templateUrl: './info-card-list.component.html',
  styleUrls: ['./info-card-list.component.scss'],
})
export class InfoCardListComponent {
  @Input() infoCardList: InfoCard[];
  @Input() title: string;

  constructor() {}

  _trackBy(index, row) {
    return row ? row.id : null;
  }
}
