// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../typings/calendar.d.ts" />
import { Calendar as BaseCalendar } from 'calendar';
import moment from 'moment-timezone';

import { ICalendarDays } from './interfaces';

export class Calendar extends BaseCalendar {
  public static readonly months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public static readonly days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

  monthDates(year: number, month: number, dayFormatter?, weekFormatter?) {
    if (typeof year !== 'number' || year < 1970) {
      throw new Error('Calendar Exception: year must be a number >= 1970');
    }
    if (typeof month !== 'number' || month < 0 || month > 11) {
      throw new Error('Calendar Exception:month must be a number (Jan is 0)');
    }
    const weeks = [];
    let week = [];
    let date = this.weekStartDate(moment([year, month, 1]));
    let val: Date;

    do {
      for (let i = 0; i < 7; i++) {
        val = date.toDate();
        week.push(dayFormatter ? dayFormatter(val) : val);
        date = date.clone().add(1, 'day');
      }
      weeks.push(weekFormatter ? weekFormatter(week) : week);
      week = [];
    } while (date.month() <= month && date.year() === year);
    return weeks;
  }

  weekStartDate(date: moment.Moment) {
    while (date.day() !== this.firstWeekDay) {
      date.subtract(1, 'day');
    }
    return date;
  }

  monthDays(year: number, month: number): ICalendarDays[][] {
    const getDayOrZero = (date: Date) => (date.getMonth() === month ? date : 0);

    return this.monthDates(+year, +month, getDayOrZero);
  }
}
