import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DateTime } from '@locumsnest/core/src/lib/helpers/date-time';
import { DATE_FORMAT, DATE_FORMAT_DB } from '@locumsnest/core/src/lib/types/constants';

import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';

@Component({
  selector: 'locumsnest-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  @Input() isDisabled: boolean;
  @Input() timePlaceholder = 'Time (hh:mm)';
  @Input() datePlaceholder = 'Date';
  @Input() dateTitle = '';
  @Input() timeTitle = '';
  @Input() horizontalAlignment = false;
  @Input() hasBorder: boolean;

  @Output() timeChange = new EventEmitter<Event>();
  @Output() dateChange = new EventEmitter<Date>();

  get date() {
    return DateTime.getDateFromPartialISOString(this.value);
  }

  get viewDate() {
    if (this.date) {
      const date = Time.getMoment(this.date);

      if (date.isValid()) {
        return date.format(DATE_FORMAT);
      }
    }

    return '';
  }

  set viewDate(date: string) {
    this.writeDate(date);
  }

  get time() {
    return DateTime.getTimeFromPartialISOString(this.value);
  }

  set time(time: string) {
    const newTime = Time.getMoment(time, 'hh:mm:ss');
    if (time?.length > 4 && newTime.isValid()) {
      this.writeTime(newTime.format('HH:mm:ss'));
    } else {
      this.writeTime(time);
    }
  }

  public onTimeChange(event: Event) {
    this.timeChange.emit(event);
  }

  public onDateChange(event: Date) {
    this.dateChange.emit(event);
  }

  writeTime(time: string) {
    if (time) {
      this.writeValue(this.date + 'T' + time);
    }
  }

  writeDate(date: string) {
    if (date) {
      this.writeValue(this.formatDate(date) + 'T' + this.time);
    }
  }

  formatDate(date: string) {
    if (!date) {
      return '';
    }

    return Time.getMoment(date, DATE_FORMAT).format(DATE_FORMAT_DB);
  }
}
