import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LineSize } from '../../atoms/section-title/section-title.component';

export type SectionActionType = 'share';

@Component({
  selector: 'locumsnest-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent {
  @Input() title: string;
  @Input() underLineSize: LineSize;
  @Input() action: SectionActionType;
  @Input() actionDisabled = false;
  @Input() actionHelp = '';

  @Output() actionClicked: EventEmitter<void> = new EventEmitter<void>();

  public onActionClicked() {
    this.actionClicked.emit();
  }
}
