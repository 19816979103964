<span class="input-text" *ngIf="!inEditMode" (click)="onDisplayAreaClicked()">
  {{ getPlaceHolder() }}
</span>

<span class="editable-display-area-input" *ngIf="inEditMode">
  <locumsnest-input-field
    [ngModel]="inputText"
    [type]="type"
    [errorMsg]="errorMsg"
    (inputEntered)="onChangeArea($event)"
    (blur)="onBlur($event)"
  >
  </locumsnest-input-field>
</span>
