import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  CdkCellDef,
  CdkColumnDef,
  CdkHeaderCellDef,
  CdkHeaderRow,
  CdkHeaderRowDef,
  CdkNoDataRow,
  CdkRow,
  CdkRowDef,
  CdkTable,
} from '@angular/cdk/table';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChildren,
  effect,
  input,
  QueryList,
  signal,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { ExpandedRowDirective } from './expanded-row.directive';
import { TableColumnComponent } from './table-column.component';

@Component({
  selector: 'ln-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    CdkTable,
    CdkRowDef,
    CdkRow,
    CdkHeaderRow,
    CdkHeaderRowDef,
    CdkNoDataRow,
    CdkColumnDef,
    CdkRowDef,
    CdkCellDef,
    CdkHeaderCellDef,
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0', opacity: 0.2 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T extends { id: number }> implements AfterContentInit {
  public loading = input(false);
  public dataSource = input<T[]>([]);
  public displayHeaders = input<string[]>([]);
  public expandedHeaders = input<string[]>([]);
  public emptyPlaceholder = input('No results');
  public expanded = signal([]);

  @ViewChild('cdkTable', { read: CdkTable, static: true }) private cdkTableRef?: CdkTable<T>;

  @ContentChildren(TableColumnComponent) columnDefComponents!: QueryList<TableColumnComponent>;
  @ContentChildren(CdkRowDef) rowDefs!: QueryList<CdkRowDef<T>>;
  @ContentChildren(ExpandedRowDirective, { descendants: true })
  extendedRows!: QueryList<ExpandedRowDirective>;

  selectionModel = new SelectionModel<number>();

  modesChanges = toSignal(this.selectionModel.changed);

  colspan = computed(() => this.displayHeaders().length);

  constructor() {
    effect(() => {
      this.modesChanges()?.added.forEach((av) => {
        const found = this.extendedRows.filter((x) => x.id() === av);
        found.forEach((f) => f.expandRow());
      });
      this.modesChanges()?.removed.forEach((av) => {
        const found = this.extendedRows.filter((x) => x.id() === av);
        found.forEach((f) => f.collapseRow());
      });
    });
  }

  public ngAfterContentInit(): void {
    this.columnDefComponents.forEach((component) => {
      if (!this.cdkTableRef) return;
      this.cdkTableRef.addColumnDef(component.columnDef);
    });
    this.rowDefs.forEach((rowDef) => {
      if (!this.cdkTableRef) return;
      this.cdkTableRef.addRowDef(rowDef);
    });
  }

  trackById(_: number, name: T): number {
    return name.id;
  }
}
