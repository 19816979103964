import flags from './flags';
import food from './food';
import nature from './nature';
import objects from './objects';
import people from './people';
import places from './places';
import symbols from './symbols';

export default {
  ...people,
  ...objects,
  ...nature,
  ...food,
  ...places,
  ...symbols,
  ...flags,
};
