<div class="mt-4">
  <locumsnest-input-label
    input="{{
      isTimesheet ? 'Reason for time and/or rate discrepancy:' : 'Reason for rate escalation:'
    }}"
  ></locumsnest-input-label>

  <locumsnest-dropdown-grouping-select
    [isDisabled]="isDisabled"
    [clearOnDestroy]="true"
    [options]="rateViolationReasonOptions"
    [placeholder]="'Select'"
    [ngrxFormControlState]="rateViolationsReasonFormControl"
    [extraClass]="'transparent-theme'"
  ></locumsnest-dropdown-grouping-select>

  <div *ngIf="rateViolationsReasonFormControl.value === null">
    <small class="text-danger error-msg-small">
      {{
        isTimesheet
          ? isAdhoc
            ? 'Your timesheet has escalated rates!'
            : 'Your timesheet has different times and/or rates compared to the authorised shift!'
          : 'One or more of your shifts have escalated rates!'
      }}
    </small>
  </div>
</div>
