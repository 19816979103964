import { Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() message = '';
  @Input() phrase: string;
  @Input() active = false;

  randomPhrase: string;
  intervalId: any;

  constructor() {}
}
