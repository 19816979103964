import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TippyDirective } from '@ngneat/helipopper';

import { IconComponent } from '../../atoms/icon/icon.component';
import { FocusableDirective } from '../../pipes/focusable/focusable.directive';
import { TextTruncatePipe } from '../../pipes/text-truncate/text-truncate.pipe';
import { TabId } from '../tabs/+state';

@Component({
  selector: 'ln-list-page-tab-filters',
  standalone: true,
  imports: [TextTruncatePipe, IconComponent, NgClass, TippyDirective, FocusableDirective],
  templateUrl: './list-page-tab-filters.component.html',
  styleUrl: './list-page-tab-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPageTabFiltersComponent {
  @Input({ required: true }) homeTab = false;
  @Input({ required: true }) isActiveTab = false;
  @Input({ required: true }) editMode = false;
  @Input({ required: true }) tabId: TabId;
  @Input({ required: true }) tabName = '';

  @Output() removeTab = new EventEmitter<TabId>();
  @Output() updateTabName = new EventEmitter<{ id: TabId; name: string }>();

  onRemoveTab(event: Event, id: TabId) {
    event.stopPropagation();
    this.removeTab.emit(id);
  }

  onUpdateTabName(id: TabId, name: string) {
    this.updateTabName.emit({ id, name });
  }
}
