import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'locumsnest-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() show: boolean;
  @Input() title: string;
  @Input() showCloseButton = true;
  @Input() showLine = true;
  @Output() close = new EventEmitter<void>();

  constructor() {}

  closeModal() {
    this.close.emit();
  }
}
