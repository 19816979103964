<ng-container *ngIf="url !== ''">
  <a class="circular-button" href="{{ url }}" target="_parent">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>
<!-- chrome treat javascript:void as unsafe when it comes from variable -->
<ng-container *ngIf="url === ''">
  <a class="circular-button test" href="javascript:void(0)">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <span class="icon" [ngClass]="icon"></span><span class="label">{{ label }}</span>
</ng-template>
