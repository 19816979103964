<div class="modal" id="reference-modal" *ngIf="show">
  <div class="background-close" (click)="closeModal()"></div>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select="[body]"></ng-content>
      </div>
      <ng-container *ngIf="footer">
        <div class="modal-footer">
          <ng-content select="[footer]"></ng-content>
        </div>
      </ng-container>
    </div>
  </div>
</div>
