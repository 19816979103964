<div class="detail-card" [ngStyle]="{ 'max-width': fullWidth ? '100%' : '580px' }">
  <div
    class="detail-card__left-part"
    [ngClass]="{
      info: cardType === 'info',
      approved: cardType === 'approved',
      cancelled: cardType === 'cancelled',
      incomplete: cardType === 'incomplete',
      report: cardType === 'report'
    }"
  >
    <div
      class="detail-card__left-part__icon"
      [ngClass]="{
        info: cardType === 'info',
        approved: cardType === 'approved',
        cancelled: cardType === 'cancelled',
        incomplete: cardType === 'incomplete',
        report: cardType === 'report'
      }"
    ></div>
  </div>

  <div class="detail-card-wrapper">
    <div
      class="detail-card-text"
      [ngClass]="{
        info: cardType === 'info',
        approved: cardType === 'approved',
        cancelled: cardType === 'cancelled',
        incomplete: cardType === 'incomplete',
        report: cardType === 'report',
        bold: fontWeight === 'bold',
        medium: size === 'medium'
      }"
    >
      <div *ngIf="showDetails; else showTitle" class="detail-card-text__main">
        <span class="detail-card-text__main__name">{{ name }}</span>
        <span class="detail-card-text__main__details">{{ details }}</span>
      </div>
      <div *ngIf="showStatus" class="detail-card-text__status">
        <div class="detail-card-text__status__name" [innerHTML]="statusDisplay"></div>
      </div>
    </div>
  </div>
</div>
<ng-template #showTitle>
  <div class="detail-card-text_main__title">
    {{ title }}
  </div>
  <ng-content></ng-content>
</ng-template>
