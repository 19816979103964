import { CommonModule } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, inject, Input, Output } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ngfModule } from 'angular-file';
import { IDetailsFile } from 'apps/hospital-admin/src/app/interfaces/api/trust-configuration-category-details-file-entity';
import { get } from 'lodash-es';
import * as papa from 'papaparse';

import { InputWrapperWithChangeDetection } from '@locumsnest/components/src/lib/core/input-wrapper';
import { ICsvData, ISerializableFile } from '@locumsnest/core/src';
import { Time } from '@locumsnest/core/src/lib/helpers';

import { EllipsisComponent } from '../../atoms/ellipsis/ellipsis.component';
import { IconComponent } from '../../atoms/icon/icon.component';
import { IsFileImagePipe } from '../../pipes/isFileImage/is-file-image.pipe';
import { FileComponent } from '../file/file.component';
import { DownloadLinkChangedEvent, ITempFile, TempFileUploadResponse } from './interfaces';
import { TEMP_FILE_UPLOAD_SERVICE } from './opaque-tokens';

@Component({
  selector: 'locumsnest-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ngfModule,
    IsFileImagePipe,
    EllipsisComponent,
    FileComponent,
    IconComponent,
  ],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent extends InputWrapperWithChangeDetection {
  @Input() maxSize: number;
  @Input() disabled = false;
  @Input() showFileDetails = false;
  @Input() acceptFormats;
  @Input() acceptFormatsUi;
  @Input() compact = false;
  @Input() parseCsv = false;
  @Input() transparentTheme = false;
  @Input() tempFileUpload = false;
  @Input() onlyIcon = false;
  @Input() useTempFiles = false;
  @Input() controlTheme = false;
  @Input() uploadedFiles: IDetailsFile[];
  @Input() blueTheme = false;

  @Output() cancelBtnClicked = new EventEmitter<string>();
  @Output() uploadProgressUpdated = new EventEmitter<{ file: File; progress: number }>();
  @Output() downloadLinkChanged = new EventEmitter<DownloadLinkChangedEvent>();
  @Output() deleteButtonClicked = new EventEmitter<number>();

  public defaultMaxSize = 3 * 1024 * 1024 * 10;
  accept = '*';
  files: File[];
  tempFiles: ITempFile[] = [];
  hasBaseDropZoneOver = false;
  lastFileAt: Date;
  // quick fix for showing messages
  //(should update move it out from local state when we have more time)
  errorMessage = '';

  sendableFormData: FormData; //populated via ngfFormData directive

  private tempFileUploadService = inject(TEMP_FILE_UPLOAD_SERVICE, { optional: true });

  writeValue(value) {
    if (!value) {
      this.files = [];
    }
    // reset temp files on clear value
    if ((this.useTempFiles || this.controlTheme) && !value.length) this.tempFiles = [];
    super.writeValue(value);
  }

  getDate() {
    return Time.getDate();
  }

  readFile(inputFile: File) {
    const fileObj = new Promise<ISerializableFile>((resolve) => {
      const reader = new FileReader();
      const { name } = inputFile;

      reader.onload = (_) => {
        let base64: string = reader.result as string;
        let a = [];

        if (name.slice(name.length - 3) === 'csv') {
          a = base64.split(';');
          a.shift();
          a.unshift('data:text/csv');
          base64 = a.join(';');
        }

        const file: ISerializableFile = {
          name,
          base64EncodedFile: base64 as string,
        };
        resolve(file);
      };

      reader.readAsDataURL(inputFile);
    });
    return this.parseCsv ? this.composeCsvFileObj(fileObj, inputFile) : fileObj;
  }

  invalidDrag(files: { file: File; type: string }[]) {
    if (!files) return;

    for (const file of files) {
      if (file.type === 'fileSize') {
        this.errorMessage =
          'Invalid file size (Max ' +
          this.getSizeDisplay(this.maxSize ? this.maxSize : this.defaultMaxSize) +
          ')';
      }
    }
  }

  getSizeDisplay(size: number) {
    const units = ['bytes', 'KB', 'MB', 'GB'];
    let display = '';
    for (const unit of units) {
      display = size + unit;
      if (Math.floor(size / 1024) === 0) {
        break;
      }
      size = Math.floor((size / 1024) * 100) / 100;
    }
    return display;
  }

  composeCsvFileObj(
    fileObjPromise: Promise<ISerializableFile>,
    inputFile: File,
  ): Promise<ISerializableFile> {
    return Promise.all([fileObjPromise, this.readAsText(inputFile)]).then(
      ([fileObject, parsedCsvData]) => ({
        ...fileObject,
        parsedCsvData,
      }),
    );
  }
  readAsText(file): Promise<ICsvData> {
    return new Promise<ICsvData>((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) =>
        resolve(papa.parse(fileReader.result, { skipEmptyLines: 'greedy' }));
      fileReader.readAsText(file);
    });
  }

  uploadFile(file: File) {
    if (this.controlTheme) {
      this.tempFiles.push({ file, token: 'uploading', uploading: true, title: file.name });
      this.writeTempFile();
    } else if (this.useTempFiles) {
      this.tempFiles.push({ file, token: 'uploading', uploading: true });
      this.writeTempFile();
    }
    return new Promise<TempFileUploadResponse>((resolve) => {
      this.tempFileUploadService.upload(file).subscribe({
        next: (e) => {
          if (e.type === HttpEventType.UploadProgress) {
            this.uploadProgressUpdated.emit({ file, progress: (e.loaded / e.total) * 100 });
          }
          if (e.type === HttpEventType.Response) {
            this.uploadProgressUpdated.emit({ file, progress: 100 });
            resolve(e.body);
          }
        },
        error: () => {
          if (this.useTempFiles || this.controlTheme)
            this.removeTempFile(this.tempFiles.length - 1);
          this.errorMessage = 'Sorry! Upload failed. Please try again in a few minutes';
          setTimeout(() => (this.errorMessage = ''), 2000);
        },
      });
    });
  }

  onFileChanged(file) {
    this.errorMessage = '';
    const event = { file };
    if (this.tempFileUpload) {
      this.uploadFile(file).then((tempFileDetails) => {
        const { filePath, downloadLink } = tempFileDetails;
        if (this.controlTheme) {
          this.removeTempFile(this.tempFiles.length - 1);
          this.tempFiles.push({ file, token: filePath, title: file.name, uploading: false });
          this.writeTempFile();
        } else if (this.useTempFiles) {
          this.removeTempFile(this.tempFiles.length - 1);
          this.tempFiles.push({ file, token: filePath, uploading: false });
          this.writeTempFile();
        } else {
          this.writeValue(filePath);
          this.propagateChange(this.value);
        }
        this.downloadLinkChanged.emit({ downloadLink });
      });
      return;
    }
    this.readFile(file).then((serializableFile) => {
      // @todo move id to form group and remove from json field
      const id = this.value ? get(JSON.parse(this.value), 'id') : undefined;
      this.writeValue(JSON.stringify({ id, ...serializableFile }));
      this.propagateChange(this.value);
      this.uploadProgressUpdated.emit({ ...event, progress: null });
    });
  }

  public onCancelBtnClicked(event) {
    event.stopPropagation();
    this.cancelBtnClicked.emit();
  }

  removeTempFile(i: number) {
    const deletedTempFile = this.tempFiles.splice(i, 1);
    this.writeTempFile();
    if (!deletedTempFile[0].uploading && !this.controlTheme) {
      this.tempFileUploadService.deleteFile(deletedTempFile[0].token).subscribe();
    }
  }

  deleteFile(id: number) {
    this.deleteButtonClicked.emit(id);
  }

  trackByToken(_: number, tempFile: ITempFile) {
    return tempFile.token;
  }

  private writeTempFile() {
    if (this.controlTheme) {
      this.writeValue(this.tempFiles.map((t) => ({ file: t.token, title: t.title })));
    } else {
      this.writeValue(this.tempFiles.map((t) => t.token));
    }
    this.propagateChange(this.value);
  }
}
