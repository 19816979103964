<div
  class="file-upload"
  [ngClass]="{
    'file-upload-compact': compact,
    hideUpload: (uploading || !uploading) && file.value && compact
  }"
>
  <locumsnest-file-upload
    [ngrxFormControlState]="file"
    [disabled]="isDisabled"
    [showFileDetails]="showFileDetails && !!file.value"
    [acceptFormats]="acceptFormats"
    [acceptFormatsUi]="acceptFormatsUi"
    [compact]="compact"
    [parseCsv]="parseCsv"
    [transparentTheme]="transparentTheme"
    [maxSize]="maxSize"
    [blueTheme]="blueTheme"
    [tempFileUpload]="tempFileUpload"
    (uploadProgressUpdated)="onUploadProgressUpdated($event)"
    (cancelBtnClicked)="onCancelBtnClicked()"
  />
</div>
@if (file.value || uploading) {
  <div class="file-uploading-info-container">
    @if (showFileDetails) {
      <div class="file_upload_details_div">
        <ng-content select="[fileDetails]"></ng-content>
      </div>
    }
    @if (!showFileDetails) {
      <div class="file_upload_img_div" (click)="downloadFile()">
        <div class="file_upload_img" [ngClass]="{ 'file_upload_img--compact': compact }"></div>
        <div class="file_info_div" [ngClass]="{ 'file_info_div--compact': compact }">
          <span class="filename-text" [ngClass]="{ 'filename-text--compact': compact }">{{
            fileName
          }}</span>
          <span
            class="filesize-text"
            [hidden]="!uploading"
            [ngClass]="{ 'filesize-text--compact': compact }"
            >{{ fileSize / 1024 / 1024 | number: '.2' }} MB</span
          >
          @if (!isDisabled && file.value) {
            <span class="remove" (click)="onRemoveFileBtnClicked($event)">x</span>
          }
          <div class="progress-bar-div">
            <locumsnest-progress-bar
              [percentage]="percentage"
              [hidden]="!uploading"
            ></locumsnest-progress-bar>
          </div>
          <span
            class="file-attached-text"
            [hidden]="uploading"
            [ngClass]="{ 'file-attached-text--compact': compact }"
            >File Attached!</span
          >
        </div>
      </div>
    }
    @if (showUploadButton) {
      <div class="file_upload_button_div">
        <ng-content select="[uploadButton]"></ng-content>
      </div>
    }
  </div>
}
