<div class="attachment-preview">
  @for (item of tempFiles; track trackByToken; let i = $index) {
    <div class="attachment-preview__container">
      @if (!item.uploading) {
        <ng-container>
          <div class="attachment-preview__container__file">
            <a [href]="item.filePath">
              <div class="file-name">{{ item.title }}</div>
            </a>
            @if (item?.file.size > 0) {
              <div class="file-size">({{ item?.file.size | fileSize }})</div>
            }
          </div>
          <div class="attachment-preview__container__remove-icon" (click)="onRemoveFile(i)"></div>
        </ng-container>
      }
      @if (item.uploading) {
        <div
          class="attachment-preview__container__file attachment-preview__container__file__loading"
        >
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      }
    </div>
  }
  @for (item of files; track item.id; let i = $index) {
    <div
      class="attachment-preview__container"
      [ngClass]="{
        'attachment-preview__container--blue': theme === 'blue',
        'attachment-preview__container--grey': theme === 'light-grey'
      }"
    >
      <ng-container>
        <div class="attachment-preview__container__file">
          @if (!isEditMode) {
            <div
              class="document-icon"
              [ngClass]="{
                'document-icon__blue': theme === 'blue',
                'document-icon__grey': theme === 'light-grey'
              }"
            ></div>
          }
          <a [href]="item.filePath">
            <div class="file-name">{{ item.title }}</div></a
          >
          @if (item?.fileSize > 0) {
            <div class="file-size">({{ item?.fileSize | fileSize }})</div>
          }
        </div>
        @if (isEditMode) {
          <div
            class="attachment-preview__container__remove-icon"
            (click)="onDeleteFile(item.id)"
          ></div>
        }
      </ng-container>
    </div>
  }
</div>
