<ng-select
  bindLabel="name"
  bindValue="id"
  groupBy="category"
  [virtualScroll]="virtualScroll"
  [id]="idx"
  [searchable]="searchable"
  [items]="options"
  [placeholder]="placeholder"
  [disabled]="isDisabled"
  [clearable]="clearable"
  [hideSelected]="hideSelected"
  [multiple]="multiple"
  [selectableGroup]="selectableGroup"
  [selectableGroupAsModel]="selectableGroupAsModel"
  [closeOnSelect]="!multiple"
  [loading]="loading"
  (close)="onClose()"
  (blur)="onBlur($event)"
  (change)="onOptionSelect($event)"
  (click)="onClick($event)"
  (clear)="onClearAll()"
  (remove)="onRemoveOne($event)"
  (add)="onAddOne($event)"
  (search)="onSearch($event)"
  (scrollToEnd)="onScrollToEnd()"
  [(ngModel)]="value"
>
  >
  <ng-template ng-optgroup-tmp let-item="item">
    <div
      title="{{ item.category || 'Unnamed group' }}"
      [id]="idx + '__' + (item.category || 'unnamed-group')"
    >
      {{ item.category || 'Unnamed group' }}
    </div>
  </ng-template>

  <ng-template ng-label-tmp let-item="item">
    {{ item.name }}
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    <div title="{{ item.name }}" [id]="idx + '__' + item.name | kebabcase | lowercase">
      {{ item.name | textTruncate: 40 }}
    </div>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    @for (item of items | slice: 0 : maxDisplayItems; track item.id) {
      <div class="ng-value displayed-option">
        <ng-container
          *ngIf="clearButtonPosition === 'left'"
          [ngTemplateOutlet]="clearButton"
          [ngTemplateOutletContext]="{
            $implicit: item,
            clear: clear
          }"
        ></ng-container>

        <span
          class="ng-value-label displayed-option-label"
          [ngClass]="{ 'ng-value-icon--multi-select': multiple }"
          [id]="idx + '__' + item.name | kebabcase | lowercase"
        >
          {{ item.name | textTruncate }}
        </span>

        <ng-container
          *ngIf="clearButtonPosition === 'right'"
          [ngTemplateOutlet]="clearButton"
          [ngTemplateOutletContext]="{
            $implicit: item,
            clear: clear
          }"
        ></ng-container>
      </div>
    }
    <div *ngIf="items.length > maxDisplayItems" class="ng-value">
      <span class="ng-value-label" [id]="idx + '__more'">
        {{ items.length - maxDisplayItems }} more...
      </span>
    </div>
  </ng-template>
</ng-select>

<ng-template #clearButton let-item let-clear="clear">
  <span
    class="ng-value-icon {{ clearButtonPosition }}"
    aria-hidden="true"
    [ngClass]="{ 'ng-value-icon--multi-select': multiple }"
    [id]="idx + '__' + item.name + '__clear' | kebabcase | lowercase"
    (click)="clear(item)"
    >×
  </span>
</ng-template>
