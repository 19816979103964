import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWrapper } from '@locumsnest/components/src/lib/core/input-wrapper';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

import { ActionButtonComponent } from '../../atoms/action-button/action-button.component';
import { IconComponent } from '../../atoms/icon/icon.component';
import { InputFieldComponent } from '../../atoms/input-field/input-field.component';
import { CalendarComponent } from '../calendar/calendar.component';

@Component({
  selector: 'locumsnest-date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  imports: [
    FormsModule,
    InputFieldComponent,
    NgClass,
    CalendarComponent,
    IconComponent,
    ActionButtonComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent extends InputWrapper implements ControlValueAccessor {
  @Input() idx: string;
  // api bindings
  @Input() disabled: boolean;
  @Input() accentColor: string;
  @Input() altInputStyle: boolean;
  @Input()
  get dateFormat(): string {
    return this._dateFormat || this.DEFAULT_FORMAT;
  }
  set dateFormat(val: string) {
    this._dateFormat = val;
  }
  @Input() fontFamily: string;
  @Input() rangeStart: Date;
  @Input() rangeEnd: Date;
  @Input() extraClass: string;
  // data
  @Input() placeholder = 'Select a date';
  // view logic
  @Input() showCalendar = false;
  @Input() showCalendarIcon = false;
  @Input() showClearButton = false;
  @Input() cancelText = 'Cancel';
  @Input() weekStart = 1;
  @Input() isInvalid: boolean;
  @Input() isRequired: boolean;
  @Input() showCalendarIconInInput = false;
  @Input() errorMsg: string;
  @Input() confirmButtonText: string;
  @Input() showConfirmButton = false;
  @Input() showResetButton = false;
  @Input() datePickerLabelText: string;
  @Input() hasBorder: boolean;
  @Input() borderNoShadow = false;
  @Input() canSelectPastDate = true;

  // events
  // time
  @Input() currentMonth: string;
  @Input() dayNames: Array<string>;
  @Input() months: Array<string>;
  @Output() change = new EventEmitter<Date>();
  @Output() select = new EventEmitter<Date>();
  @Output() confirmButtonClicked = new EventEmitter<Date>();
  @Output() resetButtonClicked = new EventEmitter<void>();

  private _dateFormat: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly DEFAULT_FORMAT = DATE_FORMAT;

  constructor(private elementRef: ElementRef) {
    super();
  }

  @HostListener('document:click', ['$event'])
  handleGlobalClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeCalendar();
    }
  }

  get date(): Date {
    const { dateFormat } = this;
    if (typeof dateFormat === 'string')
      return Time.getMoment(this.value, dateFormat as string).toDate();
    // TODO remove function support since deprecated
    throw new Error('Improperly configured dateFormat');
  }
  set date(date: Date) {
    const { dateFormat } = this;
    if (date === null) {
      this.value = null;
      return;
    }
    if (typeof dateFormat === 'string') {
      this.value = Time.formatDate(date, dateFormat as string);
      return;
    }
    // TODO remove function support since deprecated
    throw new Error('Improperly configured dateFormat');
  }
  set value(val) {
    if (this._value === val) return;
    this._value = val;
    this.propagateChange(this.value);
    this.change.emit(this.value);
  }
  get value() {
    return this._value;
  }

  // -------------------------------------------------------------------------------- //
  // -------------------------------- State Management ------------------------------ //
  // -------------------------------------------------------------------------------- //
  /**
   * Closes the calendar and syncs visual components with selected or current date.
   * This way if a user opens the calendar with this month, scrolls to two months from now,
   * closes the calendar, then reopens it, it will open with the current month
   * or month associated with the selected date
   */
  closeCalendar(): void {
    this.showCalendar = false;
  }
  toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }
  /**
   * Sets the visible input text
   */
  setInputText(date: Date): void {}
  /**
   * Closes the calendar when the cancel button is clicked
   */
  onCancel(): void {
    this.closeCalendar();
  }

  /**
   * Toggles the calendar when the date input is clicked
   */
  onInputClick(): void {
    if (!this.disabled) {
      this.toggleCalendar();
    }
  }

  onCalendarIconClick(): void {
    if (!this.disabled) {
      this.toggleCalendar();
    }
  }

  /**
   * Returns the font color for a day
   */
  onSelectDay(newDate: Date): void {
    this.date = newDate;
    if (!this.showConfirmButton) {
      this.toggleCalendar();
    }
    this.select.emit(newDate);
  }

  onConfirmButtonClick(): void {
    //if the user is not click on a date then the default day should be the today
    if (this.date.toString() === 'Invalid Date') {
      this.date = Time.getDate();
    }

    this.toggleCalendar();
    this.confirmButtonClicked.emit(this.date);
  }

  onResetButtonClick(): void {
    this.toggleCalendar();
    this.resetButtonClicked.emit();
  }

  onClearButtonClick(): void {
    this.onSelectDay(null);
  }
}
