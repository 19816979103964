<div class="progress">
  <div
    class="progress-bar"
    [ngClass]="{
      success: statusType === 'success',
      info: statusType === 'info',
      error: statusType === 'error'
    }"
    [ngStyle]="{ width: percentage + '%' }"
  ></div>
  <div class="status-text">
    {{ status }}
  </div>
</div>
