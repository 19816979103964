import { createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

export const createSignalConverter = (actions$, signal, message) =>
  createEffect(() =>
    actions$.pipe(
      ofType(signal.TYPE),
      map(() => new message(signal.payload))
    )
  );
