<locumsnest-date-picker
  class="date-picker"
  [placeholder]="'From'"
  (select)="markAsSelectedStart($event)"
></locumsnest-date-picker>
<locumsnest-date-picker
  class="date-picker"
  [placeholder]="'To'"
  (select)="markAsSelectedEnd($event)"
></locumsnest-date-picker>
