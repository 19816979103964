import { CdkCellDef, CdkColumnDef, CdkHeaderCellDef, CdkTableModule } from '@angular/cdk/table';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  ViewChild,
} from '@angular/core';

import { CellDefDirective } from './cell-def.directive';
import { HeaderDefDirective } from './header-def.directive';

@Component({
  selector: 'ln-table-column',
  standalone: true,
  imports: [CdkTableModule],
  template: `
    <ng-container [cdkColumnDef]="name">
      <ng-content select="[lnHeaderDef]" />
      <ng-content select="[lnCellDef]" />
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnComponent implements AfterContentChecked {
  #name = '';
  @Input() isExpandedRow = false;

  @ViewChild(CdkColumnDef, { static: true }) private columnDefRef!: CdkColumnDef;
  @ContentChild(CellDefDirective, { static: true }) private cellDef?: CdkCellDef;
  @ContentChild(HeaderDefDirective, { static: true }) private headerCellDef?: CdkHeaderCellDef;

  @Input()
  get name(): string {
    return this.#name;
  }

  set name(value: string) {
    this.#name = value;
    if (!this.columnDefRef) return;
    this.columnDefRef.name = value;
  }

  get columnDef() {
    return this.columnDefRef;
  }

  get cell() {
    return this.columnDef.cell;
  }

  public ngAfterContentChecked(): void {
    this.columnDefRef.name = this.name;
    if (this.cellDef) {
      this.columnDefRef.cell = this.cellDef;
    }
    if (this.headerCellDef) {
      this.columnDefRef.headerCell = this.headerCellDef;
    }
  }
}
