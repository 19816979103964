<div class="calendar__nav">
  <div
    id="{{ idx }}__calendar-nav-left"
    class="calendar__nav__bubble"
    (click)="onMonthClick('left')"
  >
    <div class="calendar__nav__arrow calendar__nav__arrow--left"></div>
  </div>
  <div class="calendar__nav__header">
    <span>{{ currentMonth }}</span>
    <input
      class="calendar__nav__header__year"
      #yearInput
      placeholder="Year"
      (keyup.enter)="yearInput.blur()"
      [(ngModel)]="currentYear"
    />
  </div>
  <div
    id="{{ idx }}__calendar-nav-right"
    class="calendar__nav__bubble"
    (click)="onMonthClick('right')"
  >
    <div class="calendar__nav__arrow calendar__nav__arrow--right"></div>
  </div>
</div>
<div class="calendar__content">
  <div class="calendar__labels">
    @for (day of dayNamesOrdered; track trackById) {
      <div class="calendar__label">
        {{ day }}
      </div>
    }
  </div>
  <div class="calendar__month" [@calendarAnimation]="animate">
    @for (day of calendarDays; track trackById) {
      <div
        id="{{ idx }}__calendar-month-day-{{ day | date: 'D' }}"
        class="calendar__month__day"
        [ngClass]="{
          'calendar__month__day--selected': isChosenDay(day),
          'calendar__month__day--current': isCurrentDay(day),
          'calendar__month__day--highlighted': isHighlightedDay(day),
          'calendar__month__day--empty': !day,
          'calendar__month__day--past-date': isPastDate(day) || disabled
        }"
        (click)="onSelectDay(day)"
      >
        @if (day) {
          <span> {{ day | date: 'D' }} </span>
        }
      </div>
    }
  </div>
</div>
