import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';

import { paginationAdapter } from './filter-views.adapter';
import { IFilterViews } from './interfaces';

export const {
  ResetLoadingStateMessage,
  ResetPaginationMessage,
  SetLoadingStateMessage,
  UpsertMultiplePagesMessage,
  UpsertPageMessage,
} = paginationAdapter.getMessages();

export const paginationMessages = paginationAdapter.getMessages();
export class UpsertFilterViewsPageMessage extends UpsertPageMessage {}
export class UpsertFilterViewsMultiplePagesMessage extends UpsertMultiplePagesMessage {}
export class ResetFilterViewsPaginationMessage extends ResetPaginationMessage {}

export enum FilterViewsMessageTypes {
  SET_COLLECTION = '[FilterViews][Message] Set Collection',
  ADD_ONE = '[FilterViews][Message] Add One',
  UPDATE_ONE = '[FilterViews][Message] Update One',
  UPSERT_ONE = '[FilterViews][Message] Upsert One',
  DELETE_ONE = '[FilterViews][Message] Delete One',
  ADD_MULTIPLE = '[FilterViews][Message] Add All',
  DELETE_MULTIPLE = '[FilterViews][Message] Delete Many',
  UPSERT_MULTIPLE = '[FilterViews][Message] Upsert Many',
  UPDATE_MULTIPLE = '[FilterViews][Message] Update Many',
}

export class SetCollectionMessage implements Action {
  readonly type = FilterViewsMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IFilterViews[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = FilterViewsMessageTypes.ADD_ONE;
  constructor(public payload: { entity: IFilterViews }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = FilterViewsMessageTypes.UPSERT_ONE;
  constructor(public payload: { entity: IFilterViews }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = FilterViewsMessageTypes.ADD_MULTIPLE;
  constructor(public payload: { entities: IFilterViews[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = FilterViewsMessageTypes.UPSERT_MULTIPLE;
  constructor(public payload: { entities: IFilterViews[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = FilterViewsMessageTypes.UPDATE_ONE;
  constructor(public payload: { entity: Update<IFilterViews> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = FilterViewsMessageTypes.UPDATE_MULTIPLE;
  constructor(public payload: { entities: Update<IFilterViews>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = FilterViewsMessageTypes.DELETE_ONE;
  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = FilterViewsMessageTypes.DELETE_MULTIPLE;
  constructor(public payload: { ids: number[] }) {}
}

export type FilterViewsMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage;
