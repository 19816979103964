import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LocumsNestComponent } from '../../core/locums-nest-component';
import { IMessengerMessage } from './../../interfaces/message';

@Component({
  selector: 'locumsnest-messenger-message',
  templateUrl: './messenger-message.component.html',
  styleUrls: ['./messenger-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessengerMessageComponent extends LocumsNestComponent {
  @Input() message: IMessengerMessage;
  @Input() isTimesheetView = false;
  @Input() isLast = false;
}
