import { TextFieldModule } from '@angular/cdk/text-field';
import { NgClass, NgIf } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  numberAttribute,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';

@Component({
  selector: 'locumsnest-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  standalone: true,
  imports: [TextFieldModule, NgIf, NgClass, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  @Input() id: string;
  @Input() placeholder: string;
  @Input() onEnterBlur = false;
  @Input() errorMsg: string;
  @Input() prefixName: string;
  @Input() hasPrefix = false;
  @Input() @HostBinding('class.text-error') isInvalid: boolean;
  @Input({ transform: booleanAttribute }) legacy = false;
  @Input({ transform: numberAttribute }) cols = 15;
  @Input({ transform: numberAttribute }) rows = 3;
  @Input({ transform: numberAttribute }) rowSize = 25;
  @Input({ transform: numberAttribute }) columnSize = 25;
  @Input({ transform: booleanAttribute }) invertColors = false;
  @Input({ transform: booleanAttribute }) borderWithIcon = false;
  @Input({ transform: booleanAttribute }) disabled: boolean;
  @Input({ transform: numberAttribute }) autosizeMaxRows = 0;
  @Output() enter = new EventEmitter<string>();
  @Output() deletePrefix = new EventEmitter<void>();

  @ViewChild('textArea', { read: ElementRef })
  textArea: ElementRef;

  constructor(elementRef: ElementRef) {
    super();
    elementRef.nativeElement.focus = () => this.textArea.nativeElement.focus();
  }
  onInputEntered(event: Event) {
    const target = event.target as HTMLInputElement;
    this.enter.emit(target.value);

    if (this.onEnterBlur) {
      target.blur();
      this.value = this.value.trim();
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const textarea = event.target as HTMLTextAreaElement;

    if (this.hasPrefix && textarea.selectionStart <= this.prefixName?.length) {
      this.deletePrefix.emit();
    }
  }
}
