import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWrapperWithChangeDetection } from '@locumsnest/components/src/lib/core/input-wrapper';

@Component({
  selector: 'locumsnest-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent
  extends InputWrapperWithChangeDetection<boolean>
  implements ControlValueAccessor
{
  @Input() isDisabled: boolean;
  @Input() isFormControl = false;
  @Input() fieldName = 'toggle'; // dom field name to use in case of traditional form submit
  @Input() isAlert = false;
  @Input({ transform: booleanAttribute }) darkTheme = false;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  isPressed = false;

  onMouseDown() {
    this.isPressed = true;
  }

  onMouseUp() {
    this.isPressed = false;
  }

  onClicked() {
    if (!this.isDisabled) {
      if (this.isFormControl) {
        this.value = !this.value;
        this.toggled.emit(this.value);
        return;
      }

      this.toggled.emit(!this.value);
    }
  }
}
