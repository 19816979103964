import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControlState } from 'ngrx-forms';

import { ISelectOption } from '../../interfaces';

@Component({
  selector: 'locumsnest-rate-violations-reason-select',
  templateUrl: './rate-violations-reason-select.component.html',
  styleUrls: ['./rate-violations-reason-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RateViolationsReasonSelectComponent {
  @Input() rateViolationReasonOptions: ISelectOption<number>[];
  @Input() rateViolationsReasonFormControl: FormControlState<number>;
  @Input() isDisabled = false;
  @Input() isTimesheet = false;
  @Input() isAdhoc = false;
}
