import { Component, ContentChild, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'locumsnest-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @ContentChild('footer', {}) footer: ElementRef;
  @Input() show: boolean;
  @Input() title: string;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  closeModal() {
    this.close.emit();
  }
}
