/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, forwardRef, Injectable, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DateTime } from '@locumsnest/core/src/lib/helpers/date-time';

import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';

const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10),
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
  }
}

@Component({
  selector: 'locumsnest-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
  ],
})
export class TimeFieldComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  @Input() disabled: boolean;
  @Output() timeChange = new EventEmitter<Event>();

  writeTime(time: string) {
    if (time) {
      this.writeValue(time);
    }
  }

  get time() {
    return DateTime.getTimeFromPartialISOString(this.value);
  }

  set time(time: string) {
    const newTime = Time.getMoment(time, 'hh:mm');

    if (time?.length > 4 && newTime.isValid()) {
      this.writeTime(newTime.format('HH:mm'));
    } else {
      this.writeTime(time);
    }
  }

  public onTimeChange(event: Event) {
    this.timeChange.emit(event);
  }
}
