import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'ln-tag',
  standalone: true,
  imports: [NgClass, IconComponent],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent<T> {
  #hasWarning = false;

  @Input() value: T | null = null;
  @HostBinding('attr.tabIndex') @Input() tabIndex = 0;
  @HostBinding('class.isSelected') protected isSelected = false;
  @Input()
  @HostBinding('class.hasWarning')
  get hasWarning() {
    return this.isSelected && this.#hasWarning;
  }
  set hasWarning(hasWarning: boolean) {
    this.#hasWarning = hasWarning;
  }
  @Input() @HostBinding('class.deleteMode') deleteMode = false;
  @Input() isNew = false;
  @Output() selected = new EventEmitter<TagComponent<T>>();

  private cd = inject(ChangeDetectorRef);

  @HostListener('click')
  protected select() {
    this.setSelected();
    this.selected.emit(this);
  }

  deselect() {
    this.isSelected = false;
    this.cd.markForCheck();
  }

  setSelected() {
    this.isSelected = true;
    this.cd.markForCheck();
  }
}
