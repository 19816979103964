import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfiguration } from 'apps/hospital-admin/src/app/interfaces/api/configuration-entity';

import { StatusComponent } from '../../atoms/status/status.component';

@Component({
  selector: 'ln-configuration-card',
  templateUrl: './configuration-card.component.html',
  styleUrls: ['./configuration-card.component.scss'],
  standalone: true,
  imports: [StatusComponent, NgClass],
})
export class ConfigurationCardComponent {
  @Input() configurationDetails: IConfiguration;
  @Input() outline = false;

  @Output() configurationCardClicked = new EventEmitter<void>();

  public onConfigurationCardClicked() {
    this.configurationCardClicked.emit();
  }
}
