<ng-container *ngIf="documentList?.length === 0">
  <div class="document-row">No Available Documents</div>
</ng-container>
<ng-template
  class="document-row"
  ngFor
  let-doc
  let-i="index"
  [ngForOf]="documentList"
  [ngForTrackBy]="trackByDocument"
>
  <div class="document-row">
    <span title="{{ doc.display }}">{{
      doc.display.length > 20 ? (doc.display | slice: 0 : 20) + '...' : doc.display
    }}</span>
    <span *ngIf="showExpirationDate" class="date-icon">
      <locumsnest-status-indicator
        [status]="getStatusIndicator(doc.expirationDate)"
        [size]="'xsmall'"
      ></locumsnest-status-indicator>
    </span>
    <div *ngIf="showExpirationDate" class="document-row--expirationDate">
      <span *ngIf="doc.expirationDate === null">
        <locumsnest-date-picker
          class="date-picker"
          [placeholder]="'Expiry date'"
          [showCalendarIcon]="true"
          [extraClass]="'hideInputField'"
          [showConfirmButton]="true"
          [confirmButtonText]="'Update'"
          [datePickerLabelText]="'never'"
          (confirmButtonClicked)="onConfirmButtonClick($event, doc)"
        ></locumsnest-date-picker>
      </span>
      <span
        *ngIf="getStatusIndicator(doc.expirationDate) === 'alert' && canChangeDocument"
        class="renew"
      >
        <span class="renew">
          <locumsnest-date-picker
            class="date-picker"
            [placeholder]="'Expiry date'"
            [showCalendarIcon]="true"
            [extraClass]="'hideInputField'"
            [showConfirmButton]="true"
            [confirmButtonText]="'Renew'"
            [datePickerLabelText]="'Renew'"
            [showResetButton]="true"
            (confirmButtonClicked)="onConfirmButtonClick($event, doc)"
            (resetButtonClicked)="onResetButtonClick(doc)"
          ></locumsnest-date-picker>
        </span>
      </span>
      <span
        *ngIf="
          (getStatusIndicator(doc.expirationDate) === 'alert' && !canChangeDocument) ||
          (doc.expirationDate !== null &&
            (getStatusIndicator(doc.expirationDate) === 'ok' ||
              getStatusIndicator(doc.expirationDate) === 'warning'))
        "
      >
        <locumsnest-date-picker
          class="date-picker"
          [placeholder]="'Expiry date'"
          [showCalendarIcon]="true"
          [extraClass]="'hideInputField'"
          [showConfirmButton]="true"
          [showResetButton]="true"
          [confirmButtonText]="'Update'"
          [datePickerLabelText]="formatDate(doc.expirationDate)"
          (confirmButtonClicked)="onConfirmButtonClick($event, doc)"
          (resetButtonClicked)="onResetButtonClick(doc)"
        ></locumsnest-date-picker>
      </span>
    </div>
    <div *ngIf="doc.ownerName" class="document-row--owner">
      {{ doc.ownerName }}
    </div>
    <div *ngIf="isCertificate(doc)" class="document-row--certificate">
      <a href="{{ doc.url }}" target="_blank">View File</a>
    </div>
    <div *ngIf="isReference(doc)" class="document-row--reference">
      <a href="javascript:void(0)" (click)="openModal(doc)">View</a>
    </div>
    <div *ngIf="allowFileCopy && !doc.isCopied" class="document-row--copy">
      <a href="javascript:void(0)" (click)="onCopyButtonClick(doc)">Copy to safebox</a>
    </div>
    <div *ngIf="allowFileCopy && doc.isCopied" class="document-row--copy">Copied to safebox</div>
    <div
      *ngIf="showExpirationDate && canDeleteDocument"
      class="document-row--delete"
      (click)="onDeleteButtonClick(doc)"
    ></div>
  </div>
</ng-template>

<locumsnest-dialog [show]="showModal" [title]="selectedDoc?.display" (close)="closeModal()">
  <ng-container body>
    <div *ngIf="selectedDoc?.original.refereeName && selectedDoc?.original.refereeName !== ''">
      <b>Referee:</b> {{ selectedDoc.original.refereeName }}
    </div>
    <p></p>
    <ng-container *ngIf="selectedDoc && selectedDoc.text !== ''; else noText">
      <p>{{ selectedDoc.text }}</p>
    </ng-container>
  </ng-container>
</locumsnest-dialog>

<ng-template #noText>
  <p>No description available</p>
</ng-template>
