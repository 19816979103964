<div class="es" [ngClass]="{ expanded: isExpanded, expandable: !isReadOnly }">
  <div class="es-line" (click)="onRowClick()">
    <locumsnest-section-title
      [title]="title"
      [titleType]="'h2'"
      [underLineSize]="'no-line'"
    ></locumsnest-section-title>
    <div class="es-right">
      <span class="es-arrow" *ngIf="!isReadOnly"></span>
    </div>
  </div>
  <div class="es-content"><ng-content select="[content]"></ng-content></div>
</div>
