<ng-template>
  <div @parent>
    <ng-container *ngIf="active === true; else inactive">
      <div
        id="{{ stepNumber }}"
        class="card-wizard-step"
        @enter
        (@enter.done)="onAnimationEvent($event)"
      >
        <div *ngIf="showFullCard" class="main">
          <div *ngIf="showLeftIcon" class="icon-column">
            <ng-content select="[left-icon]"> </ng-content>
          </div>
          <ng-content select="[main]"> </ng-content>
        </div>
        <div class="card-wizard-step__left-part">
          <div class="card-wizard-step__left-part__content">
            <div *ngIf="showLeftTitle" class="row">
              <div class="col-sm-12">
                <label *ngIf="showStepNumber" class="title title-number"> {{ stepNumber }}. </label>
                <label class="title">{{ leftTitle }}</label>
              </div>
            </div>
            <div class="middle">
              <ng-content select="[left]"></ng-content>
              <ng-content select="[warnings]"></ng-content>
            </div>
            <div *ngIf="showButton" class="button-container">
              <locumsnest-button
                *ngIf="showCancelButton"
                [buttonType]="'cancel'"
                [hasBoldFont]="true"
                [size]="'medium'"
                (click)="onCancelButtonClick()"
              >
                {{ backButtonText }}
              </locumsnest-button>
              <div
                tabindex="{{
                  isCurrentSectionInvalid || disableNextButton ? nextButtonTabIndex : -1
                }}"
                (focusout)="onNextFocus($event)"
                (mousedown)="onNextMouseDown()"
                (mouseover)="onNextMouseOver()"
              >
                <locumsnest-button
                  tabindex="{{
                    isCurrentSectionInvalid || disableNextButton ? -1 : nextButtonTabIndex
                  }}"
                  [buttonType]="'onboarding-next'"
                  [hasBoldFont]="true"
                  [size]="'medium'"
                  [disabled]="isCurrentSectionInvalid || disableNextButton"
                  (click)="onGoToNextCard('')"
                >
                  {{ buttonText }}
                </locumsnest-button>
              </div>

              <ng-content select="[extraButtons]"></ng-content>
            </div>
          </div>
        </div>
        <div class="card-wizard-step__right-part">
          <div *ngIf="showRightTitle">
            <div class="card-wizard-step__right-part__title">
              {{ rightTitle }}
            </div>
          </div>
          <div class="card-wizard-step__right-part__body scroller">
            <ng-content select="[right]"></ng-content>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #inactive>
    <div
      *ngIf="completed === true; else incomplete"
      class="completed"
      (click)="onCardClick(stepNumber)"
    >
      <ng-content select="[completedCard]"></ng-content>
    </div>
    <div
      *ngIf="showDeleteButton && completed === true"
      class="bin"
      (click)="onRemoveQuestionButtonClicked(cardId)"
    ></div>
  </ng-template>

  <ng-template #incomplete>
    <ng-content select="[inCompletedCard]"></ng-content>
  </ng-template>
</ng-template>
