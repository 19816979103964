import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IProfileNote } from 'apps/hospital-admin/src/app/interfaces/api/profile-note-entity';
import { FormGroupState } from 'ngrx-forms';

import { LocumsNestComponent } from '../../core/locums-nest-component';
import { INoteComponentState } from '../../interfaces/note';

@Component({
  selector: 'locumsnest-notes-component',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent extends LocumsNestComponent {
  @Input() title: string;
  @Input() info: string;
  @Input() notes: IProfileNote[];
  @Input() noteFormState: FormGroupState<INoteComponentState>;
  @Input() displayNotesArea = false;
  @Input() showFlaggedNotes: boolean;

  @Output() submitNewNote: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteButtonClick: EventEmitter<IProfileNote> = new EventEmitter<IProfileNote>();
  @Output() flagButtonClick: EventEmitter<IProfileNote> = new EventEmitter<IProfileNote>();
  @Output() toggleFlaggedNotes = new EventEmitter<void>();
  @Output() loadMore = new EventEmitter();

  get isValid() {
    return this.noteFormState.isValid;
  }

  public onSubmitNewNote(note: string) {
    if (this.isValid) this.submitNewNote.emit(note);
  }

  public onDeleteButtonClick(profileNote: IProfileNote): void {
    this.deleteButtonClick.emit(profileNote);
  }

  public onFlagButtonClick(profileNote: IProfileNote): void {
    this.flagButtonClick.emit(profileNote);
  }

  public onToggleFlaggedNotes(event) {
    this.toggleFlaggedNotes.emit(event);
  }

  onScroll() {
    this.loadMore.emit();
  }
}
