<div
  class="delete-icon"
  [ngClass]="{ hidden: !showDeleteIcon }"
  (click)="onDeleteListItemClicked()"
>
  &#x2A2F;
</div>

<div class="middle">
  <ng-container *ngTemplateOutlet="mainRef" />
</div>

<div *ngIf="!disabled" class="right">
  <ng-container *ngTemplateOutlet="actionButtonRef" />
</div>
