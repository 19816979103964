<div class="shadow-collapse-container" [ngClass]="{ 'pb-2': isExpanded }">
  <div class="header">
    <div class="title" [ngClass]="{ 'placeholder-glow': loading }">
      <span [ngClass]="{ placeholder: loading, 'placeholder-width': loading }">{{ title }} </span>
    </div>

    <div style="display: flex; align-items: center">
      @if (!loading) {
        <ng-content select="[pagination]" />
      }
      <div class="" [ngClass]="{ 'placeholder-glow': loading }">
        <div
          class="collapsed"
          [ngClass]="{ expanded: isExpanded, placeholder: loading }"
          (click)="onCollapseButtonClicked()"
        ></div>
      </div>
    </div>
  </div>

  @if (isExpanded) {
    <div>
      @if (loading) {
        @for (item of placeholderItems; track item) {
          <div class="test placeholder-glow">
            <div class="placeholder avatar"></div>
            <div class="placeholder other"></div>
            <div class="placeholder other"></div>
            <div class="placeholder other"></div>
          </div>
        }
      } @else {
        <ng-content select="[body]" />
      }
    </div>
  }
</div>
