import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export const STATUS_CLASSES = {
  success: 'success-status',
  warning: 'warning-status',
  alert: 'alert-status',
  attention: 'attention-status',
} as const;

export type StatusType = keyof typeof STATUS_CLASSES;
export type StatusClasses = `${(typeof STATUS_CLASSES)[StatusType]}${' outline' | ''}`;

@Component({
  selector: 'ln-status',
  standalone: true,
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  @Input() statusType: StatusType = 'success';
  @Input() outline = false;

  @HostBinding('class') protected get statusTypeHostClass(): StatusClasses {
    return `${STATUS_CLASSES[this.statusType]}${this.outline ? ' outline' : ''}`;
  }
}
