<div class="card">
  <div class="card--title">{{ title.length > 45 ? (title | slice: 0:45) + '...' : title }}</div>
  <div *ngIf="!showDetails; else showDetailsCard">
    <div class="card--image">
      <img class="image" src="{{ image }}" />
      <div *ngIf="activated" class="card--icon"></div>
    </div>
    <div class="card--active">
      <div *ngIf="activated; else deactivated">Link activated</div>
    </div>
  </div>
  <div class="card--info" (click)="onInfoClicked()"></div>
</div>

<ng-template #showDetailsCard>
  <div class="details">
    <div
      class="scroller"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
    >
      <div class="description">
        {{ description }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deactivated> Link available </ng-template>
