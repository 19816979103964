import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

import {
  ITempFileUploadService,
  ITempUploadEndpointConfig,
  TempFileUploadResponse,
} from './interfaces';

@Injectable()
export class TempFileUploadService
  extends HttpApiPersistenceService<ITempUploadEndpointConfig, TempFileUploadResponse>
  implements ITempFileUploadService
{
  protected readonly endpoint = 'tempUpload';
  upload(file: File) {
    return this.create<File, HttpEvent<TempFileUploadResponse>, any>(file, null, {
      reportProgress: true,
      observe: 'events',
      headers: {
        'Content-Disposition': `attachment; filename=${file.name}`,
        'Content-Type': file.type,
      },
    });
  }

  deleteFile(_: string): Observable<void> {
    throw new Error('delete file not implemented');
  }
}
