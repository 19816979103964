import { NgClass } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWrapperWithChangeDetection } from '@locumsnest/components/src/lib/core/input-wrapper';

type Theme = 'green' | 'blue' | 'blue-cross' | 'green-rounded';
type Size = 'normal' | 'small' | 'medium' | 'big';

@Component({
  selector: 'locumsnest-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  standalone: true,
  imports: [NgClass, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true,
    },
  ],
})
export class CheckBoxComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  @Input() idx: string;
  @Input() displayText: string;
  @Input() isRequired: boolean;
  @Input() selected: boolean;
  @Input() subtitle: string;
  @Input() isDisabled: boolean;
  @Input() size: Size = 'big';
  @Input() theme: Theme;
  @Input() isRounded = false;
  @Input() isButton = false;
  @Input() opaque = false;
  @Input() stillClickable = false; //MD: still clickable when is disable
  @Input() errorMsg: string;
  @Output() checked = new EventEmitter<Event>();

  onChecked(selected: Event) {
    this.checked.emit(selected);
  }
}
