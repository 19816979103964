import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type StatusType = 'error' | 'info' | 'success';

@Component({
  selector: 'locumsnest-status-progress-bar',
  templateUrl: './status-progress-bar.component.html',
  styleUrls: ['./status-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusProgressBarComponent {
  @Input() status: string;
  @Input() percentage: number;
  @Input() statusType: StatusType = 'success';
}
