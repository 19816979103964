import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'locumsnest-addition-list-item',
  templateUrl: './addition-list-item.component.html',
  styleUrls: ['./addition-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionListItemComponent {
  @Input() showDeleteIcon = false;
  @Input() disabled = false;

  @Output() deleteListItemClicked = new EventEmitter<void>();
  @Output() actionIconClicked = new EventEmitter<void>();

  @ContentChild('main') mainRef: TemplateRef<unknown>;
  @ContentChild('actionButton') actionButtonRef: TemplateRef<unknown>;

  public onDeleteListItemClicked() {
    this.deleteListItemClicked.emit();
  }

  public onActionIconClicked() {
    this.actionIconClicked.emit();
  }
}
