<span
  *ngIf="showVaccinationStatus"
  class="vaccination-status"
  [ngClass]="{
    done: vaccinationStatus === true,
    none: vaccinationStatus === false,
    pending: vaccinationStatus === null
  }"
></span>
<div class="avatar" [ngClass]="{ disabled: disabled }">
  <span class="locums-avatar"></span>
  <img *ngIf="photoUrl" onerror="this.style.display='none'" [src]="photoUrl" />
</div>
<span
  *ngIf="showStatus"
  class="status"
  [ngClass]="{
    success: statusFont === 'success',
    warning: statusFont === 'warning',
    error: statusFont === 'error',
    cascade: statusFont === 'cascade'
  }"
></span>
<div *ngIf="showAssignmentNumberWarning" class="assignment-number-warning">
  <div class="assignment-number-warning--bell" tp="Missing Assignment Number"></div>
</div>
<a *ngIf="isHyperlink; else profile" class="isHyperlink" [routerLink]="['/profile/' + profileId]">
  <span class="profile-name">{{ name }}</span>
</a>
<ng-template #profile>
  {{ name }}
</ng-template>
