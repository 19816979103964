<div>
  <div class="shift-description-files-box">
    <div class="description-line-text">{{ description }}</div>
    <ng-container *ngIf="!(isPrimaryCare === true && vacancyReasons?.length === 1)">
      <div class="description-line-text">{{ reasonForVacancy }}</div>
    </ng-container>
    <div class="description-line-text">{{ costCentreNumber }}</div>
    <div>
      <div class="file-img" *ngIf="fileName" (click)="downloadFile()"></div>
      <div class="description-line-text file-name">{{ fileName }}</div>
    </div>
  </div>
</div>
