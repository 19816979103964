import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { IFilterValues } from 'apps/hospital-admin/src/app/interfaces/api/filter-views-entity';
import { upperFirst } from 'lodash-es';
import { map } from 'rxjs';

import { PaginatedStateService } from '@locumsnest/core/src';

import { HOME_NAME } from '.';
import { PAGE_INDEX } from './filter-views.adapter';
import {
  DeleteOneMessage,
  paginationMessages,
  ResetFilterViewsPaginationMessage,
  UpdateOneMessage,
  UpsertFilterViewsPageMessage,
  UpsertMultipleMessage,
  UpsertOneMessage,
} from './filter-views.messages';
import { FilterViewsPersistenceService } from './filter-views.persistence.service';
import {
  filterViewsPaginationSelectors,
  selectAllFilters,
  selectFilterViewsEntityState,
} from './filter-views.selectors';
import { IFilterViews } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class FilterViewsService extends PaginatedStateService<
  IFilterViews,
  UpsertFilterViewsPageMessage,
  ResetFilterViewsPaginationMessage,
  UpsertMultipleMessage
> {
  protected persistenceService = inject(FilterViewsPersistenceService);

  get paginationMessages() {
    return paginationMessages;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return filterViewsPaginationSelectors;
  }

  get entityStateSelector() {
    return selectFilterViewsEntityState;
  }

  getMaxTabLength(): number {
    return 6;
  }

  addHomeTab(filters: Partial<IFilterValues>, page: string) {
    return this.addTab(upperFirst(HOME_NAME), filters, page);
  }

  addTab(name: string, filters: Partial<IFilterValues>, page: string) {
    return this.persistenceService
      .create<any, IFilterViews>({ filters, name, page })
      .pipe(map((entity) => new UpsertOneMessage({ entity })));
  }

  updateTab(tabId: string | number, name: string) {
    return this.persistenceService
      .patch<IFilterViews>(tabId, { name })
      .pipe(map(({ id, ...changes }) => new UpdateOneMessage({ entity: { id, changes } })));
  }

  saveActiveTab(tabId: string | number, filters: Partial<IFilterValues>) {
    return this.persistenceService
      .patch<IFilterViews>(tabId, { filters })
      .pipe(map(({ id, ...changes }) => new UpdateOneMessage({ entity: { id, changes } })));
  }

  removeOne(id: string | number) {
    return this.persistenceService.delete(id).pipe(map(() => new DeleteOneMessage({ id: +id })));
  }

  fetch() {
    return this.loadAllPages('default', {});
  }

  override getAll() {
    return this.store.pipe(select(selectAllFilters));
  }

  getHomeFilter(page: string) {
    return this.getAllByPage(page).pipe(
      map((filters) => filters.find((filter) => filter.name.toLowerCase() === HOME_NAME) || null),
    );
  }

  getAllByPage(page: string) {
    return this.getMultipleOrEmptyByIndex(PAGE_INDEX, page);
  }
}
