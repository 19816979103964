import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-shadow-collapse-container',
  templateUrl: './shadow-collapse-container.component.html',
  styleUrls: ['./shadow-collapse-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShadowCollapseContainerComponent {
  @Input() title: string;
  @Input() loading = false;

  public isExpanded = true;
  public readonly placeholderItems = [1, 2, 3];

  onCollapseButtonClicked() {
    this.isExpanded = !this.isExpanded;
  }
}
