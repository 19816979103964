<div class="radio-button-group" [ngClass]="{ 'is-Vertical': isVertical }">
  @for (option of radioButtons; track option.id) {
    <div class="radio-button">
      <locumsnest-radio-button
        [displayText]="option.name"
        [value]="option.id"
        [isDisabled]="option.disabled || isDisabled"
        [checked]="isSelected(option)"
        [smaller]="smaller"
        [darkTheme]="darkTheme"
        [hideDot]="hideDot"
        (selected)="onSelectionChange($event)"
      >
        <ng-container [ngTemplateOutlet]="option.content" />
      </locumsnest-radio-button>
    </div>
  }
</div>
