/* eslint-disable @angular-eslint/no-output-native */

import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil, isNumber } from 'lodash-es';
import moment from 'moment-timezone';

import { InputWrapperWithChangeDetection } from '@locumsnest/components/src/lib/core/input-wrapper';
import { Time } from '@locumsnest/core/src/lib/helpers';
import { isNumeric } from '@locumsnest/core/src/lib/helpers/util';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'locumsnest-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  standalone: true,
  imports: [NgClass, FormsModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true,
    },
  ],
})
export class InputFieldComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  @HostBinding('class.input--date') get isDate() {
    return this.type === 'date';
  }

  @HostBinding('class.input--time') get isTime() {
    return this.type === 'time';
  }

  get inputText() {
    if (this.timestampFormat && isNumber(this.value)) {
      return Time.formatDate(+this.value, this.timestampFormat);
    }
    return this.value;
  }

  set inputText(val) {
    if (this.timestampFormat) {
      val = this.guardTimestampInput(val);
      if (isNil(val)) return;
    }

    this.value = val;
  }

  get value() {
    if (this.type === 'number' && isNumeric(this._value) && !this.preserveDecimalPlaces) {
      return +this._value;
    }

    return this._value;
  }

  set value(value) {
    if (this.type === 'number' && typeof value === 'string') {
      value = +value;
    }

    if (this.isNewValue(value)) {
      this._value = value;
      this.propagateChange(this.value);
    }
  }

  private static readonly RESET_TIMEOUT = 2500;

  @Input() placeholder: string | number;
  @Input() @HostBinding('class.input--error') isInvalid: boolean;
  @Input() @HostBinding('class.input--required') isRequired: boolean;
  @Input() @HostBinding('class.input--date') showCalendarIconInInput: boolean;
  @Input() @HostBinding('class.input--invert-colours') invertColours = false;
  @Input() type: 'text' | 'number' | 'password' | 'date' | 'datetime-local' | 'time';
  @Input() disabled: boolean;
  @Input() idx: string;
  @Input() readonly: boolean;
  @Input() step: number;
  @Input() preserveDecimalPlaces = false;
  @Input() min: number;
  @Input() max: number;
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() suffix = '';
  @Input() hasPrefix = false;
  @Input() extraClass: string;
  @Input({ transform: booleanAttribute }) hasBorder = false;
  @Input({ transform: booleanAttribute }) hideBorder = false; //to hide the border bottom
  @Input({ transform: booleanAttribute }) borderNoShadow = false;
  @Input() transparentTheme = false;
  @Input() keepColorsOnDisable: boolean;
  /*  eslint-disable max-len */
  /**
   * alternative to setting focus when field is disabled
   * mitigating w3c specification conflict with the need
   * to style as focussed when disabled (to emulate focus on
   * molecules that use this disabled) we also take this
   * into account in css
   * https://www.w3.org/TR/html5/sec-forms.html
   * #enabling-and-disabling-form-controls-the-disabled-attribute
   */
  /*  eslint-enable max-len */
  @Input() errorMsg: string;
  @Input() active: boolean;
  @Input() styleBold = false;
  @Input() hasWarning = false;
  @Input() largerFont = false;
  @Input() regularFont = false;
  @Input() textAlignRight = false;
  // @todo put this in a timestamp directive
  @Input() timestampFormat: string;
  @Input() upperBoundary: number;
  @Input() lowerBoundary: number;
  @Input() index: string;
  @Input() noUnderline = false;
  @Input() isAlert = false;
  @Input() name = '';
  @Input() isCommand = false;
  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() focus = new EventEmitter<Event>();
  @Output() change = new EventEmitter<Event>();
  @Output() blur = new EventEmitter<Event>();
  @Output() inputEntered = new EventEmitter<Event>();
  @Output() inputChanged = new EventEmitter<Event>();
  @Output() reset = new EventEmitter<void>();
  @HostBinding('class.input') private readonly inputClass = true;

  private timerForKeyup;
  private timeoutHandle;
  private renderer = inject(Renderer2);

  resetValue(value) {
    this.timeoutHandle = setTimeout(() => {
      this.renderer.addClass(this.input.nativeElement, 'shake');

      setTimeout(() => {
        this.renderer.removeClass(this.input.nativeElement, 'shake');
      }, 500);

      this.input.nativeElement.value = value;

      this.reset.emit();
    }, InputFieldComponent.RESET_TIMEOUT);
  }

  cancelValueReset() {
    clearTimeout(this.timeoutHandle);
  }

  guardTimestampInput(val) {
    this.cancelValueReset();
    if (!moment(val, 'HH:mm', true).isValid()) {
      this.resetValue(this.inputText);
      return;
    }
    val = Time.next(this.lowerBoundary, val);
    if (val >= this.upperBoundary) {
      this.resetValue(this.inputText);
      return;
    }
    return val;
  }

  onFocus(event: Event) {
    this.focus.emit(event);
  }

  onChange(event: Event) {
    this.change.emit(event);
  }

  onClick(_: Event) {
    clearInterval(this.timerForKeyup);
    this.timerForKeyup = setTimeout(() => {
      this.inputChanged.emit(event);
    }, 1000);
  }

  onInputEntered(event: Event) {
    this.inputEntered.emit(event);
  }

  onBlur(event: Event) {
    this.blur.emit(event);
  }
}
