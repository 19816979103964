import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { isNil } from 'lodash-es';
import moment from 'moment-timezone';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';
import { Calendar } from '@locumsnest/util/src';
import { ICalendarDays } from '@locumsnest/util/src/lib/calendar/interfaces';

import { LocumsNestComponent } from '../../core/locums-nest-component';
import { DateFormatFunction, ValidationResult } from '../../interfaces';
import { DatePipe } from '../../pipes/date/date.pipe';

@Component({
  selector: 'locumsnest-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('calendarAnimation', [
      transition('* => left', [
        animate(
          180,
          keyframes([
            style({ transform: 'translateX(105%)', offset: 0.5 }),
            style({ transform: 'translateX(-130%)', offset: 0.51 }),
            style({ transform: 'translateX(0)', offset: 1 }),
          ]),
        ),
      ]),
      transition('* => right', [
        animate(
          180,
          keyframes([
            style({ transform: 'translateX(-105%)', offset: 0.5 }),
            style({ transform: 'translateX(130%)', offset: 0.51 }),
            style({ transform: 'translateX(0)', offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class CalendarComponent extends LocumsNestComponent implements OnInit {
  @Input() idx: string;
  @Input()
  get dayNames(): string[] {
    return this._dayNames || Calendar.days;
  }
  set dayNames(dayNames: string[]) {
    this._dayNames = dayNames;
  }
  @Input()
  get weekStart(): number {
    return this._weekStart || 1;
  }
  set weekStart(weekStart: number) {
    this._weekStart = weekStart;
  }
  @Input() disabled: boolean;
  @Input() dateFormat: string | DateFormatFunction;
  @Input() rangeStart: Date;
  @Input() rangeEnd: Date;
  @Input() blackList: Date[];
  // time
  @Input() calendarDays: ICalendarDays[];
  @Input() months: string[];
  @Input() dates: Date[] = [];
  @Input() highlightedDate: Date;
  @Input()
  get serializedDates() {
    return this.dates.map((d) => d.toDateString());
  }
  @Input() @HostBinding('class.calendar--themed') themed: boolean;
  @Input() canSelectPastDate = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<Date>();
  @HostBinding('class') classList = 'calendar';

  dayNamesOrdered: string[];
  calendar: Calendar;
  currentYear: number;
  // animation
  animate: string;
  // forms
  yearControl: UntypedFormControl;
  set currentMonthNumber(month: number) {
    this._currentMonthNumber = month;
    this.updateCalendarDays();
  }
  get currentMonthNumber() {
    return this._currentMonthNumber;
  }

  get currentMonth(): string {
    return Calendar.months[this.currentMonthNumber];
  }

  private _dayNames: string[];
  private _weekStart: number;
  private _currentMonthNumber: number;

  constructor() {
    super();
    // form controls
    this.yearControl = new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(4),
        this.yearValidator,
        this.inRangeValidator.bind(this),
      ]),
    );
  }

  /**
   * Visually syncs calendar and input to selected date or current day
   */

  /**
   * Sets the currentMonth and creates new calendar days for the given month
   */
  updateCalendarDays(): void {
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);
    this.calendarDays = [].concat(...calendarArray);

    this.calendarDays = this.filterInvalidDays(this.calendarDays);
  }
  // -------------------------------------------------------------------------------- //
  // --------------------------------- Click Handlers ------------------------------- //
  // -------------------------------------------------------------------------------- //
  /**
   * Sets the date values associated with the calendar.
   * Triggers animation if the month changes
   */
  onMonthClick(direction: string): void {
    const currentMonth: number = this.currentMonthNumber;
    let newYear: number = this.currentYear;
    let newMonth: number;
    // sets the newMonth
    // changes newYear is necessary
    if (direction === 'left') {
      if (currentMonth === 0) {
        newYear = this.currentYear - 1;
        newMonth = 11;
      } else {
        newMonth = currentMonth - 1;
      }
    } else if (direction === 'right') {
      if (currentMonth === 11) {
        newYear = this.currentYear + 1;
        newMonth = 0;
      } else {
        newMonth = currentMonth + 1;
      }
    }
    // check if new date would be within range
    const newDate = moment([newYear, newMonth]).toDate();
    let newDateValid: boolean;
    if (direction === 'left') {
      newDateValid = !this.rangeStart || newDate.getTime() >= this.rangeStart.getTime();
    } else if (direction === 'right') {
      newDateValid = !this.rangeEnd || newDate.getTime() <= this.rangeEnd.getTime();
    }

    if (newDateValid) {
      this.currentYear = newYear;
      this.currentMonthNumber = newMonth;
      this.triggerAnimation(direction);
    }
  }

  /**
   * Check if a date is within the range.
   * @param date The date to check.
   * @return true if the date is within the range, false if not.
   */
  isDateValid(date: Date): boolean {
    return (
      (!this.rangeStart || date.getTime() >= this.rangeStart.getTime()) &&
      (!this.rangeEnd || date.getTime() <= this.rangeEnd.getTime())
    );
  }

  isBlackListed(date: Date): boolean {
    if (!this.blackList) {
      return false;
    }
    return this.blackList.map((d) => +moment(d).startOf('day')).indexOf(+date) > -1;
  }

  /**
   * Check if a date is within the range.
   * @param date The date to check.
   * @return true if the date is within the range, false if not.
   */
  isHighlightedDay(date: ICalendarDays): boolean {
    if (isNil(this.highlightedDate)) {
      return this.dates.length === 1 && this.isChosenDay(date);
    }
    return +date === +moment(this.highlightedDate).startOf('day');
  }

  /**
   * Filter out the days that are not in the date range.
   * @param calendarDays The calendar days
   * @return {Array} The input with the invalid days replaced by 0
   */
  filterInvalidDays(calendarDays: ICalendarDays[]): ICalendarDays[] {
    const newCalendarDays: ICalendarDays[] = [];
    calendarDays.forEach((day) => {
      if (!day || !this.isDateValid(day)) {
        newCalendarDays.push(0);
      } else {
        newCalendarDays.push(day);
      }
    });
    return newCalendarDays;
  }

  /**
   * Returns the font color for a day
   */
  onSelectDay(day: ICalendarDays): void {
    if (day && this.isDateValid(day) && !this.isBlackListed(day)) {
      this.select.emit(day);
    }
  }

  isPastDate(day: ICalendarDays): boolean {
    return !this.canSelectPastDate && !(+day > +Time.getMoment().startOf('day') - 1);
  }

  /**
   * Returns whether a day is the chosen day
   */
  isChosenDay(day: ICalendarDays): boolean {
    return day && this.serializedDates.indexOf(day.toDateString()) > -1;
  }

  /**
   * Returns whether a day is the current calendar day
   */
  isCurrentDay(day: ICalendarDays): boolean {
    return day && +moment(day).startOf('day') === +Time.getMoment().startOf('day');
  }

  /**
   * Triggers an animation and resets to initial state after the duration of the animation
   */
  triggerAnimation(direction: string): void {
    this.animate = direction;
    setTimeout(() => (this.animate = 'reset'), 185);
  }

  // -------------------------------------------------------------------------------- //
  // ---------------------------------- Validators ---------------------------------- //
  // -------------------------------------------------------------------------------- //
  /**
   * Validates that a value is within the 'rangeStart' and/or 'rangeEnd' if specified
   */
  inRangeValidator(control: UntypedFormControl): ValidationResult {
    const value = control.value;

    if (this.currentMonthNumber) {
      const tentativeDate = moment([+value, this.currentMonthNumber]).toDate();
      if (this.rangeStart && tentativeDate.getTime() < this.rangeStart.getTime()) {
        return { yearBeforeRangeStart: true };
      }
      if (this.rangeEnd && tentativeDate.getTime() > this.rangeEnd.getTime()) {
        return { yearAfterRangeEnd: true };
      }
      return null;
    }

    return { currentMonthMissing: true };
  }

  /**
   * Validates that a value is a number greater than or equal to 1970
   */
  yearValidator(control: UntypedFormControl): ValidationResult {
    const value = control.value;
    const valid = !isNaN(value) && value >= 1970 && Math.floor(value) === +value;
    if (valid) {
      return null;
    }
    return { invalidYear: true };
  }
  orderDays() {
    this.dayNamesOrdered = this.dayNames.slice();
    if (this.weekStart < 0 || this.weekStart >= this.dayNamesOrdered.length) {
      // Out of range
      throw Error(
        `The weekStart is not in range between ${0} and ${this.dayNamesOrdered.length - 1}`,
      );
    } else {
      this.calendar = new Calendar(this.weekStart);
      this.dayNamesOrdered = this.dayNamesOrdered
        .slice(this.weekStart, this.dayNamesOrdered.length)
        .concat(this.dayNamesOrdered.slice(0, this.weekStart)); // Append beginning to end
    }
  }
  getDefaultDate() {
    const selectedDate = moment(this.dates[0], DATE_FORMAT);
    return selectedDate.isValid() ? selectedDate.toDate() : Time.getDate();
  }
  ngOnInit() {
    this.orderDays();
    const defaultDate = this.getDefaultDate();
    this.currentYear = defaultDate.getFullYear();
    this.currentMonthNumber = defaultDate.getMonth();
    const calendarArray = this.calendar.monthDays(this.currentYear, this.currentMonthNumber);

    this.calendarDays = [].concat(...calendarArray);
    this.calendarDays = this.filterInvalidDays(this.calendarDays);
    // Copy DayNames with default value (weekStart = 0)
  }
}
