import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'locumsnest-form-group-list-item',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  styleUrls: [],
})
export class FormGroupListItemComponent {
  @Input() id!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;
}

@Component({
  selector: 'locumsnest-form-group-list',
  templateUrl: './form-group-list.component.html',
  styleUrls: ['./form-group-list.component.scss'],
})
export class FormGroupListComponent {
  @Input() id: string;
  @Input() isReadOnly = false;
  @Input() isRemovable = true;
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @Input() buttonText = 'Add';
  @Input() showAddButton = true;
  @Input() showDeleteButton = true;
  @Input() removalWhiteList = [];
  @Input() showInline = false;

  @ContentChildren(FormGroupListItemComponent, { descendants: true })
  list: QueryList<FormGroupListItemComponent>;

  @Output() remove = new EventEmitter<string>();
  @Output() add = new EventEmitter<string>();

  constructor() {}

  onAdd(): void {
    this.add.emit(this.id);
  }

  onRemove(id: string): void {
    this.remove.emit(id);
  }

  trackByFn(index: number): number {
    return index;
  }
}
