import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { get } from 'lodash-es';

import { DistinctBehaviorSubject } from '@locumsnest/core/src/lib/rxjs';

@Component({
  selector: 'locumsnest-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent implements AfterViewChecked {
  @Input() active: boolean;
  @Input() complete = false;
  @Input() title: string;
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;
  @ViewChild('stepContainer') container: ElementRef<any>;
  @Output() stepSelected = new EventEmitter<void>();

  get containerEl() {
    return get(this.container, 'nativeElement');
  }

  private containerColor$: DistinctBehaviorSubject<string> = new DistinctBehaviorSubject<string>(
    ''
  );

  constructor() {}

  ngAfterViewChecked() {
    this.containerColor$.next(this.getContainerColor());
  }

  getContainerColor() {
    if (this.containerEl) {
      return window.getComputedStyle(this.containerEl).backgroundColor;
    }
    return '';
  }

  public onStepSelected() {
    this.stepSelected.emit();
  }
}
