<div
  ngfDrop
  selectable="1"
  [maxSize]="maxSize || defaultMaxSize"
  [accept]="acceptFormats"
  [fileDropDisabled]="disabled || showFileDetails"
  [ngClass]="{
    attachment: onlyIcon,
    attachment__disabled: onlyIcon && disabled,
    well: !onlyIcon,
    'my-drop-zone': !onlyIcon,
    compact: compact,
    'my-drop-zone--disabled': disabled,
    'blue-theme': blueTheme,
    'well--transparent': transparentTheme
  }"
  (fileChange)="onFileChanged($event)"
  (lastInvalidsChange)="invalidDrag($event)"
  [(files)]="files"
>
  @if (!onlyIcon) {
    @if (!showFileDetails) {
      <div class="upload-area">
        <locumsnest-icon
          class="upload-icon"
          iconName="upload-icon"
          width="23"
          height="25"
          [class.upload-icon__blue-theme]="blueTheme"
        />
        <div
          class="drag-and-drop-files"
          [ngClass]="{
            'drag-and-drop-files--compact': compact,
            'drag-and-drop-files__blue-theme': blueTheme
          }"
        >
          <div class="title">
            Drag and drop file here or
            <span class="text-style-1">Browse</span>
            here to upload from your computer
          </div>
          @if (acceptFormatsUi && acceptFormatsUi !== '*') {
            <div class="file_upload__formats">
              *accepted formats : {{ acceptFormatsUi }} <br />
              Maximum file size {{ getSizeDisplay(maxSize || defaultMaxSize) }}
            </div>
          }
          @if (!(acceptFormatsUi && acceptFormatsUi !== '*')) {
            <div class="file_upload__formats">
              <br />
              Maximum file size {{ getSizeDisplay(maxSize || defaultMaxSize) }}
            </div>
          }
        </div>
      </div>
    }
    @if (showFileDetails) {
      <div class="upload-area">
        <div style="float: left">
          <div
            class="noun_365895_cc"
            src="../../../assets/icons/image-upload.svg"
            [ngClass]="{ 'noun_365895_cc-compact': compact }"
          ></div>
        </div>
        <div class="drag-and-drop-files" [ngClass]="{ 'drag-and-drop-files--compact': compact }">
          <p>
            Please name your file and choose file type. Click
            <span class="text-style-1" (click)="onCancelBtnClicked($event)">here</span> to cancel
            upload
          </p>
        </div>
      </div>
    }
  }
</div>
@if (errorMessage !== '') {
  <div class="error-msg" [ngClass]="{ 'error-msg--compact': compact }">
    <p>{{ errorMessage }}</p>
  </div>
}

@if (controlTheme) {
  <div class="disclaimer">
    Deleting a document is a permanent action, and you won't be able to undo it.
  </div>
  <ln-file
    [tempFiles]="tempFiles"
    [files]="uploadedFiles"
    [isEditMode]="true"
    [theme]="'dark-grey'"
    (removeFile)="removeTempFile($event)"
    (deleteFile)="deleteFile($event)"
  />
} @else {
  <div class="image-preview">
    @for (item of tempFiles; track trackByToken(i, item); let i = $index) {
      <div class="image-preview__container">
        @if (!item.uploading) {
          @if (item.file | isFileImage) {
            <div class="image-preview__container__file" [ngfBackground]="item.file"></div>
          } @else {
            <div class="image-preview__container__file__not-image">
              <div class="noun_365895_cc" src="../../../assets/icons/image-upload.svg"></div>
              <locumsnest-ellipsis [title]="item.file.name" [text]="item.file.name" />
            </div>
          }
          <div class="image-preview__container__remove-icon" (click)="removeTempFile(i)"></div>
        }
        @if (item.uploading) {
          <div class="image-preview__container__file image-preview__container__file__loading">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        }
      </div>
    }
  </div>
}
