<label
  class="checkbox-box"
  [ngClass]="{
    required: isRequired,
    'checkbox-box--disabled': isDisabled,
    'checkbox-box--opaque': opaque,
    'checkbox-box--smaller': size === 'small',
    'checkbox-box--medium': size === 'medium',
    'checkbox-box--blue-cross': theme === 'blue-cross',
    'checkbox-box--blue': theme === 'blue',
    'checkbox-box--green': theme === 'green',
    'checkbox-box--normal': size === 'normal',
    clickable: stillClickable
  }"
>
  <input
    name="radio"
    type="checkbox"
    [id]="idx"
    [checked]="selected"
    [disabled]="isDisabled"
    (change)="onChecked($event)"
    [(ngModel)]="value"
  />

  <div
    id="{{ idx }}__display-text"
    class="displayedText"
    [ngClass]="{
      'displayedText--isButton': isButton,
      'displayedText--blue': theme === 'blue' && !isButton,
      'displayedText--opaque': opaque
    }"
  >
    {{ displayText }}
    <ng-content />
  </div>
  @if (subtitle && value === true && !isDisabled) {
    <div class="displayedSubText">- {{ subtitle }}</div>
  }
  <div
    class="checkmark"
    [ngClass]="{
      'checkmark--blue': theme === 'blue',
      'checkmark--green': theme === 'green',
      'checkmark--rounded': isRounded,
      'checkmark--isButton': isButton
    }"
  ></div>
  @if (errorMsg) {
    <small class="text-danger" [id]="idx + '__error-message'">
      {{ errorMsg }}
    </small>
  }
</label>
