/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { featureKey, reducer } from './tabs.adapter';

@NgModule({
  imports: [StoreModule.forFeature(featureKey, reducer)],
})
export class TabsStateModule {}
