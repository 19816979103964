@if (editMode) {
  <input
    class="edit-field"
    #tabNameInput
    locumsnestFocusable
    type="text"
    [value]="tabName"
    (keyup.enter)="onUpdateTabName(tabId, tabNameInput.value)"
    (blur)="onUpdateTabName(tabId, tabNameInput.value)"
  />
} @else {
  <span [tp]="tabName">{{ tabName | textTruncate }}</span>

  @if (homeTab) {
    <span class="home" [ngClass]="{ 'home-active': isActiveTab }">
      <locumsnest-icon iconName="house" width="10" height="10" />
    </span>
  } @else {
    <locumsnest-icon
      class="remove-tab"
      width="4"
      height="4"
      iconName="close"
      [ngClass]="{ active: isActiveTab }"
      (click)="onRemoveTab($event, tabId)"
    />
  }
}
