import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDetailsFile } from 'apps/hospital-admin/src/app/interfaces/api/trust-configuration-category-details-file-entity';

import { EllipsisComponent } from '../../atoms/ellipsis/ellipsis.component';
import { PipesModule } from '../../pipes/pipes.module';
import { ITempFile } from '../file-upload/interfaces';

type ThemeType = 'blue' | 'light-grey' | 'dark-grey';

@Component({
  selector: 'ln-file',
  standalone: true,
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  imports: [EllipsisComponent, NgClass, PipesModule],
})
export class FileComponent {
  @Input() tempFiles: ITempFile[] = [];
  @Input() files: IDetailsFile[] = [];
  @Input() theme: ThemeType = 'dark-grey';
  @Input() isEditMode: boolean;

  @Output()
  removeFile = new EventEmitter<number>();
  @Output() deleteFile = new EventEmitter<number>();

  public onRemoveFile(i: number) {
    this.removeFile.emit(i);
  }

  public onDeleteFile(i: number) {
    this.deleteFile.emit(i);
  }

  trackByToken(_: number, tempFile: ITempFile) {
    return tempFile.token;
  }
}
