import { Directive, inject, Input, TemplateRef } from '@angular/core';

import { TabId } from './+state';

@Directive({
  selector: '[locumsnestTab]',
  standalone: true,
})
export class TabDirective {
  @Input('locumsnestTab') name: string | TemplateRef<any>;
  @Input('locumsnestTabIsActive') isActive: boolean;
  @Input('locumsnestTabId') id: TabId;

  public template = inject(TemplateRef<any>);
}
