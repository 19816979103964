<div class="title">
  {{ title }}
</div>

<ng-content />

<div class="footer">
  <ng-content select="[footer]" />
  <div class="buttons">
    <locumsnest-button
      buttonType="warning-accept"
      size="medium"
      [idx]="idx + '__accept-button'"
      (click)="onWarningButtonPressed('warningAccept')"
    >
      {{ acceptButtonText }}
    </locumsnest-button>

    <locumsnest-button
      buttonType="warning-decline"
      size="medium"
      [idx]="idx + '__decline-button'"
      (click)="onWarningButtonPressed('warningDecline')"
    >
      {{ declineButtonText }}
    </locumsnest-button>
  </div>
</div>
