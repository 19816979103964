<div
  class="eis"
  [ngClass]="{ activated: value, isAlert: isAlert, expanded: isExpanded, expandable: !isReadOnly }"
>
  <div class="eis-line">
    <div class="eis-label">{{ label }}</div>
    <div class="eis-right">
      <locumsnest-toggle
        class="eis-toggle align-middle"
        *ngIf="!isReadOnly"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
        (toggled)="onValueChange($event)"
        [isAlert]="isAlert"
      ></locumsnest-toggle>
      <span class="eis-tick" *ngIf="isReadOnly"></span>
      <span class="eis-info"><ng-content select="[info]"></ng-content></span>
      <span class="eis-arrow" *ngIf="!isReadOnly" (click)="onArrowClick()"></span>
    </div>
  </div>
  <div class="eis-details"><ng-content select="[details]"></ng-content></div>
</div>
