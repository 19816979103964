import { Component, Input } from '@angular/core';

type SideImage = 'safebox' | 'passport' | 'staffbank-safebox';
type FontStyle = 'regular' | 'bold';

@Component({
  selector: 'locumsnest-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss'],
})
export class InfoBannerComponent {
  @Input() sideImage: SideImage = 'safebox';
  @Input() fontStyle: FontStyle = 'bold';

  constructor() {}
}
