import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUploadFileFormState } from 'apps/hospital-admin/src/app/profile/+state/interfaces';
import { AbstractControlState, FormControlState, FormGroupState } from 'ngrx-forms';

import { ISelectOption } from '@locumsnest/components';
import { parseValueErrors } from '@locumsnest/core/src';

@Component({
  selector: 'locumsnest-expiring-document-uploader',
  templateUrl: './expiring-document-uploader.component.html',
  styleUrls: ['./expiring-document-uploader.component.scss'],
})
export class ExpiringDocumentUploaderComponent {
  @Input() file: FormControlState<string>;
  @Input() isDisabled: boolean;
  @Input() fileTypes: ISelectOption[];
  @Input() fileUploadForm: FormGroupState<IUploadFileFormState>;
  @Input() isTitleVisible: boolean;
  @Input() showFileDetails: boolean;
  @Input() acceptFormats: string;
  @Input() acceptFormatsUi: string;
  @Input() transparentTheme: boolean;

  @Output() resetUploadFileForm = new EventEmitter<string>();
  @Output() confirmUploadFile = new EventEmitter<void>();

  constructor() {}

  public onResetUploadFileForm(event) {
    this.showFileDetails = true;
    this.resetUploadFileForm.emit(event);
  }

  public onConfirmUploadFile() {
    this.confirmUploadFile.emit();
  }

  public getErrorMsg(control: AbstractControlState<any>) {
    return control.isDirty ? parseValueErrors(control.errors) : '';
  }
}
