import { select, Store } from '@ngrx/store';

export interface ISelectorService {
  store: Store;
}

export const selectFromStore = (selector) =>
  function () {
    return this.store.pipe(select(selector));
  };

export const serviceSelectors = (selectors) => (ctr) => {
  for (const selectorName in selectors) {
    if (selectors.hasOwnProperty(selectorName)) {
      ctr.prototype[selectorName.replace('select', 'get')] = selectFromStore(
        selectors[selectorName]
      );
    }
  }
  return ctr as any;
};
