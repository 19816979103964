<div>
  <textarea
    name="textArea"
    class="textarea"
    tabindex="0"
    #textArea
    cdkTextareaAutosize
    [id]="id"
    [placeholder]="placeholder"
    [cdkAutosizeMaxRows]="autosizeMaxRows || rows"
    [rows]="rows"
    [cols]="cols"
    [disabled]="disabled ? '' : null"
    [ngClass]="{
      'invert-colors': invertColors && !autosizeMaxRows,
      'invert-colors-auto-size': invertColors && autosizeMaxRows,
      legacy: legacy,
      'border-with-icon': borderWithIcon === true,
      box: !!autosizeMaxRows
    }"
    (keyup.enter)="onInputEntered($event)"
    [(ngModel)]="value"
  ></textarea>

  <div class="icon-suffix">
    <ng-content select="[suffix]" />
  </div>
</div>
<div *ngIf="errorMsg" class="text-error">
  <small>{{ errorMsg }}</small>
</div>
