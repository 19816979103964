import { MessageableFactory, createAlertStateAdapter } from '@locumsnest/core/src';
import { SignalableFactory } from '@locumsnest/core/src';

export const messageableFactory = MessageableFactory.forFeature<'LayoutUI'>('LayoutUI');
export const signalableFactory = SignalableFactory.forFeature<'LayoutUI'>('LayoutUI');
export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorSignal, AlertErrorSignal } = alertStateAdapter.getSignals();
export const { alertSignalToMessage } = alertStateAdapter.getEffectOperators();
