@if (showInline) {
  <div *ngFor="let item of list; let i = index; let last = last; trackBy: trackByFn" class="row">
    <div class="col-sm-11 in-line">
      <ng-container *ngTemplateOutlet="item.content"></ng-container>
      <div
        class="button button--delete"
        [ngClass]="{ disabled: isDisabled }"
        (click)="onRemove(item.id)"
      ></div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-11 in-line">
      <div class="button button--add" [ngClass]="{ disabled: isDisabled }" (click)="onAdd()"></div>
    </div>
  </div>
} @else {
  <div class="container">
    <div *ngFor="let item of list; let i = index; let last = last; trackBy: trackByFn" class="row">
      <div class="col-sm-8 option">
        <div class="row">
          <div
            *ngIf="(isRemovable || removalWhiteList.indexOf(item.id) > -1) && showDeleteButton"
            class="trash-area"
            [ngClass]="{ disabled: isDisabled }"
            (click)="onRemove(item.id)"
          >
            <span
              class="trash-area__trash"
              [class.trash-area__trash--disabled]="
                !isRemovable && !(removalWhiteList.indexOf(item.id) > -1)
              "
              >&nbsp;&nbsp;&nbsp;</span
            >
          </div>
          <div class="col-sm-11">
            <ng-container *ngTemplateOutlet="item.content"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isReadOnly && showAddButton" class="row button-add">
      <locumsnest-action-button
        [buttonType]="'button'"
        [style]="'Blue'"
        [disabled]="isDisabled"
        (click)="onAdd()"
      >
        <span class="button-text">
          {{ buttonText }}
        </span>
      </locumsnest-action-button>
    </div>
  </div>
}
