@if (showPaginationText) {
  <span class="pagination-text">
    {{ getPaginationText() }}
  </span>
}
@if (isEditable) {
  <div class="pagination">
    <div class="pagination-body">
      <div class="button-left" (click)="onArrowButtonClicked(directionEnum.LEFT)">
        <div class="button-left__arrow"></div>
      </div>
      <div class="input-field">
        <locumsnest-input-field
          [type]="'number'"
          [noUnderline]="true"
          [ngModel]="currentPage"
          [min]="1"
          (change)="onSelectedPageChanged($event)"
          (blur)="onBlur($event)"
        >
        </locumsnest-input-field>
      </div>
      <div class="total-pages">
        of <span class="total-pages__number">{{ totalPages }}</span>
      </div>
      <div class="button-right" (click)="onArrowButtonClicked(directionEnum.RIGHT)">
        <div class="button-right__arrow"></div>
      </div>
    </div>
  </div>
} @else {
  <div class="pagination-body">
    <div class="button-left__read-only" (click)="onArrowButtonClicked(directionEnum.LEFT)">
      <div class="button-left__arrow"></div>
    </div>
    <div class="input-field__read-only">Page {{ currentPage }}</div>
    <div class="total-pages__read-only">
      of <span class="total-pages__number">{{ totalPages }}</span>
    </div>
    <div class="button-right__read-only" (click)="onArrowButtonClicked(directionEnum.RIGHT)">
      <div class="button-right__arrow"></div>
    </div>
  </div>
}
