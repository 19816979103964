<div class="list">
  <div class="title">{{ title }}</div>
  <div
    class="scroller"
    infiniteScroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="30"
  >
    <div class="cards">
      <div *ngFor="let infoCard of infoCardList; trackBy: _trackBy">
        <locumsnest-info-card
          [title]="infoCard.title"
          [description]="infoCard.description"
          [image]="infoCard.image"
          [activated]="infoCard.active"
        >
        </locumsnest-info-card>
      </div>
    </div>
  </div>
</div>
