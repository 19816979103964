import { Component, Input } from '@angular/core';

import { IUser } from '../../interfaces/user';

@Component({
  selector: 'locumsnest-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() sender: IUser;
  @Input() messageText: string;
  @Input() createdAt: Date;
  @Input() lastUpdate: Date;
}
