import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { get } from 'lodash-es';

import { InputWrapperWithChangeDetection } from '@locumsnest/components/src/lib/core/input-wrapper';

import { CheckBoxComponent } from '../../atoms/check-box/check-box.component';
import { WidgetAreaComponent } from '../widget-area/widget-area.component';

/**
 * Optional widget area boolean input. Makes ng content visible only when true
 *
 * @export
 * @class OptionalWidgetAreaComponent
 * @extends {InputWrapperWithChangeDetection<boolean>}
 * @implements {ControlValueAccessor}
 */
@Component({
  selector: 'locumsnest-optional-widget-area',
  templateUrl: './optional-widget-area.component.html',
  styleUrls: ['./optional-widget-area.component.scss'],
  standalone: true,
  imports: [WidgetAreaComponent, CheckBoxComponent, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionalWidgetAreaComponent),
      multi: true,
    },
  ],
})
export class OptionalWidgetAreaComponent
  extends InputWrapperWithChangeDetection<boolean>
  implements ControlValueAccessor
{
  @Input() idx: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() isInvalid: boolean;

  @Output() checked = new EventEmitter<boolean>();

  onChecked(event: Event) {
    this.checked.emit(get(event.target, 'checked'));
  }
}
