<div class="carousel-panel">
  <div
    *ngIf="currentPane>0"
    class="carousel-panel__left-line"
    (click)="onShowCarouselPanel(currentPane -1)"
  >
    <div class="arrow-left"></div>
  </div>
  <div class="carousel-panel__main">
    <ng-container
      [ngTemplateOutlet]="slidePanel"
      [ngTemplateOutletContext]="{
    carouselPanelList: carouselPanels.toArray(),
    index: 0
  }"
    ></ng-container>
  </div>
  <div
    *ngIf="currentPane<carouselPanels.length-1 "
    class="carousel-panel__right-line"
    (click)="onShowCarouselPanel(currentPane +1)"
  >
    <div class="arrow-right"></div>
  </div>
</div>

<ng-template #slidePanel let-carouselPanelList="carouselPanelList" let-index="index">
  <ng-container *ngIf="carouselPanelList.length">
    <ng-container *ngIf="carouselPanelList[index] as carouselPanel">
      <ng-container *ngIf="index+1 as nextIndex">
        <locumsnest-slide-panel-container>
          <locumsnest-slide-panel
            [translation]="'47'"
            [activePane]="currentPane > index ? 'right' : 'left'"
          >
            <div leftPane class="carousel-panel__left-pane">
              <ng-container [ngTemplateOutlet]="carouselPanel?.content"></ng-container>
            </div>
            <div rightPane right-container class="carousel-panel__right-pane">
              <ng-container
                *ngIf="nextIndex === carouselPanelList.length -1"
                [ngTemplateOutlet]="carouselPanelList[nextIndex]?.content"
              ></ng-container>
              <ng-container
                *ngIf="nextIndex !== carouselPanelList.length-1"
                [ngTemplateOutlet]="slidePanel"
                [ngTemplateOutletContext]="{
              carouselPanelList: carouselPanelList,
              index: nextIndex
            }"
              ></ng-container>
            </div>
          </locumsnest-slide-panel>
        </locumsnest-slide-panel-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
