<div class="datepicker" [ngClass]="{ 'align-with-calendar-icon': showCalendarIcon }">
  <locumsnest-input-field
    class="datepicker__input"
    [idx]="idx"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [readonly]="true"
    [isInvalid]="isInvalid"
    [showCalendarIconInInput]="showCalendarIconInInput"
    [errorMsg]="errorMsg"
    [extraClass]="extraClass"
    [hasBorder]="hasBorder"
    [borderNoShadow]="borderNoShadow"
    (click)="onInputClick()"
    [(ngModel)]="value"
  />
  @if (datePickerLabelText !== null) {
    <div class="datepicker__label">
      <a href="javascript:void(0);" (click)="onInputClick()">{{ datePickerLabelText }}</a>
    </div>
  }

  @if (showCalendar) {
    <div class="datepicker__calendar" [ngClass]="{ 'align-with-calendar-icon': showCalendarIcon }">
      <div class="datepicker__calendar__container">
        <locumsnest-calendar
          [idx]="idx"
          [disabled]="disabled"
          [dateFormat]="dateFormat"
          [rangeStart]="rangeStart"
          [rangeEnd]="rangeEnd"
          [weekStart]="weekStart"
          [dayNames]="dayNames"
          [months]="months"
          [dates]="[date]"
          [themed]="true"
          [canSelectPastDate]="canSelectPastDate"
          (select)="onSelectDay($event)"
        />
        <div class="datepicker__footer">
          @if (showConfirmButton || showClearButton || showResetButton) {
            <div class="button-row">
              @if (showClearButton) {
                <ng-container>
                  <locumsnest-action-button class="clear-button" (click)="onClearButtonClick()">
                    <span class="button-text"> Clear </span>
                  </locumsnest-action-button>
                </ng-container>
              }
              @if (showResetButton) {
                <ng-container>
                  <locumsnest-action-button class="reset-button" (click)="onResetButtonClick()">
                    <span class="button-text"> Reset </span>
                  </locumsnest-action-button>
                </ng-container>
              }
              @if (showConfirmButton) {
                <ng-container>
                  <locumsnest-action-button class="confirm-button" (click)="onConfirmButtonClick()">
                    <span class="button-text">
                      {{ confirmButtonText }}
                    </span>
                  </locumsnest-action-button>
                </ng-container>
              }
            </div>
          }
        </div>
      </div>
    </div>
  }
  @if (showCalendarIcon) {
    <locumsnest-icon
      class="calendar-icon"
      iconName="calendar"
      width="12"
      height="13"
      (click)="onCalendarIconClick()"
    />
  }
</div>
