const emojis = {
  '🍔 Food & Drink': [
    { title: 'Grapes', character: '🍇' },
    { title: 'Melon', character: '🍈' },
    { title: 'Watermelon', character: '🍉' },
    { title: 'Tangerine', character: '🍊' },
    { title: 'Lemon', character: '🍋' },
    { title: 'Banana', character: '🍌' },
    { title: 'Pineapple', character: '🍍' },
    { title: 'Mango', character: '🥭' },
    { title: 'Red Apple', character: '🍎' },
    { title: 'Green Apple', character: '🍏' },
    { title: 'Pear', character: '🍐' },
    { title: 'Peach', character: '🍑' },
    { title: 'Cherries', character: '🍒' },
    { title: 'Strawberry', character: '🍓' },
    { title: 'Blueberries', character: '🫐' },
    { title: 'Kiwi Fruit', character: '🥝' },
    { title: 'Tomato', character: '🍅' },
    { title: 'Olive', character: '🫒' },
    { title: 'Coconut', character: '🥥' },
    { title: 'Avocado', character: '🥑' },
    { title: 'Eggplant', character: '🍆' },
    { title: 'Potato', character: '🥔' },
    { title: 'Carrot', character: '🥕' },
    { title: 'Ear of Corn', character: '🌽' },
    { title: 'Hot Pepper', character: '🌶️' },
    { title: 'Bell Pepper', character: '🫑' },
    { title: 'Cucumber', character: '🥒' },
    { title: 'Leafy Green', character: '🥬' },
    { title: 'Broccoli', character: '🥦' },
    { title: 'Garlic', character: '🧄' },
    { title: 'Onion', character: '🧅' },
    { title: 'Mushroom', character: '🍄' },
    { title: 'Peanuts', character: '🥜' },
    { title: 'Chestnut', character: '🌰' },
    { title: 'Bread', character: '🍞' },
    { title: 'Croissant', character: '🥐' },
    { title: 'Baguette Bread', character: '🥖' },
    { title: 'Flatbread', character: '🫓' },
    { title: 'Pretzel', character: '🥨' },
    { title: 'Bagel', character: '🥯' },
    { title: 'Pancakes', character: '🥞' },
    { title: 'Waffle', character: '🧇' },
    { title: 'Cheese Wedge', character: '🧀' },
    { title: 'Meat on Bone', character: '🍖' },
    { title: 'Poultry Leg', character: '🍗' },
    { title: 'Cut of Meat', character: '🥩' },
    { title: 'Bacon', character: '🥓' },
    { title: 'Hamburger', character: '🍔' },
    { title: 'French Fries', character: '🍟' },
    { title: 'Pizza', character: '🍕' },
    { title: 'Hot Dog', character: '🌭' },
    { title: 'Sandwich', character: '🥪' },
    { title: 'Taco', character: '🌮' },
    { title: 'Burrito', character: '🌯' },
    { title: 'Tamale', character: '🫔' },
    { title: 'Stuffed Flatbread', character: '🥙' },
    { title: 'Falafel', character: '🧆' },
    { title: 'Egg', character: '🥚' },
    { title: 'Cooking', character: '🍳' },
    { title: 'Shallow Pan of Food', character: '🥘' },
    { title: 'Pot of Food', character: '🍲' },
    { title: 'Fondue', character: '🫕' },
    { title: 'Bowl with Spoon', character: '🥣' },
    { title: 'Green Salad', character: '🥗' },
    { title: 'Popcorn', character: '🍿' },
    { title: 'Butter', character: '🧈' },
    { title: 'Salt', character: '🧂' },
    { title: 'Canned Food', character: '🥫' },
    { title: 'Bento Box', character: '🍱' },
    { title: 'Rice Cracker', character: '🍘' },
    { title: 'Rice Ball', character: '🍙' },
    { title: 'Cooked Rice', character: '🍚' },
    { title: 'Curry Rice', character: '🍛' },
    { title: 'Steaming Bowl', character: '🍜' },
    { title: 'Spaghetti', character: '🍝' },
    { title: 'Roasted Sweet Potato', character: '🍠' },
    { title: 'Oden', character: '🍢' },
    { title: 'Sushi', character: '🍣' },
    { title: 'Fried Shrimp', character: '🍤' },
    { title: 'Fish Cake with Swirl', character: '🍥' },
    { title: 'Moon Cake', character: '🥮' },
    { title: 'Dango', character: '🍡' },
    { title: 'Dumpling', character: '🥟' },
    { title: 'Fortune Cookie', character: '🥠' },
    { title: 'Takeout Box', character: '🥡' },
    { title: 'Oyster', character: '🦪' },
    { title: 'Soft Ice Cream', character: '🍦' },
    { title: 'Shaved Ice', character: '🍧' },
    { title: 'Ice Cream', character: '🍨' },
    { title: 'Doughnut', character: '🍩' },
    { title: 'Cookie', character: '🍪' },
    { title: 'Birthday Cake', character: '🎂' },
    { title: 'Shortcake', character: '🍰' },
    { title: 'Cupcake', character: '🧁' },
    { title: 'Pie', character: '🥧' },
    { title: 'Chocolate Bar', character: '🍫' },
    { title: 'Candy', character: '🍬' },
    { title: 'Lollipop', character: '🍭' },
    { title: 'Custard', character: '🍮' },
    { title: 'Honey Pot', character: '🍯' },
    { title: 'Baby Bottle', character: '🍼' },
    { title: 'Glass of Milk', character: '🥛' },
    { title: 'Hot Beverage', character: '☕' },
    { title: 'Teapot', character: '🫖' },
    { title: 'Teacup Without Handle', character: '🍵' },
    { title: 'Sake', character: '🍶' },
    { title: 'Bottle with Popping Cork', character: '🍾' },
    { title: 'Wine Glass', character: '🍷' },
    { title: 'Cocktail Glass', character: '🍸' },
    { title: 'Tropical Drink', character: '🍹' },
    { title: 'Beer Mug', character: '🍺' },
    { title: 'Clinking Beer Mugs', character: '🍻' },
    { title: 'Clinking Glasses', character: '🥂' },
    { title: 'Tumbler Glass', character: '🥃' },
    { title: 'Cup with Straw', character: '🥤' },
    { title: 'Bubble Tea', character: '🧋' },
    { title: 'Beverage Box', character: '🧃' },
    { title: 'Mate', character: '🧉' },
    { title: 'Ice', character: '🧊' },
    { title: 'Chopsticks', character: '🥢' },
    { title: 'Fork and Knife with Plate', character: '🍽️' },
    { title: 'Fork and Knife', character: '🍴' },
    { title: 'Spoon', character: '🥄' },
  ],
};

export default emojis;
