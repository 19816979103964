import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'locumsnest-carousel-panel',
  styleUrls: ['./locumsnest-carousel-panel.scss'],
  templateUrl: './locumsnest-carousel-panel.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselPanelComponent {
  @Input() active: boolean;
  @ViewChild(TemplateRef, { static: true }) content: TemplateRef<any>;
}
