import { ISelectOption } from '../../interfaces';

const enum Days {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

type Code = 'days' | 'weekdays' | 'weekends' | DaysCode;

type DaysCode = `${Lowercase<keyof typeof Days>}s`;

interface SelectionType {
  selectOption: ISelectOption<number, Code>;
  checkDay: (day: number) => boolean;
}

export const optionStrategies = {
  days: {
    selectOption: { id: 1, name: 'all days', code: 'days' },
    checkDay: (_) => true,
  },
  weekdays: {
    selectOption: { id: 2, name: 'weekdays', code: 'weekdays' },
    checkDay: (day) =>
      [Days.MONDAY, Days.TUESDAY, Days.WEDNESDAY, Days.THURSDAY, Days.FRIDAY].includes(day),
  },
  weekends: {
    selectOption: { id: 3, name: 'weekends', code: 'weekends' },
    checkDay: (day) => [Days.SUNDAY, Days.SATURDAY].includes(day),
  },
  mondays: {
    selectOption: { id: 4, name: 'Mondays', code: 'mondays' },
    checkDay: (day) => day === Days.MONDAY,
  },
  tuesdays: {
    selectOption: { id: 5, name: 'Tuesdays', code: 'tuesdays' },
    checkDay: (day) => day === Days.TUESDAY,
  },
  wednesdays: {
    selectOption: { id: 6, name: 'Wednesdays', code: 'wednesdays' },
    checkDay: (day) => day === Days.WEDNESDAY,
  },
  thursdays: {
    selectOption: { id: 7, name: 'Thursdays', code: 'thursdays' },
    checkDay: (day) => day === Days.THURSDAY,
  },
  fridays: {
    selectOption: { id: 8, name: 'Fridays', code: 'fridays' },
    checkDay: (day) => day === Days.FRIDAY,
  },
  saturdays: {
    selectOption: { id: 9, name: 'Saturdays', code: 'saturdays' },
    checkDay: (day) => day === Days.SATURDAY,
  },
  sundays: {
    selectOption: { id: 10, name: 'Sundays', code: 'sundays' },
    checkDay: (day) => day === Days.SUNDAY,
  },
} satisfies Record<Code, SelectionType>;
