import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';

@Component({
  selector: 'locumsnest-tag-label',
  templateUrl: './tag-label.component.html',
  styleUrls: ['./tag-label.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.new]': ' isNew()',
    '[class.disabled]': ' isDisabled()',
  },
})
export class TagLabelComponent {
  isNew = input(false);
  isDisabled = input(false);
  @Output() remove = new EventEmitter<void>();

  public onRemove() {
    if (!this.isDisabled()) {
      this.remove.emit();
    }
  }
}
