<div class="message" (click)="onMessageSelected(messageListItem.id)">
  <div class="message-icon"></div>
  <div class="message-details">
    <span class="message-sender">
      <div
        *ngFor="
          let receiver of messageListItem.receivers;
          last as isLast;
          trackBy: trackByFirstLastName
        "
      >
        {{ receiver.firstName }} {{ receiver.lastName }}{{ isLast ? '' : ',&nbsp;' }}
      </div></span
    >
    <div class="message-body ellipsis">
      {{ getLastMessage(messageListItem.messages) }}
    </div>
  </div>
</div>
