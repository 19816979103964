import { ChangeDetectionStrategy, Component, input } from '@angular/core';

export type TitleType = 'h1' | 'h2' | 'h3' | 'h4';
export type LineSize = 'normal' | 'full' | 'no-line';
export type LineTheme = 'black' | 'grey';

@Component({
  selector: 'locumsnest-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SectionTitleComponent {
  title = input('');
  gap = input(0);
  underLineSize = input<LineSize>('normal');
  titleType = input<TitleType>('h1');
  lineTheme = input<LineTheme>('black');
}
