import { ChangeDetectorRef, Directive, inject, Input, signal, WritableSignal } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { isArray, isEqual } from 'lodash-es';

type ValueType = string | boolean | number | string[];

@Directive()
export abstract class InputWrapper<T extends ValueType = any> implements ControlValueAccessor {
  // todo think of abstract methods we want people to implement
  // but the below are already implemented since the common case
  // @Input() disabled=false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected _value: T;
  protected onTouch: Function = (_: any) => {};
  protected propagateChange: Function = (_: any) => {};

  registerOnChange(fn: Function) {
    this.propagateChange = fn;
  }
  registerOnTouch(fn: Function) {
    this.onTouch = fn;
  }
  @Input() get value() {
    return this._value;
  }

  isNewValue(value) {
    if (isArray(value)) {
      return !isArray(this.value) || !isEqual(new Set(value), new Set(this.value));
    }
    return value !== this.value;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set value(value) {
    if (this.isNewValue(value)) {
      this._value = value;
      this.propagateChange(this.value);
    }
  }
  // called by the reactive form control
  registerOnTouched(fn: Function) {
    this.onTouch = fn;
  }

  // writes the value to the local component
  // that binds to the "value"
  writeValue(value: T) {
    this._value = value;
  }

  onBlur($event) {
    this.onTouch();
  }

  // setDisabledState(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }
}

@Directive()
export abstract class InputWrapperWithChangeDetection<
  T extends ValueType = any,
> extends InputWrapper<T> {
  protected changeDetectionReference = inject(ChangeDetectorRef);

  writeValue(value: T) {
    this.value = value;
    this.changeDetectionReference.markForCheck();
  }
}

@Directive()
export abstract class InputWrapperSignal<T extends ValueType> implements ControlValueAccessor {
  protected value: WritableSignal<T>;
  protected isDisabled = false;

  protected onChange: (newValue: T) => void = () => {};
  protected onTouched: () => void = () => {};

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: T) {
    if (!this.value) this.value = signal(value);
    this.value.set(value);
  }

  onBlur() {
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
