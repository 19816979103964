<div class="info-banner-container">
  <div class="info-banner-content-row">
    <div
      class="info-banner-content-row--text"
      [ngClass]="{
        regular: fontStyle === 'regular',
        bold: fontStyle === 'bold'
      }"
    >
      <ng-content></ng-content>
    </div>
    <div
      class="info-banner-content-row--image"
      [ngClass]="{
        'image-safebox': sideImage === 'safebox',
        'image-staffbank-safebox': sideImage === 'staffbank-safebox',
        'image-passport': sideImage === 'passport'
      }"
    ></div>
  </div>
</div>
