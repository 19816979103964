import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Time } from '@locumsnest/core/src/lib/helpers';
import { DATE_FORMAT, URL_DATE_FORMAT } from '@locumsnest/core/src/lib/types/constants';

@Component({
  selector: 'locumsnest-add-bar',
  templateUrl: './add-bar.component.html',
  styleUrls: ['./add-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBarComponent {
  @Input() idx: string;
  @Input() displayText: string;
  @Input() selectedDate: string;

  constructor() {}

  public getTheDate() {
    return Time.getMoment(this.selectedDate, DATE_FORMAT).format(URL_DATE_FORMAT);
  }
}
