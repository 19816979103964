import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type PaneType = 'left' | 'right';

@Component({
  selector: 'locumsnest-slide-panel',
  styleUrls: ['./locumsnest-slide-panel.scss'],
  templateUrl: './locumsnest-slide-panel.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-{{translation}}%)' }), {
        params: { translation: '50' },
      }),
      transition('* => *', animate('{{duration}}')),
    ]),
  ],
})
export class SlidePanelComponent {
  @Input() activePane: PaneType = 'left';
  @Input() duration = '400ms';
  @Input() translation = 50; // TODO: Discontinue this
}
