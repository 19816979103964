<locumsnest-section-title [title]="title" [underLineSize]="underLineSize" [titleType]="'h2'">
  <ng-content select="[heading]"></ng-content>
  <div class="text-center" [tp]="actionHelp | titlecase">
    <button
      *ngIf="action"
      class="action-button"
      [disabled]="actionDisabled"
      (click)="onActionClicked()"
    >
      <div [ngClass]="action"></div>
    </button>
  </div>
</locumsnest-section-title>
<ng-content></ng-content>
