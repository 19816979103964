import { ActionReducerMap } from '@ngrx/store';

import { entityReducer, paginationReducer } from './filter-views.reducer';
import { IFilterViewsFeatureState } from './interfaces';

export const reducers: ActionReducerMap<IFilterViewsFeatureState> = {
  entityState: entityReducer,
  pagination: paginationReducer,
};

export const HOME_NAME = 'home';
