<div *ngIf="!displayNotesArea">
  <locumsnest-section-title [title]="title"></locumsnest-section-title>
  <div class="status-container">
    <div class="status-box-content-row">
      <div class="status-box-content-row--input">
        <locumsnest-text-area
          legacy
          [ngrxFormControlState]="noteFormState?.controls.note"
          [placeholder]="'type here and press enter to submit'"
          [onEnterBlur]="true"
          [errorMsg]="getErrorMsg(noteFormState?.controls.note)"
          [isInvalid]="
            noteFormState?.controls.note.isDirty && noteFormState?.controls.note.isInvalid
          "
          (enter)="onSubmitNewNote($event)"
        ></locumsnest-text-area>
      </div>
      <div *ngIf="info" class="status-box-content-row--info">* {{ info }}</div>
    </div>
    <div class="show-flagged">
      Show flagged notes only &nbsp; &nbsp;
      <locumsnest-toggle [ngModel]="showFlaggedNotes" (toggled)="onToggleFlaggedNotes($event)">
      </locumsnest-toggle>
    </div>
    <div *ngIf="notes?.length === 0 && showFlaggedNotes" class="empty-list">
      No flagged notes found
    </div>
    <div
      class="scroller"
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <div
        *ngFor="let note of notes; let last = last; trackBy: trackById"
        class="status-box-content-row"
      >
        <div class="status-box-content-row--note">
          <div class="status-box-content-row--note-text">{{ note.noteText }}</div>
          <span class="status-box-content-row--note-date">{{ note.createdAt | fromNow }}</span>
          <div *ngIf="note.createdBy" class="status-box-content-row--note-details">
            {{ note.createdBy?.firstName }} {{ note.createdBy?.lastName }},
            {{ note.createdBy?.email }}
          </div>
          <hr *ngIf="!last" class="line" />
        </div>
        <!-- <div class="status-box-content-row--delete" (click)="onDeleteButtonClick(note)"></div> -->
        <div
          *ngIf="note.flagged"
          class="status-box-content-row--flag-red"
          (click)="onFlagButtonClick(note)"
        ></div>
        <div
          *ngIf="!note.flagged"
          class="status-box-content-row--flag-grey"
          (click)="onFlagButtonClick(note)"
        ></div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="displayNotesArea" class="show-flagged-area">
  Show flagged notes only &nbsp; &nbsp;
  <locumsnest-toggle [ngModel]="showFlaggedNotes" (toggled)="onToggleFlaggedNotes($event)">
  </locumsnest-toggle>
</div>
<div *ngIf="displayNotesArea" class="notes-area">
  <div *ngIf="notes?.length === 0 && showFlaggedNotes" class="empty-list">
    No flagged notes found
  </div>
  <div
    class="scroller right-space"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
  >
    <div class="status-box-content-row right-space">
      <div *ngFor="let note of notes; let last = last; trackBy: trackById" class="note-block">
        <div class="status-box-content-row--note">
          <div class="status-box-content-row--note-text">{{ note.noteText }}</div>
          <span class="status-box-content-row--note-date">{{ note.createdAt | fromNow }}</span>
          <div *ngIf="note.createdBy" class="status-box-content-row--note-details-notes">
            {{ note.createdBy?.firstName }} {{ note.createdBy?.lastName }},
            {{ note.createdBy?.email }}
          </div>
          <hr *ngIf="!last" class="line-notes" />
        </div>
        <!-- <div
          class="status-box-content-row--delete delete"
          (click)="onDeleteButtonClick(note)"
        ></div> -->
        <div
          *ngIf="note.flagged"
          class="status-box-content-row--flag-red-area"
          (click)="onFlagButtonClick(note)"
        ></div>
        <div
          *ngIf="!note.flagged"
          class="status-box-content-row--flag-grey-area"
          (click)="onFlagButtonClick(note)"
        ></div>
      </div>
    </div>
  </div>
  <hr class="line-after-container" />
  <div class="status-container">
    <div class="status-box-content-row">
      <div class="status-box-content-row--input">
        <locumsnest-text-area
          legacy
          [ngrxFormControlState]="noteFormState?.controls.note"
          [placeholder]="'type here and press enter to submit'"
          [onEnterBlur]="true"
          [errorMsg]="getErrorMsg(noteFormState?.controls.note)"
          [isInvalid]="
            noteFormState?.controls.note.isDirty && noteFormState?.controls.note.isInvalid
          "
          (enter)="onSubmitNewNote($event)"
        ></locumsnest-text-area>
      </div>
      <div *ngIf="info" class="status-box-content-row--info">* {{ info }}</div>
    </div>
  </div>
</div>
