import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core/src';
import { createEntityAdapter, index } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IMultipleIndexField } from '@locumsnest/core/src/lib/ngrx/interfaces';

import { HOME_NAME } from '.';
import { IFilterViews } from './interfaces';

export const PAGE_INDEX: IMultipleIndexField = {
  fieldName: 'page',
  unique: false,
  reAssignMent: false,
};
const indexEntity = index([PAGE_INDEX]);

const namespace = 'FilterViews';
const adapter = createEntityAdapter<IFilterViews>(
  {
    sortComparer: (a, b) => {
      if (a.name.toLowerCase() === HOME_NAME) return -1;
      if (b.name.toLowerCase() === HOME_NAME) return 1;
      return +new Date(a.createdAt) - +new Date(b.createdAt);
    },
  },
  indexEntity,
);
const messageableFactory = MessageableFactory.forFeature<typeof namespace>(namespace);
const signalableFactory = SignalableFactory.forFeature<typeof namespace>(namespace);
const initialState = adapter.getInitialState({});
const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter,
);
export { adapter, initialState, paginationAdapter };
