<div class="input__prefix" [ngClass]="{ 'has-border': hasBorder }">
  <ng-content select="[prefix]" />
</div>
<input
  class="input {{ extraClass }}"
  #input
  placeholder="{{ placeholder }}"
  [id]="idx || name"
  [readonly]="readonly ? '' : null"
  [disabled]="disabled ? '' : null"
  [type]="type ? type : 'text'"
  [min]="min ?? null"
  [max]="max ?? null"
  [minlength]="minlength ?? null"
  [maxlength]="maxlength ?? null"
  [step]="step ?? null"
  [name]="name"
  [ngClass]="{
    'input--active': active,
    'input--bold': styleBold,
    'input--larger-font': largerFont,
    'input--regular-font': regularFont,
    'input--align-right': textAlignRight,
    'input--alert': isAlert || hasWarning,
    'input--no-underline': noUnderline,
    'input--border': hasBorder,
    'input--hide-border': hideBorder,
    'input--transparent': transparentTheme,
    'input--is-command': isCommand,
    'input--readonly': readonly,
    'input--prefix-border': hasBorder && hasPrefix,
    'input--border-no-shadow': hasBorder && borderNoShadow
  }"
  [class.keep-colors-on-disable]="keepColorsOnDisable"
  (input)="onChange($event)"
  (keyup)="onClick($event)"
  (focus)="onFocus($event)"
  (keyup.enter)="onInputEntered($event)"
  (blur)="onBlur($event)"
  [(ngModel)]="inputText"
/>
<span class="input__suffix">
  <ng-content select="[suffix]" />
</span>
@if (showCalendarIconInInput) {
  <locumsnest-icon
    class="input__calendar-icon"
    iconName="calendar"
    [ngClass]="{ 'input__calendar-icon__white': extraClass === 'white-theme' }"
  />
}

<div [ngClass]="{ 'has-border': hasBorder }">
  @if (errorMsg) {
    <small class="text-danger" [id]="idx + '__error-message'">
      {{ errorMsg }}
    </small>
  }
</div>
