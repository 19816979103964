<label
  class="radio-button"
  [ngClass]="{ disabled: isDisabled, smaller: smaller, 'dark-theme': darkTheme }"
>
  <ng-content></ng-content>
  <div class="caption">
    <input
      class="dot"
      type="radio"
      [checked]="checked"
      [ngClass]="{ 'no-dot': hideDot }"
      (change)="onChange()"
    />
    <span class="displayedText" [ngClass]="{ 'hide-dot': hideDot }">{{ displayText }}</span>
  </div>
</label>
