import { Directive, OnDestroy } from '@angular/core';
import { AbstractControlState } from 'ngrx-forms';
import { Subscription } from 'rxjs';

import { Id, parseValueErrors } from '@locumsnest/core/src';

@Directive()
export class LocumsNestBaseComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public trackById(index: number, resource?: { id?: Id } | string | number) {
    if (typeof resource === 'number' || typeof resource === 'string') return index;
    return resource?.id || index;
  }

  public addToSubscriptions(sub: Subscription | Subscription[]): void {
    if (Array.isArray(sub)) this.subscriptions.push(...sub);
    else this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
export abstract class LocumsNestComponent extends LocumsNestBaseComponent {
  public getErrorMsg(control: AbstractControlState<any>) {
    return control.isDirty ? parseValueErrors(control.errors) : '';
  }
}
