<div
  class="icon-button"
  [ngClass]="{ disabled: disabled, enable: !disabled }"
  (click)="onButtonClicked()"
>
  @if (icon) {
    <div
      class="icon"
      [ngClass]="{
        approve: icon === 'approve',
        reject: icon === 'reject',
        calendar: icon === 'calendar'
      }"
    ></div>
    <div class="text" [ngClass]="{ approve: icon === 'approve', reject: icon === 'reject' }">
      {{ displayText }}
    </div>
  } @else {
    <ng-content></ng-content>
  }
</div>
