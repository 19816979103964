import { NgClass } from '@angular/common';
import { booleanAttribute, Component, HostBinding, Input } from '@angular/core';

type ButtonType =
  | 'alert'
  | 'back'
  | 'accept'
  | 'accept-dark'
  | 'decline'
  | 'warning-accept'
  | 'warning-decline'
  | 'go'
  | 'confirm'
  | 'info'
  | 'onboarding-back'
  | 'onboarding-next'
  | 'onboarding-save'
  | 'cancel'
  | 'transparent'
  | 'save'
  | 'actioned'
  | 'bot'
  | 'dismiss'
  | 'tag';

type Size = 'small' | 'medium' | 'xsmall';
type Radius = 'radius-0' | 'radius-5' | 'radius-25';

@Component({
  selector: 'locumsnest-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() @HostBinding('class.disabled') disabled = false;
  @Input() @HostBinding('class.margin-right-4') marginRight = true;
  @Input({ transform: booleanAttribute }) fullWidth = false;
  @Input() idx: string;
  @Input() extraClass = '';
  @Input() buttonType: ButtonType;
  @Input() size: Size;
  @Input() radius: Radius = 'radius-5';
  @Input() isSelected = false;
  @Input() isBordered = false;
  @Input() hasBoldFont = false;
}
