<locumsnest-input-field
  class="input-field"
  hasBorder
  borderNoShadow
  [ngrxFormControlState]="inputControl"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [maxlength]="maxLength"
  (inputEntered)="inputEntered.emit($event)"
/>
<div></div>
<locumsnest-button
  class="icon-button"
  [ngClass]="{ 'non-clickable': nonClickable }"
  [disabled]="disabled"
  (click)="buttonClicked.emit()"
>
  <locumsnest-icon [width]="iconSize" [height]="iconSize" [iconName]="iconName" />
</locumsnest-button>
