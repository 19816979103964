import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';

import { LocumsNestBaseComponent } from '../../core/locums-nest-component';
import { TabId } from './+state';
import { TabDirective } from './tab.directive';

@Component({
  selector: 'locumsnest-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  // Not working
  // https://github.com/angular/angular/issues/38976
  // https://medium.com/angularwave/a-simple-trick-you-need-for-dynamic-contentchildren-6ed0eae49cb4
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent extends LocumsNestBaseComponent {
  @ContentChildren(TabDirective) tabs: QueryList<TabDirective>;
  @Input() canAddTabs = false;
  @Output() addTab = new EventEmitter<void>();
  @Output() selectedTab = new EventEmitter<TabId>();
  @Output() editTab = new EventEmitter<TabId>();

  get currentTab(): TemplateRef<any> {
    return this.tabs.find((tab) => tab.isActive)?.template || this.tabs.first?.template;
  }

  onAddTab() {
    this.addTab.emit();
  }

  onSelectedTab(event: Event, { isActive, id }: TabDirective) {
    if (isActive) event.stopPropagation();
    else this.selectedTab.emit(id);
  }

  // TODO: check if home tab in effect
  onEditTab(id: TabId, first: boolean) {
    if (!first) this.editTab.emit(id);
  }
}
