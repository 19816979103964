import { Component } from '@angular/core';

@Component({
  selector: 'locumsnest-choice-section',
  templateUrl: './choice-section.component.html',
  styleUrls: ['./choice-section.component.scss'],
})
export class ChoiceSectionComponent {
  constructor() {}
}
