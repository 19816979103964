@if (isNew) {
  <div class="new">new</div>
}

@if (hasWarning) {
  <locumsnest-icon class="warning-icon" iconName="warning-info" width="10" height="10" />
}
@if (deleteMode) {
  <input class="delete-checkbox" type="checkbox" [checked]="isSelected" />
}

<ng-content></ng-content>

@if (!deleteMode) {
  <div>
    @if (isSelected) {
      <locumsnest-icon iconName="tick-blue2" width="17" height="17" />
    } @else {
      <locumsnest-icon class="tag-add" iconName="plus-circle" width="17" height="17" />
    }
  </div>
}
