import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HOME_NAME } from '.';
import { paginationAdapter } from './filter-views.adapter';
import { featureKey, selectAll } from './filter-views.reducer';
import { IFilterViewsFeatureState } from './interfaces';

const selectFilterViewsState = createFeatureSelector<IFilterViewsFeatureState>(featureKey);

export const selectFilterViewsEntityState = createSelector(
  selectFilterViewsState,
  (state) => state.entityState,
);

export const selectAllFilters = createSelector(selectFilterViewsEntityState, selectAll);

export const selectHomeFilter = createSelector(
  selectAllFilters,
  (filters) => filters.find((filter) => filter.name.toLowerCase() === HOME_NAME) || null,
);

export const selectHomeFilterId = createSelector(
  selectHomeFilter,
  (homeFilter) => homeFilter?.id || null,
);

export const filterViewsPaginationSelectors = paginationAdapter.paginationSelectors(
  selectFilterViewsState,
  null,
  'pagination',
  'entityState',
  false,
);
