import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import classicEditor from 'ln-ckeditor5';

import { InputWrapperWithChangeDetection } from '../../core/input-wrapper';
import { EmojiPlugin } from './emoji.plugin';

@Component({
  selector: 'locumsnest-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
export class EditorComponent
  extends InputWrapperWithChangeDetection
  implements ControlValueAccessor
{
  private el = inject(ElementRef);
  @Input() @HostBinding('class.editor--standard') standardTheme = true;
  @Input() @HostBinding('class.editor--blue') blueTheme = false;
  @Input() @HostBinding('class.editor__has-attachment') hasAttachment = false;
  @Input() isDisabled: boolean;
  @Input() errorMsg: string;
  @Input() hideToolbar: false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<Event>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Editor = classicEditor;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public plugins: any[] = [...classicEditor.builtinPlugins, EmojiPlugin];

  public editorValue: string;

  public headingStyle = `
  font-weight: bold;
  font-size: 18px;`;

  public subHeadingStyle = `
  font-weight: bold;
  font-size: 16px;`;

  public normalStyle = `
  font-size: 16px;`;

  public footnoteStyle = `
  font-size: 14px;
  font-style: italic;`;

  get inputText() {
    if (this.value.indexOf('style') === -1) {
      return this.value;
    } else {
      return this.editorValue;
    }
  }

  set inputText(val) {
    this.writeValue(val);
  }

  writeValue(value: string): void {
    this.editorValue = value;
    super.writeValue(this.parseHeadings(value));
  }

  onChange(event: Event) {
    this.change.emit(event);
  }

  parseHeadings(text: string) {
    return text
      .replace(/<h1>/g, `<h1 style="${this.headingStyle}">`)
      .replace(/<h2>/g, `<h2 style="${this.subHeadingStyle}">`)
      .replace(/<p>/g, `<p style="${this.normalStyle}">`)
      .replace(
        /<p class="ck-heading_footnote">/g,
        `<p class="ck-heading_footnote" style="${this.footnoteStyle}">`,
      );
  }
}
