import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControlState } from 'ngrx-forms';

import { IMessengerMessage } from '../../interfaces/message';

@Component({
  selector: 'locumsnest-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessengerComponent {
  @ViewChild('text', { static: true }) ref: ElementRef;
  @Input() newComment: FormControlState<string>;
  @Input() title: string;
  @Input() messages: IMessengerMessage[] = [];
  @Input() placeholder = 'Type a message...';
  @Input() isExpanded: boolean;
  @Input() hasNewMessages: boolean;
  @Output() loadMore = new EventEmitter();
  @Output() submitMessage = new EventEmitter();
  @Output() toggle = new EventEmitter();

  onScroll() {
    this.loadMore.emit();
  }

  onClick() {
    if (this.newComment.value !== null && this.newComment.value.replace(/\s/g, '').length > 0) {
      this.submitMessage.emit();
    }
  }

  onKeyUp() {
    if (this.ref.nativeElement.scrollHeight > this.ref.nativeElement.offsetHeight) {
      if (this.ref.nativeElement.rows < 3) {
        this.ref.nativeElement.rows++;
      }
    } else {
      if (this.ref.nativeElement.rows > 1) {
        this.ref.nativeElement.rows--;
        if (this.ref.nativeElement.scrollHeight > this.ref.nativeElement.offsetHeight) {
          this.ref.nativeElement.rows++;
        }
      }
    }

    if (!this.isExpanded && this.messages.length > 0) {
      this.toggle.emit();
    }
  }

  onToggle() {
    this.toggle.emit();
  }
}
