import { FormGroupState } from 'ngrx-forms';

import {
  IAlertState,
  ICloneDocumentToHospitalFormState,
  IContactFormState,
} from '@locumsnest/core/src';
import { INoteComponentState } from '@locumsnest/core/src/lib/adapters/note-component-adapter';

import { ICertificateEntity } from '../../../interfaces/api/certificate-entity';
import { IProfessionEntity } from '../../../interfaces/api/profession-entity';
import { ISafeboxReferenceEntity } from '../../../interfaces/api/safebox-reference-entity';
import { IProfileAddress, IProfileEntity } from './../../../interfaces/api/profile-entity';
import { IReferenceEntity } from './../../../interfaces/api/reference-entity';
import { ISafeboxCertificateEntity } from './../../../interfaces/api/safebox-certificate-entity';

export interface IProfileUiState {
  readonly employmentHistoryFilter: EmploymentHistoryFilterTypes;
  readonly profileAssignmentNumbersFilter: ProfileAssignmentNumbersFilterTypes;
  readonly safeboxUploadFileFormState: FormGroupState<IUploadFileFormState>;
  readonly noteFormState: FormGroupState<INoteComponentState>;
  readonly complianceMessageFormState: FormGroupState<IContactFormState>;
  readonly cloneDocumentToHospitalFormState: FormGroupState<ICloneDocumentToHospitalFormState>;
  readonly copiedCertificates: number[];
  readonly copiedReferences: number[];
  readonly alertState?: IAlertState;
  readonly selectedTab: string;
  readonly showFlaggedNotes: boolean;
  readonly showFlaggedNotesProfileIds: Record<string, boolean>;
}

export type ProfileDocument = IPassportDocument | ISafeboxDocument;

export interface IUploadFileFormState {
  file: string;
  type: number;
  expirationDate: Date;
  title: string;
}

export enum EmploymentHistoryFilterTypes {
  none = 'NONE',
  application = 'APPLICATION',
  job = 'JOB',
  bids = 'BIDS',
}

export interface EmploymentHistoryFilterButton {
  title: string;
  filter: EmploymentHistoryFilterTypes;
}

export enum ProfileAssignmentNumbersFilterTypes {
  none = 'NONE',
  active = 'ACTIVE',
  inactive = 'IN-ACTIVE',
}

export interface IProfilePersonalInfo {
  readonly personalEmail: string;
  readonly telephone: string;
  readonly professionalEmail: string;
  readonly address: IProfileAddress;
  readonly photos: string[];
}

export interface IProfileRegistryRecord {
  readonly id: string;
  readonly registrationNumber: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly gender: string;
  readonly personalEmail: string;
  readonly telephone: string;
  readonly professionalEmail: string;
  readonly address: IProfileAddress;
  readonly identityProofPhoto: string;
  readonly photo: string;
  readonly photosAccepted: boolean;
  readonly canPractice: boolean;
  readonly resourcetype: string;
  readonly profession: IProfessionEntity;
  // Medics
  readonly gmcStatus?: number;
  readonly srDate?: string; // Specialist Register Date
  readonly gprDate?: string; // GP Register Date
  readonly qualification?: string;
  readonly yearOfQualification?: string;
  readonly placeOfQualification?: string;
  readonly placeOfQualificationCountry?: string;
  readonly prDate?: string; // Provisional Registration Date
  readonly frDate?: string; // Full Registration Date
  readonly revalidationStatus?: string;
  readonly responsibleOfficerName?: string;
  readonly designatedBodyName?: string;
  readonly doctorInTraining?: string;
  // AHPS
  readonly registrationFrom?: Date;
  readonly registrationTo?: Date;
  readonly titles?: string;
  readonly status?: string;
  readonly qualifications?: string;
  readonly firstRegistered?: string;
  readonly registrantType?: string;
  // Vaccination
  readonly compliantWithVaccine?: boolean;
  readonly dateOfVaccineDeclaration?: Date;
}

export enum DocumentTypes {
  reference = 'REFERENCE',
  certificate = 'CERTIFICATE',
}

export enum ProfileUiTabs {
  general = 'general',
  digitalSafebox = 'digital-safebox',
  digitalPassport = 'digital-passport',
  sendMessage = 'send-message',
  unavailability = 'unavailability',
  agencyDocuments = 'agencyDocuments',
}

export interface IPassportDocument {
  readonly display: string;
  readonly type?: DocumentTypes;
  readonly text?: string;
  readonly ownerName?: string;
  readonly url: string;
  readonly id: number;
  readonly expirationDate?: Date;
  readonly original?: IReferenceEntity | ICertificateEntity;
  readonly isCopied?: boolean;
}

export interface ISafeboxDocument {
  readonly display: string;
  readonly type: DocumentTypes;
  readonly text: string;
  readonly url: string;
  readonly id: number;
  readonly expirationDate: Date;
  readonly original: ISafeboxReferenceEntity | ISafeboxCertificateEntity;
}

export interface IProfileAssignmentNumberEntry {
  readonly assignmentNumber: string;
  readonly assignmentCategory: string;
  readonly grade: string;
  readonly active: string;
  readonly effectiveEndDate: string;
  readonly effectiveStartDate: string;
  readonly trust: string;
  readonly userAssignmentStatus?: string;
}

export interface ISetEmploymentHistoryFilterPayload {
  filter: EmploymentHistoryFilterTypes;
}

export interface ISetProfileAssignmentNumbersFilterPayload {
  filter: ProfileAssignmentNumbersFilterTypes;
}

export interface IProfileWithStaffBankStatus {
  profile: IProfileEntity;
  staffBankStatus: string;
  staffBankStatusFont: string;
}
