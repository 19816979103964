import { get } from 'lodash-es';
import { Boxed, FormControlState, NgrxValueConverter, NgrxValueConverters } from 'ngrx-forms';

import { getFile } from '../../../helpers/file';

const { dateToISOString, default: boxedConverter } = NgrxValueConverters;

export const dateArrayToISOStringArray = {
  convertViewToStateValue: (dates: Date[]): string[] | Boxed<string[]> =>
    boxedConverter<string[]>().convertViewToStateValue(
      (dates || []).map((date) => dateToISOString.convertViewToStateValue(date))
    ),
  convertStateToViewValue: (dates: Boxed<string[]>) =>
    boxedConverter<Date[]>().convertStateToViewValue({
      ...dates,
      value: dates.value.map((date) => dateToISOString.convertStateToViewValue(date)),
    }),
} as NgrxValueConverter<Date[], string[] | Boxed<string[]>>;

export const getFileFromControl = (control: FormControlState<string>) =>
  get(getFile(control.value), 'file', null);
export const getFileNameFromUrl = (url: string) => url.split('/').pop().split('#')[0].split('?')[0];
export const getFileNameFromControlValue = (value: string) => {
  try {
    return JSON.parse(value).name;
  } catch {
    return getFileNameFromUrl(value);
  }
};
export const getFileNameFromControl = (control: FormControlState<string>) =>
  getFileNameFromControlValue(control.value);

export const getCsvFromControl = (control: FormControlState<string>) =>
  JSON.parse(control.value).parsedCsvData;
