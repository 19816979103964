<div class="user-status-bar">
  <locumsnest-user-profile
    class="user-profile"
    [photoUrl]="photoUrl"
    [name]="name"
    [vaccinationStatus]="vaccinationStatus"
    [showVaccinationStatus]="showVaccinationStatus"
    [isHyperlink]="isHyperlink"
    [profileId]="profileId"
  ></locumsnest-user-profile>
  <div class="status-component">
    <ng-content></ng-content>
  </div>
  <span class="details">{{ details }}</span>
</div>
