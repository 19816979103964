import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'locumsnest-error-tip',
  templateUrl: './error-tip.component.html',
  styleUrls: ['./error-tip.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorTipComponent {}
