import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-number-badge',
  templateUrl: './number-badge.component.html',
  styleUrls: ['./number-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberBadgeComponent {
  @Input() idx: string;
  @Input() displayValue: number;
  @Input() title: string;
  @Input() isInvalid: boolean;
  @Input() isWarned: boolean;
}
