<button
  class="button"
  type="{{ buttonType }}"
  [id]="idx"
  [ngClass]="{
    button: style !== 'Add',
    black: style === 'Black',
    blue: style === 'Blue',
    green: style === 'Green',
    add: style === 'Add',
    active: active
  }"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>
