import { CdkHeaderCellDef } from '@angular/cdk/table';
import { Directive, TemplateRef } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[lnHeaderDef]',
  exportAs: 'lnHeaderDef',
})
export class HeaderDefDirective extends CdkHeaderCellDef {
  constructor(public override template: TemplateRef<unknown>) {
    super(template);
  }
}
