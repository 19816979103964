import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonComponent } from '../../atoms';

@Component({
  selector: 'locumsnest-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss'],
  standalone: true,
  imports: [ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningBoxComponent {
  @Input() idx: string;
  @Input() message: string;
  @Input() title: string;
  @Input() acceptButtonText: string;
  @Input() declineButtonText: string;
  @Output() acceptButtonClicked = new EventEmitter<void>();
  @Output() declineButtonClicked = new EventEmitter<void>();

  public onWarningButtonPressed(buttonClickedName: `warning${'Accept' | 'Decline'}`) {
    if (buttonClickedName === 'warningAccept') {
      this.acceptButtonClicked.emit();
    } else if (buttonClickedName === 'warningDecline') {
      this.declineButtonClicked.emit();
    }
  }
}
