import { Component, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent {
  @Input() input: string;
  @Input() isRequired: boolean;
  @Input() displayRequiredIcon = true;
  @Input() size: 'normal' | 'small' | 'medium' | 'xsmall' = 'normal';
  @Input() for = '';

  constructor() {}
}
