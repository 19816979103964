import { Directive, Input } from '@angular/core';
import { difference, isArray, isNil } from 'lodash-es';

import { InputWrapperWithChangeDetection } from '../../../core/input-wrapper';
import { ISelectOption } from '../../../interfaces';

@Directive()
export abstract class DropdownSelectComponentBase<
  T extends string | boolean | number,
> extends InputWrapperWithChangeDetection {
  #options: ISelectOption<T>[];

  @Input() removeNonOptionValues = false;

  @Input()
  get options(): ISelectOption<T>[] {
    return this.#options;
  }

  set options(val: ISelectOption<T>[]) {
    this.#options = val || [];
    if (this.removeNonOptionValues) {
      this.clearNonOptionValues();
    }
  }

  clearNonOptionValues() {
    let newValue;
    const optionValues = this.options.map(({ id }) => id);
    if (isNil(this.value)) {
      return;
    }
    if (isArray(this.value)) {
      newValue = this.getOptionOnlyArrayValues(optionValues, this.value);
    } else if (!optionValues.includes(this.value)) {
      newValue = null;
    } else {
      return;
    }
    if (this.value !== newValue) {
      this.value = newValue;
    }
  }

  getOptionOnlyArrayValues(optionValues: unknown[], value: unknown[]) {
    const diff = difference(value, optionValues);
    if (diff.length) {
      return difference(value, diff);
    }
    return value;
  }
}
