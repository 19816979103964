import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'locumsnest-widget-area',
  templateUrl: './widget-area.component.html',
  styleUrls: ['./widget-area.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetAreaComponent {
  @Input() @HostBinding('class.vertical') vertical: boolean;
  @Input() @HostBinding('class.invalid') isInvalid: boolean;
}
