import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'locumsnest-slide-panel-container',
  styleUrls: ['./locumsnest-slide-panel-container.scss'],
  templateUrl: './locumsnest-slide-panel-container.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidePanelContainerComponent {
  @Input() width = 815;
}
