import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LocumsNestComponent } from '../../core/locums-nest-component';

@Component({
  selector: 'locumsnest-editable-display-area',
  templateUrl: './editable-display-area.component.html',
  styleUrls: ['./editable-display-area.component.scss'],
})
export class EditableDisplayAreaComponent extends LocumsNestComponent {
  @Input() inputText: string;
  @Input() emptyPlaceHolder = '';
  @Input() maxLength: number = null;
  @Input() type: 'text' | 'number' | 'password' | 'date' | 'datetime-local';
  @Output() changeArea: EventEmitter<Event> = new EventEmitter<Event>();

  public inEditMode = false;
  public errorMsg: string;

  constructor(protected router: Router, private renderer: Renderer2) {
    super();
  }

  public onDisplayAreaClicked() {
    this.inEditMode = true;

    setTimeout(() => {
      const x = this.renderer.selectRootElement('.editable-display-area-input input');
      x.focus();
    }, 50);
  }

  public onChangeArea(event: Event) {
    const reg = new RegExp('^\\d+$');
    if (
      this.type === 'number' &&
      event !== null &&
      event !== undefined &&
      !(event.target as HTMLInputElement).value.match(reg)
    ) {
      this.errorMsg = 'Please use only numbers';
    } else {
      this.changeArea.emit(event);
      this.inEditMode = false;
      this.errorMsg = '';
    }
  }

  public onBlur(event: Event) {
    this.inEditMode = false;
  }

  public getPlaceHolder() {
    if (this.inputText === '' || this.inputText === null) return this.emptyPlaceHolder;
    return this.inputText;
  }
}
