import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWrapper } from '@locumsnest/components/src/lib/core/input-wrapper';

@Component({
  selector: 'locumsnest-expandable-section',
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpandableSectionComponent),
      multi: true,
    },
  ],
})
export class ExpandableSectionComponent
  extends InputWrapper<boolean>
  implements ControlValueAccessor
{
  @Input() fieldName = 'expandable-input';
  @Input() title: string;
  @Input() isDisabled = false;
  @Input() isReadOnly = false;
  @Input() propertyId: string;

  @Input()
  set toggleValue(val: boolean) {
    this.writeValue(val);
  }

  @Output() expandToggleAction = new EventEmitter<boolean>();

  #isExpanded = false;

  constructor() {
    super();
    if (this.isReadOnly) {
      this.isExpanded = false;
    }
  }

  get isExpanded(): boolean {
    return this.#isExpanded;
  }

  @Input()
  set isExpanded(val: boolean) {
    if (!this.isReadOnly && this.#isExpanded !== val) {
      this.#isExpanded = val;
    }
  }

  onRowClick() {
    this.#isExpanded = !this.#isExpanded;
    this.expandToggleAction.emit(this.#isExpanded);
    this.writeValue(this.#isExpanded);
  }
}
