import { Component, Input } from '@angular/core';
import * as fileSaver from 'file-saver';

import { isRelativeUrl } from '@locumsnest/core/src/lib/helpers/file';

import { ISelectOption } from '../../interfaces';

@Component({
  selector: 'locumsnest-shift-description-files',
  templateUrl: './shift-description-files.component.html',
  styleUrls: ['./shift-description-files.component.scss'],
})
export class ShiftDescriptionFilesComponent {
  @Input() description: string;
  @Input() reasonForVacancy: string;
  @Input() costCentreNumber: string;
  @Input() fileName: string;
  @Input() file: any;
  @Input() isPrimaryCare: boolean;
  @Input() vacancyReasons: ISelectOption[];

  constructor() {}

  downloadFile() {
    const file = JSON.parse(this.file.value);
    if (isRelativeUrl(file.base64EncodedFile)) {
      const topHref = window.name;
      const topHrefSessions = topHref.split('/');
      const rootUrl = topHrefSessions.splice(0, topHrefSessions.length - 3).join('/');
      const fileUrl = rootUrl + file.base64EncodedFile;
      window.top.location.href = fileUrl;
    } else {
      const blob = JSON.parse(this.file.value).base64EncodedFile;
      const filename = JSON.parse(this.file.value).name;
      fileSaver.saveAs(blob, filename);
    }
  }
}
