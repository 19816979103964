<div
  *ngIf="unreadAlerts > 0"
  class="notification"
  [ngClass]="{ 'notification--red': type === 'cancelledAlerts' }"
  (click)="notificationClicked()"
>
  <div class="notification_alert">
    <div class="notification_alert--icon"></div>
    <div
      [ngClass]="{
        'notification_alert--text_white': type === 'cancelledAlerts'
      }"
    >
      You have {{ unreadAlerts }} cancellation{{ unreadAlerts > 1 ? 's' : '' }} &nbsp;&nbsp;- &nbsp;
      <span class="u-italic-text">see all</span>
    </div>
  </div>
  <div
    [ngClass]="{
      'notification_date-white': type === 'cancelledAlerts'
    }"
  >
    {{ lastAlertDate | fromNow }}
  </div>
</div>
